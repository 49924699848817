import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

import { themeConfig } from "@sol/uikit/theming";

export { GlobalStyle } from "@sol/uikit/theming";

import theme from "../../uikit/theme";

type AppThemeProviderProps = {
    children: ReactNode;
};
export function AppThemeProvider({ children }: AppThemeProviderProps) {
    return (
        <ConfigProvider theme={themeConfig}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ConfigProvider>
    );
}
