import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { ISelfEvaluationResource } from "./";

interface PostSelfEvaluationVariables
    extends Pick<
        ISelfEvaluationResource,
        "title" | "periodStart" | "periodEnd" | "achievements" | "challenges" | "description"
    > {
    uuid: string;
    skillLevels: string[];
    skills: string[];
}

export const useCreateSelfEvaluation = (
    config: MutateConfig<AxiosResponse<ISelfEvaluationResource>, any, PostSelfEvaluationVariables>,
) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const postSelfEvaluation = (variables: PostSelfEvaluationVariables) =>
        api.post<ISelfEvaluationResource>("/self_evaluations", { ...variables, tools: "", links: [] });

    const [createSelfEvaluation, { isSuccess, error, reset, isLoading }] = useMutation(postSelfEvaluation, config);

    return { createSelfEvaluation, isSuccess, error, reset, isLoading };
};
