import styled, { css } from "styled-components";

type Props = {
    selected?: boolean;
};

export const ListItem = styled.li<Props>`
    list-style: none;
    flex-shrink: 0;

    > a,
    button&,
    > button,
    > div {
        display: block;
        width: 100%;
        transition: all 0.2s;
        outline: none;

        background: none;
        padding: 0;
        cursor: pointer;

        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border: 4px solid transparent;

        :focus {
            border: 4px solid ${({ theme }) => theme.palette.blue.focus};
        }

        ${({ theme, selected }) =>
            selected
                ? css`
                      background: ${theme.palette.purple.base};
                      --text-color: ${theme.palette.white.base};
                      --icon-color: ${theme.palette.white.base};
                      :focus {
                          box-shadow: inset 0 0 0 3px ${({ theme }) => theme.palette.white.base};
                      }
                  `
                : css`
                      --text-color: ${theme.palette.black.base};
                      --icon-color: ${theme.palette.black.base};

                      :hover {
                          background-color: ${theme.palette.purple.lighter2};
                      }

                      :active {
                          background-color: ${theme.palette.purple.lighter2};
                      }

                      :hover:active {
                          transform: translate(0px, 0px);
                          box-shadow: none;
                      }
                  `};
    }
`;

export default ListItem;
