import { v4 as uuid } from "uuid";

import { createReducer } from "../utils";
import { ActionTypes, IRequestState } from "./model";

export const initialState: IRequestState = {
    id: uuid(),
};

const actionHandlers = {
    [ActionTypes.REFRESH]: (state: IRequestState) => ({
        ...state,
        id: uuid(),
    }),
};

export const reducer = createReducer(initialState, actionHandlers);
