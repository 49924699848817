import { parse, format } from "date-fns";

const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const parseDate = (date: string) => {
    return parse(date, DATETIME_FORMAT, Date.now());
};

export const formatDate = (localDate: Date | number) => {
    return `${format(localDate, DATETIME_FORMAT)}+00:00`;
};
