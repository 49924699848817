import axios from "axios";
import { useMutation, MutateConfig } from "react-query";
import JSONLD from "src/utils/jsonld";
import { v4 as uuid } from "uuid";

import { useApi } from "@sol/sdk/SDKProvider";

import { IClassroomResource } from "../classrooms/IClassroomResource";
import { IMediaResource } from "../media/IMediaResource";
import { ITagResource } from "../tags/ITagResource";
import { IResourceResource } from "./IResourceResource";

export interface ICreateResourceParams {
    title: string;
    url: string;
    description: string;
    tags?: string[] | ITagResource[];
    image?: string | IMediaResource;
    classroom: string | IClassroomResource;
}

export const useCreateResource = (config: MutateConfig<IResourceResource, any, ICreateResourceParams>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const mutationFn = async (params: ICreateResourceParams) => {
        const { url } = params;

        let media = undefined;
        try {
            // Here we get image of the page specified by the url in order to set the resource image;

            // STEP 1: Retrieve the image from url by using a private API and bypassing the
            // CORS issues when accessing external URL
            const { data: image } = await axios.get("/api/website-image", {
                params: {
                    url,
                },
                responseType: "blob",
            });

            // STEP: 2 Upload the image as media
            const formData = new FormData();
            formData.append("file", image);
            formData.append("uuid", uuid());

            media = await api.request({
                url: "/media",
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            });

            // STEP 3: manually link the resource to the media
            media = media.data;
        } finally {
            // STEP 4: Create the resource with the image
            const res = await api.post<IResourceResource>(
                "/resources",
                JSONLD.normalize({ ...params, image: media, uuid: uuid() }),
            );
            return res.data;
        }
    };

    return useMutation(mutationFn, { ...config });
};
