import { MenuProps } from "antd";
import { MenuItemType } from "antd/es/menu/interface";
import { TFunction } from "i18next";
import Link from "next/link";

import { Feature } from "@sol/features";
import { interpolate, Route } from "@sol/routing";
import { getResourceUuid, IClassroom } from "@sol/sdk";
import { Binder, Brief, EmptyWork, Explore, SelfEvaluation, Users } from "@sol/uikit/core/icons";

export type MenuItem = Required<MenuProps>["items"][number];

export const getLink = ({ label, href, ...item }: Omit<MenuItemType, "key"> & { href: string }): MenuItemType => ({
    key: href,
    label: (
        <Link href={href} passHref>
            {/* tabIndex prevent the link to interfere with menu focus trap */}
            <a tabIndex={-1}>{label}</a>
        </Link>
    ),
    ...item,
});

export const getMenuItems = (
    t: TFunction,
    checkFeatureFlag: (feature: Feature | Feature[]) => boolean,
    activeClassroom?: IClassroom,
): MenuItem[] => {
    const items: MenuItem[] = [];

    // Home
    items.push(
        getLink({
            label: t("component.Navigation.Menu.items.home"),
            href: Route.HOME,
            icon: <Explore stroke="transparent-base" className="rounded-none" />,
        }),
    );
    // Trainer space
    if (checkFeatureFlag(Feature.MENU_TRAINER_SPACE)) {
        items.push({
            label: t("component.Navigation.Menu.items.trainerSpace.label"),
            key: "trainer-space",
            icon: <Brief stroke="transparent-base" className="rounded-none" />,
            children: [
                // Trainer space items
                getLink({
                    label: t("component.Navigation.Menu.items.trainerSpace.children.professionalSituations"),
                    href: Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION,
                }),
                getLink({
                    label: t("component.Navigation.Menu.items.trainerSpace.children.allBriefs"),
                    href: Route.TRAINER_WORKSPACE_BRIEFS,
                }),
                getLink({
                    label: t("component.Navigation.Menu.items.frameworks"),
                    href: Route.TRAINER_WORKSPACE_FRAMEWORKS,
                }),
            ],
        });
    }

    // Briefs explorer for manager
    // TODO: check if we want an entry on the menu or the explorer acces through the classroom
    // can do the job
    // if (checkFeatureFlag(Feature.MENU_BRIEFS_EXPLORER)) {
    //     items.push(
    //         getLink({
    //             href: Route.BRIEFS_EXPLORE,
    //             label: t("component.Navigation.Menu.items.briefs"),
    //             icon: <Brief stroke="transparent-base" className="rounded-none" />,
    //         }),
    //     );
    // }

    // Classroom space
    if (checkFeatureFlag(Feature.MENU_CLASSROOM_SPACE)) {
        const classroomSpaceItems = [
            getLink({
                label: t("component.Navigation.Menu.items.classroomSpace.children.pedagogicalScenario"),
                href: interpolate(Route.CLASSROOM_PEDAGOGICAL_SCENARIO, {
                    classroomId: activeClassroom ? getResourceUuid(activeClassroom) : undefined,
                }),
            }),
            getLink({
                href: interpolate(Route.CLASSROOM_RESOURCES, {
                    classroomId: activeClassroom ? getResourceUuid(activeClassroom) : undefined,
                }),
                label: t("component.Navigation.Menu.items.classroomSpace.children.resources"),
            }),
            getLink({
                href: interpolate(Route.CLASSROOM_WORKSPACES, {
                    classroomId: activeClassroom ? getResourceUuid(activeClassroom) : undefined,
                }),
                label: t("component.Navigation.Menu.items.works"),
            }),
        ];

        if (checkFeatureFlag(Feature.CLASSROOM_BRIEFS) && activeClassroom) {
            classroomSpaceItems.splice(
                1,
                0,
                getLink({
                    href: interpolate(Route.CLASSROOM_BRIEFS, {
                        classroomId: getResourceUuid(activeClassroom),
                    }),
                    label: t("component.Navigation.Menu.items.classroomSpace.children.briefs"),
                }),
            );
        } else if (checkFeatureFlag([Feature.TOPICS_WORK_SUBMIT, Feature.WORKSPACE_GROUP_SUBMIT])) {
            classroomSpaceItems.splice(
                1,
                0,
                getLink({
                    href: Route.TRAINER_WORKSPACE_BRIEFS,
                    label: t("component.Navigation.Menu.items.classroomSpace.children.briefs"),
                }),
            );
        }

        if (checkFeatureFlag(Feature.MENU_CLASSROOM_DASHBOARD) && activeClassroom) {
            classroomSpaceItems.splice(
                0,
                0,
                getLink({
                    href: interpolate(Route.CLASSROOM_DASHBOARD, {
                        classroomId: getResourceUuid(activeClassroom),
                    }),
                    label: t("component.Navigation.Menu.items.classroomSpace.children.statistics"),
                }),
            );
        }

        items.push({
            label: t("component.Navigation.Menu.items.classroomSpace.label"),
            key: "classroom-space",
            icon: <Binder stroke="transparent-base" className="rounded-none" />,
            children: [
                {
                    label: activeClassroom
                        ? activeClassroom.title
                        : t("component.Navigation.Menu.items.classroomSpace.children.noActiveClassroom"),
                    key: "active-classroom",
                    children: classroomSpaceItems,
                    type: "group",
                },
            ],
        });
    }

    // Frameworks
    if (checkFeatureFlag(Feature.FRAMEWORKS_ACCESS_TUTOR)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.items.frameworks"),
                href: Route.FRAMEWORKS,
                icon: <Brief stroke="transparent-base" className="rounded-none" />,
            }),
        );
    }

    // Works
    if (checkFeatureFlag(Feature.WORKSPACES_ACCESS_TUTOR)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.items.works"),
                href: Route.WORKSPACES,
                icon: <EmptyWork stroke="transparent-base" className="rounded-none" />,
            }),
        );
    }

    // Follow-ups
    if (checkFeatureFlag(Feature.MENU_FOLLOW_UPS)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.items.followUp"),
                href: Route.FOLLOW_UPS_LIST,
                icon: <SelfEvaluation stroke="transparent-base" className="rounded-none" />,
            }),
        );
    }

    // Learners
    if (checkFeatureFlag(Feature.LEARNERS_CARD_ACCESS_TUTOR)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.items.learners"),
                href: Route.LEARNERS,
                icon: <Users size={20} fill="transparent-base" className="rounded-none" />,
            }),
        );
    }

    return items;
};
