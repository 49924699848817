export enum EntityTypes {
    BRIEF = "Brief",
    PROFESSIONAL_SITUATION = "ProfessionalSituation",

    CATEGORY = "Category",
    CLASSROOM = "Classroom",

    EVALUATION = "Evaluation",

    FACTORY = "Factory",
    FOLLOW_UP = "FollowUp",
    FRAMEWORK = "Framework",

    GROUP_CORRECTION = "GroupCorrection",
    GROUP_CORRECTED_SKILL_LEVEL = "GroupCorrectedSkillLevel",
    GROUP_MESSAGE = "GroupMessage",
    GROUP_SPACE = "GroupSpace",
    GROUP_TASK = "GroupTask",
    GROUP_WORK = "GroupWork",

    LANGUAGE = "Language",

    MEDIA = "Media",
    MEDIA_SECURED = "MediaSecured",

    NOTIFICATION = "Notification",

    PAGE = "Page",

    RESOURCE = "Resource",

    SELF_EVALUATION = "SelfEvaluation",
    SKILL = "Skill",
    SKILL_LEVEL = "SkillLevel",
    STATISTIC = "Statistic",

    TAG = "Tag",
    TOPIC = "Topic",

    USER = "User",

    VALIDATION = "Validation",
    WORK = "Work",

    CURRENT_BRAND = "CurrentBrand",

    DATAVIZ_LINE = "DatavizLine",
    DATAVIZ_DAY_POINT = "DatavizDayPoint",

    EDUCATIONAL_SCENARIO_SLOT = "EducationalScenarioSlot",
    EDUCATIONAL_SCENARIO_ITEM = "EducationalScenarioItem",
    EDUCATIONAL_BRIEF = "EducationalBrief",

    COMMENT = "Comment",

    MISSION = "Mission",

    USER_SETTINGS = "UserSettings",

    BRAND = "Brand",

    LOCATION = "Location",
}

export default EntityTypes;
