import { ConfigProvider, theme, ThemeConfig } from "antd";
import clsx from "clsx";
import { useMemo } from "react";

import { Menu, MenuProps } from "@sol/uikit/navigation";

export function NewContentMenu({ className, items = [], ...props }: MenuProps) {
    const { token } = theme.useToken();
    const themeConfig: ThemeConfig = useMemo(
        () => ({
            cssVar: { key: "new-content-menu" },
            components: {
                Menu: {
                    colorText: token.colorPrimary,
                    groupTitleColor: token.colorPrimaryHover,
                    itemSelectedBg: token.colorPrimaryBorder,
                    itemActiveBg: token.colorPrimaryBorder,
                    itemBg: token.colorPrimaryBgHover,
                },
            },
        }),
        [token],
    );
    return (
        <ConfigProvider theme={themeConfig}>
            <Menu
                className={clsx("rounded-lg pt-2", className)}
                items={useMemo(
                    () =>
                        items.map(function mapContentMenuItem(item: (typeof items)[number]): (typeof items)[number] {
                            if (!item) {
                                return item;
                            }

                            const newItem = {
                                ...item,
                                className:
                                    "ring-inset ring-1 ring-transparent-base [&:is(.ant-menu-item-selected)]:ring-antd-split before:hidden",
                            };
                            if ("children" in newItem && Array.isArray(newItem.children)) {
                                return {
                                    ...newItem,
                                    children: newItem.children.map(mapContentMenuItem),
                                };
                            }

                            return newItem;
                        }),
                    [items],
                )}
                {...props}
            />
        </ConfigProvider>
    );
}
