import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Forbidden = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M6 6L18 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Forbidden;
