import BaseError from "es6-error";

class HttpError extends BaseError {
    constructor(error) {
        super("An error occured during API call");
        const { status, data, headers } = error;

        this.status = status;
        this.headers = headers;
        this.data = data;
    }
}

export default HttpError;
