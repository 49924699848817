import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IMissionResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    missionId?: string | null;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useMission = ({ queryKey, missionId, ...options }: Params<IMissionResource>) => {
    const api = useApi();
    const url = useMemo(() => `/missions/${missionId}`, [missionId]);
    const queryFn = useCallback(() => unwrapAPIResult(api.get<IMissionResource>(url)), [api, url]);

    return useQuery<IMissionResource>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
