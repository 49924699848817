import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Info = ({ className, stroke, fill, ...props }: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} className={className}>
            <circle
                cx="12"
                cy="5"
                r="1.25"
                stroke="black"
                fill="black"
                transform="rotate(-180 12 5)"
                strokeWidth="0.5"
                className={`stroke-${stroke} fill-${fill}`}
            />
            <path
                d="M12 10L12 19"
                strokeWidth="2"
                strokeLinecap="round"
                stroke="black"
                className={`stroke-${stroke}`}
            />
        </SvgIcon>
    );
};
