import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const SortArrow = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M12.8 15.9333C12.4 16.4667 11.6 16.4667 11.2 15.9333L7.2 10.6C6.70557 9.94076 7.17596 9 8 9L16 9C16.824 9 17.2944 9.94076 16.8 10.6L12.8 15.9333Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default styled(SortArrow)``;
