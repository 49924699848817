import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import isFunction from "lodash/isFunction";
import React, { useMemo, useContext, ReactNode } from "react";
import { ReactQueryConfigProvider } from "react-query";

export type SDKContext = AxiosInstance;

const SDKContext = React.createContext<SDKContext>(axios);

const { Provider } = SDKContext;

type SDKProviderProps = {
    token?: string;
    locale: string;
    baseURL: string;
    children: ReactNode | ((ctx: SDKContext) => ReactNode);
};

export function SDKProvider({ baseURL, children, token, locale }: SDKProviderProps) {
    const api = useMemo(() => {
        const instance = axios.create({ baseURL });

        if (token) {
            instance.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

        instance.defaults.headers.common["x-locale"] = locale;

        return instance;
    }, [baseURL, token, locale]);

    const reactQueryConfig = useMemo(
        () => ({
            queries: {
                // See diagram https://bs-uploads.toptal.io/blackfish-uploads/uploaded_file/file/167418/image-1579535436801-78c658f57da8a1b95d58fb2da9fa35a5.png
                cacheTime: 90 * 60 * 1000, // Keeps data in cache during 1h30min
                staleTime: 1000, // Avoids to emit a new request during 1s
                retry: 1, // Do not retry more than 1 time
                refetchOnWindowFocus: false,
                queryFn: async (key: string, options?: AxiosRequestConfig) => {
                    const response = await api.request(
                        options
                            ? {
                                  url: key,
                                  ...options,
                              }
                            : { url: key },
                    );

                    return response?.data;
                },
            },
        }),
        [api],
    );

    return (
        <ReactQueryConfigProvider config={reactQueryConfig}>
            <Provider value={api}>{children && isFunction(children) ? children(api) : children}</Provider>
        </ReactQueryConfigProvider>
    );
}

export const useApi = () => useContext(SDKContext);

export default SDKContext;
