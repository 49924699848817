import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Mail = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M5.6 6H18.4C19.28 6 20 6.675 20 7.5V16.5C20 17.325 19.28 18 18.4 18H5.6C4.72 18 4 17.325 4 16.5V7.5C4 6.675 4.72 6 5.6 6Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M19 7L12 13L5 7"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default styled(Mail)``;
