import { AxiosRequestConfig } from "axios";

import { IGroupSpaceResource, IGroupTasksList, IUserResource } from "@sol/sdk";
import { createApiQueryHook, IOrderable, IPaginateable, mapOrder, mapPagination } from "@sol/sdk/utils/api";
import { mapRelationalFilter, RelationalFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

export interface IGroupTaskListQueryParameters extends IPaginateable, IOrderable<"rank"> {
    filters?: {
        groupSpace?: RelationalFilter<IGroupSpaceResource>;
        learner?: RelationalFilter<IUserResource>;
    };
}

export type IGroupTaskListQueryResult = IGroupTasksList;

const mapFilters = (filters: IGroupTaskListQueryParameters["filters"]) => {
    const { groupSpace, learner } = filters ?? {};
    return {
        "groupSpace.uuid": mapRelationalFilter(groupSpace),
        "learner.uuid": mapRelationalFilter(learner),
    };
};

export function groupTaskListQuery({ filters, order, pagination }: IGroupTaskListQueryParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/group_tasks${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
}

export const useGroupTaskListQuery = createApiQueryHook<IGroupTaskListQueryParameters, IGroupTaskListQueryResult>(
    groupTaskListQuery,
);
