import SvgIcon from "./SvgIcon";
import { SvgIconProps } from "./types";

const Search = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M11.5 17C15.0899 17 18 14.0899 18 10.5C18 6.91015 15.0899 4 11.5 4C7.91015 4 5 6.91015 5 10.5C5 14.0899 7.91015 17 11.5 17Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M19 18L16.5 15.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};

export default Search;
