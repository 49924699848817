import styled from "styled-components";

import theme from "../../uikit/theme";

type Props = {
    color?: string;
    highlightColor?: string;
};

const baseColor = theme.palette.grey.lightest;
const baseHighlightColor = theme.palette.purple.lightest;

const BaseSkeleton = styled.span<Props>`
    background-color: ${({ color = baseColor }) => color};
    background-image: ${({ color = baseColor, highlightColor = baseHighlightColor }) => `linear-gradient(
        90deg,
        ${color},
        ${highlightColor},
        ${color}
    )`};
    background-size: 200px 100%;
    background-repeat: no-repeat;

    @keyframes loading {
        0% {
            background-position: -200px 0;
        }
        100% {
            background-position: calc(200px + 100%) 0;
        }
    }

    animation: loading 1.2s ease-in-out infinite;
`;

export default BaseSkeleton;
