const ImageIcon = () => {
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
        >
            <rect className="icon-strokecolor" stroke="white" strokeWidth={3} x="0" y="0" width="100%" height="100%" />
            <path
                className="icon-fillcolor"
                fill="white"
                d="M15.1026 5.95085L12.9775 8.69594L17.9407 13.0135V5.6554C17.0835 4.92666 15.7914 5.06117 15.1026 5.95085Z"
            />
            <path
                className="icon-fillcolor"
                fill="white"
                d="M17.9409 17.2703H1.71973C1.71973 16.4438 2.00196 15.6421 2.51971 14.9978L6.96653 9.46477C8.39487 7.68753 11.0176 7.4619 12.7285 8.96907L16.585 12.3663C17.447 13.1257 17.9409 14.219 17.9409 15.3678V17.2703Z"
            />
            <circle className="icon-fillcolor" fill="white" cx="11.8301" cy="5" r="2" />
        </svg>
    );
};

export default ImageIcon;
