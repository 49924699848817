import { createReducer } from "../utils";
import { setUser } from "./actions";
import { ActionTypes, IUserState } from "./model";

export const initialState: IUserState = null;

const actionHandlers = {
    [ActionTypes.UNSET_USER]: () => initialState,
    [ActionTypes.SET_USER]: (state: IUserState, { user }: ReturnType<typeof setUser>) => user,
};

export const reducer = createReducer(initialState, actionHandlers);
