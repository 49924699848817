import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

type Direction = "up" | "down" | "left" | "right";

const DIRECTIONS: {
    [key in Direction]: number;
} = {
    up: 0,
    right: 90,
    down: 180,
    left: 270,
};

type Props = { direction?: Direction };

const StyledSvgIcon = styled(SvgIcon)<Props>`
    transform: ${({ direction }) => direction && `rotateZ(${DIRECTIONS[direction]}deg)`};
    transition: transform 0.2s ease-in-out;
`;

const Chevron = (props: Props & SvgIconProps) => {
    const { stroke } = classes;

    return (
        <StyledSvgIcon {...props}>
            <path
                d="M18 14L12 8L6 14"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </StyledSvgIcon>
    );
};

export default styled(Chevron)``;
