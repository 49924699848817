import { AxiosRequestConfig } from "axios";

import { IClassroom, IClassroomMetrics } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { mapRelationalFilter, RelationalFilter } from "@sol/sdk/utils/filters";

export type IClassroomMetricsQueryParameters = {
    classroom?: RelationalFilter<IClassroom>;
};

export type IClassroomMetricsQueryResult = IClassroomMetrics;

export function classroomMetricsQuery({ classroom }: IClassroomMetricsQueryParameters): AxiosRequestConfig | undefined {
    if (!classroom) {
        return;
    }

    return {
        method: "GET",
        url: `/classrooms/${mapRelationalFilter(classroom)}/metrics`,
    };
}

export const useClassroomMetricsQuery = createApiQueryHook<
    IClassroomMetricsQueryParameters,
    IClassroomMetricsQueryResult
>(classroomMetricsQuery);
