import { IApiMutationFunction, createApiMutationHook } from "../utils/api";
import { RelationalValue, mapRelationalValue } from "../utils/filters";
import { ICommentResource } from "./ICommentResource";

export type ICommentDeleteMutationParameters = {
    comment: RelationalValue<ICommentResource>;
};

export type ICommentDeleteMutationResult = void;

export const commentDeleteMutation: IApiMutationFunction<ICommentDeleteMutationParameters> = ({ comment }) => {
    return {
        method: "DELETE",
        url: `/comments/${mapRelationalValue(comment)}`,
    };
};

export const useCommentDeleteMutation = createApiMutationHook<
    ICommentDeleteMutationParameters,
    ICommentDeleteMutationResult
>(commentDeleteMutation);
