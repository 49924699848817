import React from "react";
import { Translation } from "react-i18next";

import { UserRole } from "@sol/sdk";

export const translationKeysByRole: { [key in UserRole]: string } = {
    ROLE_ADMIN: "misc.users.roles.admin",
    ROLE_LEARNER: "misc.users.roles.learner",
    ROLE_TRAINER: "misc.users.roles.trainer",
    ROLE_MANAGER: "misc.users.roles.manager",
    ROLE_SUPERADMIN: "misc.users.roles.superadmin",
    ROLE_TUTOR: "misc.users.roles.tutor",
};

export const translatedRoles: { [key in UserRole]: JSX.Element } = {
    ROLE_ADMIN: <Translation>{t => t("misc.users.roles.admin")}</Translation>,
    ROLE_LEARNER: <Translation>{t => t("misc.users.roles.learner")}</Translation>,
    ROLE_TRAINER: <Translation>{t => t("misc.users.roles.trainer")}</Translation>,
    ROLE_MANAGER: <Translation>{t => t("misc.users.roles.manager")}</Translation>,
    ROLE_SUPERADMIN: <Translation>{t => t("misc.users.roles.superadmin")}</Translation>,
    ROLE_TUTOR: <Translation>{t => t("misc.users.roles.tutor")}</Translation>,
};
