const CodeBlockIcon = () => (
    <svg
        width="18"
        height="8"
        viewBox="0 0 18 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <path
            d="M0.788086 3.008L7.66809 0.112L8.29209 2.048L3.26809 4L8.29209 5.952L7.66809 7.888L0.788086 4.992V3.008Z"
            className="icon-fillcolor"
            fill="white"
        />
        <path
            d="M17.3698 4.992L10.4898 7.888L9.86584 5.952L14.8898 4L9.86584 2.048L10.4898 0.112L17.3698 3.008V4.992Z"
            className="icon-fillcolor"
            fill="white"
        />
    </svg>
);

export default CodeBlockIcon;
