import { TFunction } from "i18next";

import { Feature } from "@sol/features";
import { Route } from "@sol/routing";
import { ExternalLink } from "@sol/uikit/core/icons";

import { MenuItem, getLink } from "./getMenuItems";

export const getQuickActionItems = ({
    t,
    checkFeatureFlag,
}: {
    t: TFunction;
    checkFeatureFlag: (feature: Feature) => boolean;
}) => {
    const items: MenuItem[] = [];

    if (checkFeatureFlag(Feature.MENU_QUICK_LINKS_BRIEF_CREATE)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.quickActionsMenu.items.brief"),
                href: Route.BRIEFS_CREATE,
            }),
        );
    }

    if (checkFeatureFlag(Feature.MENU_QUICK_LINKS_PROFESSIONAL_SITUATION_CREATE)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.quickActionsMenu.items.professionalSituation"),
                href: Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION_CREATE,
            }),
        );
    }

    if (checkFeatureFlag(Feature.CLASSROOM_CREATE)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.quickActionsMenu.items.classroom"),
                href: Route.CLASSROOMS_CREATE,
            }),
        );
    }

    if (checkFeatureFlag(Feature.ADMIN)) {
        items.push(
            getLink({
                label: t("component.Navigation.Menu.quickActionsMenu.items.admin"),
                href: Route.ADMIN,
                icon: <ExternalLink size={16} stroke="transparent-base" className="rounded-none" />,
            }),
        );
    }

    return items;
};
