import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { ISelfEvaluationResource } from "./ISelfEvaluationResource";

interface PutSelfEvaluationsItemVariables
    extends Pick<
        ISelfEvaluationResource,
        "title" | "periodStart" | "periodEnd" | "achievements" | "challenges" | "description"
    > {
    uuid: string;
    skillLevels: string[];
    skills: string[];
}

const putSelfEvaluation =
    (api: AxiosInstance) =>
    ({ uuid, ...payload }: PutSelfEvaluationsItemVariables) =>
        api.put(`/self_evaluations/${uuid}`, payload);

export const usePutSelfEvaluation = (config: MutateConfig<AxiosResponse, any, PutSelfEvaluationsItemVariables>) => {
    const api = useApi();
    const putSelfEvaluationMutation = useMemo(() => putSelfEvaluation(api), [api]);

    const [updateSelfEvaluation, mutationState] = useMutation(putSelfEvaluationMutation, config);

    return { updateSelfEvaluation, ...mutationState };
};
