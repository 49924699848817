import { ISkillLevelResource, ISkillResource } from "@sol/sdk";

import { EntityTypes } from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";
import { IUserResource } from "../users";

export interface ISelfEvaluationResourceSkill extends Omit<ISkillResource, "createdAt" | "updatedAt"> {
    officialTitle: string;
    rank: number;
    code: string;
}

export interface ISelfEvaluationResourceSkillLevel extends ISkillLevelResource {
    skill: ISelfEvaluationResourceSkill;
}

export interface ISelfEvaluationResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.SELF_EVALUATION;
    title: string;
    unprefixedTitle: string;
    description: string;
    tools: string;
    skillLevels: ISelfEvaluationResourceSkillLevel[];
    periodStart: string;
    periodEnd: string;
    achievements?: string;
    challenges?: string;
    // TODO: modify when API is standardized. Be careful when using these 2 fields depending of the endpoint
    // learner is used for details or legacy /self_evaluations. createdBy is used for follow-ups
    learner: IUserResource;
    createdBy: IUserResource;
}

export function isSelfEvaluationResource(resource: IHydraResource): resource is ISelfEvaluationResource {
    return resource["@type"] === EntityTypes.SELF_EVALUATION;
}
