import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IDatavizLineResource, IDatavizLinesList } from ".";
import { serialize } from "../utils/url";

type DatavizActionType = IDatavizLineResource["id"];

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    filters?: {
        classroomId: string;
        action?: DatavizActionType | DatavizActionType[];
        fromDate?: string;
        toDate?: string;
        cumulatedNumber?: boolean;
        byLearner?: boolean;
        learners?: string | string[];
    };
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { classroomId, learners, action, byLearner, cumulatedNumber, fromDate, toDate } = filters || {};
    return {
        "classroom.uuid": classroomId,
        "learner.uuid": learners,
        "day[after]": fromDate,
        "day[before]": toDate,
        action,
        byLearner,
        cumulatedNumber,
    };
};

export const useDataviz = ({ queryKey, filters, ...options }: Params<IDatavizLinesList>) => {
    const api = useApi();

    const url = useMemo(() => `/dataviz_lines${serialize(mapFilters(filters), true)}`, [filters]);

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IDatavizLinesList>(url)), [api, url]);

    return useQuery<IDatavizLinesList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
