import { IModule } from "redux-dynamic-modules";

import { IRootState } from "..";
import middleware from "./middleware";

const AuthenticationModule: IModule<Partial<IRootState>> = {
    id: "analytics",
    middlewares: [middleware],
};

export default AuthenticationModule;
