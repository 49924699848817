import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Retry = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path d="M3 4.87958V10.2067H8.32711" strokeWidth="1.7757" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M5.22851 14.6459C5.80418 16.2799 6.8953 17.6825 8.33746 18.6425C9.77961 19.6024 11.4947 20.0677 13.2242 19.9681C14.9538 19.8686 16.6042 19.2096 17.9267 18.0906C19.2492 16.9715 20.1722 15.453 20.5566 13.7637C20.941 12.0745 20.766 10.3061 20.058 8.72494C19.3499 7.14381 18.1472 5.8356 16.631 4.99744C15.1149 4.15929 13.3674 3.83658 11.6518 4.07794C9.9363 4.31931 8.34568 5.11167 7.11963 6.33565L3 10.2067"
                strokeWidth="1.7757"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
