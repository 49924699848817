import React from "react";

const WorkToCorrect = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            aria-hidden="true"
            focusable="false"
        >
            <path
                d="M0.59082 7.51578H2.45667C2.8336 7.51578 3.17851 7.72773 3.3488 8.064L4.00942 9.36853C4.17971 9.7048 4.52462 9.91675 4.90155 9.91675H8.11077C8.48964 9.91675 8.83597 9.70263 9.00532 9.36371L9.65232 8.06882C9.82166 7.7299 10.168 7.51578 10.5469 7.51578H12.409L10.8733 3.55523C10.7241 3.17037 10.3537 2.91675 9.94094 2.91675H3.05888C2.6461 2.91675 2.27574 3.17037 2.12652 3.55523L0.59082 7.51578Z"
                stroke="#6E6A69"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M12.409 7.58337V11.4167C12.409 12.5213 11.5136 13.4167 10.409 13.4167H2.59082C1.48625 13.4167 0.59082 12.5213 0.59082 11.4167V7.58337"
                stroke="#6E6A69"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default WorkToCorrect;
