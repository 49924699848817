import { createReducer } from "../utils";
import { setLocale } from "./actions";
import { ActionTypes, II18nState } from "./model";

export const initialState = {
    locale: "fr",
};
const actionHandlers = {
    [ActionTypes.SET_LOCALE]: (state: II18nState, { locale }: ReturnType<typeof setLocale>) => ({
        ...state,
        locale: locale ?? initialState.locale,
    }),
};

export const reducer = createReducer(initialState, actionHandlers);
