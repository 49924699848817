import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IPaginated } from "../types/IPaginated";
import { mapRelationalValue, OneOrManyFilter, RelationalValue } from "../utils/filters";
import { serialize } from "../utils/url";
import { IUserList } from "./IUserList";
import { IUserResource } from "./IUserResource";
import UserRole from "./UserRole";
import { UserStatus } from "./UserStatus";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        emails?: OneOrManyFilter<string>;
        name?: string;
        surname?: string;
        status?: OneOrManyFilter<UserStatus>;
        role?: UserRole;
        hasWrittenFollowUpForLearner?: RelationalValue<IUserResource>;
        fullname?: string;
    };
    queryKey?: QueryKey;
    orderBy?: "updatedAt" | "createdAt" | "email" | "name" | "surname" | "roles" | "status";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { emails, name, surname, status, role, hasWrittenFollowUpForLearner, fullname } = filters || {};
    return {
        email: emails,
        name,
        surname,
        status,
        roles: role,
        follow_ups_for_learner: mapRelationalValue(hasWrittenFollowUpForLearner),
        fullname,
    };
};

export const useUsers = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IUserList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/users${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IUserList>(url)), [api, url]);

    return useQuery<IUserList, any>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
