import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";
import { IUserResource } from "../users";

export interface IBriefResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.BRIEF;
    title: string;
    createdBy: string | IUserResource;
    coeditors: IUserResource[];
}

export function isBriefResource(resource: IHydraResource): resource is IBriefResource {
    return resource["@type"] === EntityTypes.BRIEF;
}
