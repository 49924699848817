import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Download = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M19 14V17.3333C19 17.7754 18.8361 18.1993 18.5444 18.5118C18.2527 18.8244 17.857 19 17.4444 19H6.55556C6.143 19 5.74733 18.8244 5.45561 18.5118C5.16389 18.1993 5 17.7754 5 17.3333V14"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M8 10L12 15M12 15L16 10M12 15L12 4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};
