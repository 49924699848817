import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const { stroke, fill } = classes;

const Info = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <circle
                cx="12"
                cy="5"
                r="1.25"
                transform="rotate(-180 12 5)"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                className={`${stroke} ${fill}`}
            />
            <path d="M12 10L12 19" stroke="black" strokeWidth="2" strokeLinecap="round" className={stroke} />
        </SvgIcon>
    );
};

export default Info;
