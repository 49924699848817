import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { setToken } from "../store/authentication/actions";
import { unsetUser } from "../store/user/actions";

const useAuth = () => {
    const dispatch = useDispatch();

    const logout = useCallback(() => {
        dispatch(unsetUser());
        dispatch(setToken(null));
    }, [dispatch]);

    return { logout };
};

export default useAuth;
