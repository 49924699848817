import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Explore = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.9487 8.31623C17.0685 7.95689 16.9749 7.56073 16.7071 7.29289C16.4393 7.02506 16.0431 6.93154 15.6838 7.05132L9.68377 9.05132C9.38517 9.15085 9.15085 9.38517 9.05132 9.68377L7.05132 15.6838C6.93154 16.0431 7.02506 16.4393 7.29289 16.7071C7.56073 16.9749 7.95689 17.0685 8.31623 16.9487L14.3162 14.9487C14.6148 14.8491 14.8491 14.6148 14.9487 14.3162L16.9487 8.31623ZM9.58114 14.4189L10.7906 10.7906L14.4189 9.58114L13.2094 13.2094L9.58114 14.4189Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
