import { cva } from "class-variance-authority";

type Direction = "up" | "down" | "left" | "right";

type Props = { direction?: Direction };

const doubleArrowVariants = cva(["transition-transform", "ease-in-out", "transition-300"], {
    variants: {
        variant: {
            left: ["rotate-0"],
            right: ["rotate-180"],
            up: ["rotate-90"],
            down: ["rotate-[270deg]"],
        },
    },
    defaultVariants: {
        variant: "left",
    },
});

export const DoubleArrow = ({ direction }: Props) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={doubleArrowVariants({ variant: direction })}
        >
            <g id="double_arrow">
                <mask
                    id="mask0_9061_2684"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                >
                    <rect id="Bounding box" width="16" height="16" transform="matrix(-1 0 0 1 16 0)" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9061_2684)">
                    <path
                        id="double_arrow_2"
                        d="M11.1465 11.4667L8.47982 7.73333L11.1465 4H9.83982L7.17315 7.73333L9.83982 11.4667H11.1465ZM7.97315 11.4667L5.30648 7.73333L7.97315 4H6.66648L3.99982 7.73333L6.66648 11.4667H7.97315Z"
                        fill="#8E8A88"
                    />
                </g>
            </g>
        </svg>
    );
};
