import { Middleware, AnyAction, MiddlewareAPI, Dispatch } from "redux";
import { REHYDRATE } from "redux-persist";

import { IRootState } from "..";
import i18n from "../../i18n";
import { SET_TOKEN } from "../authentication/actions";
import { getDecodedToken } from "../authentication/selectors";
import { ActionTypes as UserActionTypes } from "../user/model";
import { getUserSelector } from "../user/selectors";
import { setLocale } from "./actions";
import { ActionTypes } from "./model";
import { getLocale } from "./selectors";

const middleware: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, IRootState>) => dispatch => (action: AnyAction) => {
        const result = dispatch(action);

        if (ActionTypes.SET_LOCALE === action.type) {
            i18n.changeLanguage(action.locale);
        } else if ([REHYDRATE, SET_TOKEN, UserActionTypes.SET_USER, UserActionTypes.UNSET_USER].includes(action.type)) {
            const state = store.getState();
            const user = getUserSelector(state);
            const token = getDecodedToken(state);
            const locale = getLocale(state);

            const overridenLocale = user?.language?.locale || token?.locale || locale;

            if (REHYDRATE === action.type || overridenLocale !== locale) {
                store.dispatch(setLocale(overridenLocale));
            }
        }

        return result;
    };

export default middleware;
