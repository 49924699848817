import { IModule } from "redux-dynamic-modules";

import { IRootState } from "../index";
import { initialState, reducer } from "./reducer";

const RequestsModule: IModule<Partial<IRootState>> = {
    id: "requests",
    reducerMap: {
        request: reducer,
    },
};

export * from "./model";
export { initialState };
export default RequestsModule;
