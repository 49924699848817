import { isEqual } from "lodash";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import { SetStateAction, useCallback, useMemo, useRef } from "react";

import { useRouter } from "@sol/routing";

type DispatchNavigation<T> = (
    value: T,
    options?: Omit<Parameters<ReturnType<typeof useRouter>["replace"]>[0], "state">,
) => void;

function useNavigationState<S = undefined>(
    key: string,
): [S | undefined, DispatchNavigation<SetStateAction<S | undefined>>];
function useNavigationState<S>(key: string, initialState: S | (() => S)): [S, DispatchNavigation<SetStateAction<S>>];
function useNavigationState<S>(
    key: string,
    initialState?: S | (() => S),
): [S | undefined, DispatchNavigation<SetStateAction<S | undefined>>] {
    const router = useRouter();

    const defaultState = useMemo(() => (isFunction(initialState) ? initialState() : initialState), []);

    const stateRef = useRef();
    const newState = router.state?.[key];

    const state = useMemo(() => {
        const previousState = stateRef.current;
        if (isEqual(previousState, newState)) {
            return previousState ?? defaultState;
        }

        stateRef.current = newState;
        return newState ?? defaultState;
    }, [newState]);

    const setState = useCallback(
        (value: SetStateAction<S | undefined>, options?: Omit<Parameters<typeof router.replace>[0], "state">) => {
            // Update both internal state and history state
            router.replace({
                ...options,
                state: (oldState: any) => {
                    return {
                        ...(isObject(oldState) ? oldState : undefined),
                        [key]: isFunction(value) ? value(oldState?.[key] ?? defaultState) : value,
                    };
                },
            });
        },
        [router.replace, key],
    );

    return [state, setState];
}

export default useNavigationState;
