import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Cross = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path d="M16 8L8 16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 8L16 16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};
