import { RequireExactlyOne } from "type-fest";
import { v4 as uuidV4 } from "uuid";

import EntityTypes from "../EntityTypes";
import { IFollowUpResource } from "../follow-up";
import { ISelfEvaluationResource } from "../selfEvaluations";
import { IApiMutationFunction, createApiMutationHook } from "../utils/api";
import { RelationalValue } from "../utils/filters";
import { buildResourceIRI } from "../utils/getResourceUuid";
import { ICommentResource } from "./ICommentResource";

export type ICommentCreateMutationParameters = RequireExactlyOne<
    {
        uuid?: string;
        content: string;
        followUp: RelationalValue<IFollowUpResource>;
        selfEvaluation: RelationalValue<ISelfEvaluationResource>;
    },
    "followUp" | "selfEvaluation"
>;

export type ICommentCreateMutationResult = ICommentResource;

export const commentCreateMutation: IApiMutationFunction<ICommentCreateMutationParameters> = ({
    followUp,
    selfEvaluation,
    uuid,
    ...data
}) => {
    return {
        method: "POST",
        url: "/comments",
        data: {
            uuid: uuid ?? uuidV4(),
            followUp: buildResourceIRI(EntityTypes.FOLLOW_UP, followUp),
            selfEvaluation: buildResourceIRI(EntityTypes.SELF_EVALUATION, selfEvaluation),
            ...data,
        },
    };
};

export const useCommentCreateMutation = createApiMutationHook<
    ICommentCreateMutationParameters,
    ICommentCreateMutationResult
>(commentCreateMutation);
