import { AxiosResponse } from "axios";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IFollowUp, IFollowUpResource } from "./";

interface PostFollowUpVariables extends Pick<IFollowUp, "title" | "waypoint" | "periodStart" | "periodEnd"> {
    briefs?: string[];
    missions?: string[];
    learner: string;
    uuid: string;
}

export const useCreateFollowUp = (
    config: MutateConfig<AxiosResponse<IFollowUpResource>, any, PostFollowUpVariables>,
) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const postFollowUp = (variables: PostFollowUpVariables) =>
        api.post<IFollowUpResource>("/follow_ups", { ...variables, more: "DUMMY" });

    const [createFollowUp, { isSuccess, error, reset, isLoading }] = useMutation(postFollowUp, config);

    return { createFollowUp, isSuccess, error, reset, isLoading };
};
