import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

interface PutMissionItemVariables {
    payload: {
        title: string;
        periodStart: string;
        periodEnd: string;
        waypoint: string;
        context: string;
        description: string;
        skills: string[];
        learners: string[];
    };
    missionId: string;
}

const putMission =
    (api: AxiosInstance) =>
    ({ payload, missionId }: PutMissionItemVariables) =>
        api.put(`/missions/${missionId}`, payload);

export const usePutMission = (config: MutateConfig<AxiosResponse, any, PutMissionItemVariables>) => {
    const api = useApi();
    const putMissionMutation = useMemo(() => putMission(api), [api]);

    const [updateMission, { isLoading, error, reset }] = useMutation(putMissionMutation, config);

    return { updateMission, isLoading, error, reset };
};
