import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const H2 = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M9.19041 7.23695H11.5007V17.5H9.19041V13.146H5.3103V17.5H2V7.23695H5.3103V11.1615H9.19041V7.23695Z"
                fill="black"
                className={fill}
            />
            <path
                d="M20.0366 9.9323C20.0366 10.3075 19.9625 10.6678 19.8144 11.0134C19.6663 11.359 19.4738 11.6946 19.2369 12.0205C18.9999 12.3364 18.7333 12.6425 18.4371 12.9386C18.141 13.2348 17.8497 13.5162 17.5634 13.7828C17.4153 13.921 17.2524 14.079 17.0747 14.2567C16.9068 14.4245 16.7439 14.5973 16.5859 14.775C16.428 14.9528 16.2848 15.1206 16.1565 15.2786C16.038 15.4267 15.9639 15.5501 15.9343 15.6488H20.3476V17.5H13.5056C13.4858 17.3914 13.4759 17.2532 13.4759 17.0853C13.4759 16.9175 13.4759 16.799 13.4759 16.7299C13.4759 16.256 13.55 15.8216 13.6981 15.4267C13.856 15.0317 14.0584 14.6664 14.3053 14.3307C14.5521 13.9852 14.8285 13.6643 15.1346 13.3681C15.4505 13.0719 15.7615 12.7757 16.0676 12.4795C16.3046 12.2525 16.5267 12.0402 16.734 11.8427C16.9414 11.6354 17.124 11.4379 17.282 11.2504C17.44 11.0529 17.5634 10.8604 17.6522 10.6728C17.7411 10.4753 17.7855 10.2779 17.7855 10.0804C17.7855 9.64598 17.6621 9.33992 17.4153 9.1622C17.1685 8.98449 16.8624 8.89563 16.4971 8.89563C16.2305 8.89563 15.9788 8.94006 15.7418 9.02891C15.5147 9.1079 15.3024 9.20663 15.105 9.32511C14.9174 9.43371 14.7545 9.54725 14.6163 9.66573C14.478 9.77433 14.3744 9.86319 14.3053 9.9323L13.2094 8.3921C13.6438 7.98731 14.1473 7.65656 14.7199 7.39986C15.3024 7.13329 15.9245 7 16.5859 7C17.1882 7 17.7065 7.06911 18.141 7.20733C18.5754 7.34556 18.9308 7.54302 19.2072 7.79972C19.4936 8.04654 19.7009 8.35261 19.8292 8.71791C19.9675 9.07334 20.0366 9.47814 20.0366 9.9323Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default H2;
