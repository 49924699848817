import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";

export enum PageStatus {
    PUBLISHED = "published",
    DRAFT = "draft",
}

export interface IPageResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.PAGE;
    title: string;
    rank: number;
    status: PageStatus;
}
