import clsx from "clsx";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@sol/uikit/core/Typography";

import SkipLinks from "../SkipLinks";
import LoginLayoutHero from "./LoginLayoutHero";

type LoginLayoutProps = {
    title: string;
    className?: string;
};

const LoginLayout: FC<LoginLayoutProps> = ({ children, title, className }) => {
    const [t] = useTranslation();

    return (
        <div className={clsx("flex min-h-screen flex-col bg-grey-lightest bg-pattern", className)}>
            <SkipLinks />
            <main role="main" id="main-content" className="container flex flex-1 items-center">
                <LoginLayoutHero />
                <section className="mr-4 flex w-2/5 flex-col justify-center gap-2.5 rounded-lg bg-white-base p-12">
                    <Typography variant="h1" tag="h1">
                        {t(title)}
                    </Typography>
                    {children}
                </section>
            </main>
        </div>
    );
};

export default LoginLayout;
