import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const H3 = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M10.0622 7.23204H12.3246V17.2825H10.0622V13.0186H6.26243V17.2825H4V7.23204H6.26243V11.0753H10.0622V7.23204Z"
                fill="black"
                className={fill}
            />
            <path
                d="M16.7969 17.5C16.5358 17.5 16.2603 17.4807 15.9702 17.442C15.6802 17.413 15.3998 17.3695 15.1291 17.3115C14.8583 17.2535 14.6118 17.1906 14.3894 17.1229C14.167 17.0552 13.993 16.9924 13.8673 16.9344L14.2879 15.1361C14.5393 15.2424 14.8583 15.3584 15.2451 15.4841C15.6415 15.6001 16.1297 15.6581 16.7099 15.6581C17.377 15.6581 17.8652 15.5325 18.1746 15.2811C18.484 15.0297 18.6387 14.6913 18.6387 14.2659C18.6387 14.0048 18.5807 13.7873 18.4647 13.6133C18.3583 13.4296 18.2085 13.2845 18.0151 13.1782C17.8217 13.0622 17.5897 12.9848 17.319 12.9461C17.0579 12.8978 16.7775 12.8736 16.4778 12.8736H15.6367V11.1333H16.5938C16.8065 11.1333 17.0096 11.114 17.203 11.0753C17.406 11.0366 17.5849 10.9738 17.7396 10.8867C17.8943 10.7901 18.0151 10.6644 18.1021 10.5097C18.1988 10.3453 18.2471 10.1423 18.2471 9.90055C18.2471 9.71685 18.2085 9.55732 18.1311 9.42196C18.0538 9.2866 17.9523 9.17541 17.8266 9.0884C17.7105 9.00138 17.5704 8.93854 17.406 8.89986C17.2513 8.85152 17.0918 8.82735 16.9274 8.82735C16.5117 8.82735 16.1249 8.89019 15.7672 9.01588C15.4191 9.14157 15.1 9.29627 14.81 9.47997L14.0413 7.89917C14.196 7.80249 14.3749 7.70097 14.578 7.59461C14.7907 7.48826 15.0227 7.39158 15.2741 7.30456C15.5255 7.21754 15.7914 7.14503 16.0717 7.08702C16.3618 7.02901 16.6663 7 16.9854 7C17.5752 7 18.0828 7.07251 18.5082 7.21754C18.9433 7.3529 19.301 7.55111 19.5814 7.81215C19.8618 8.06354 20.0697 8.36326 20.205 8.71133C20.3404 9.04972 20.4081 9.42196 20.4081 9.82804C20.4081 10.2244 20.2969 10.6112 20.0745 10.9883C19.8521 11.3557 19.5524 11.636 19.1753 11.8294C19.6974 12.0421 20.0987 12.3612 20.3791 12.7866C20.6691 13.2023 20.8141 13.7051 20.8141 14.2949C20.8141 14.759 20.7368 15.1892 20.5821 15.5856C20.4274 15.9724 20.1857 16.3108 19.857 16.6008C19.5282 16.8812 19.1076 17.1036 18.5952 17.268C18.0925 17.4227 17.493 17.5 16.7969 17.5Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default H3;
