import { Action } from "../types";
import { ActionType, Category, IOptInOutAction, IState } from "./types";

export const initialState = {
    initial: true,
    settings: {
        [Category.Analytics]: true,
    },
};

const reducer = (state: IState = initialState, action: Action): IState => {
    switch (action.type) {
        case ActionType.Configure:
            return {
                initial: false,
                settings: {
                    ...state.settings,
                    ...(action as IOptInOutAction).payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
