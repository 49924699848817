import React from "react";

const PaperClipFull = () => {
    return (
        <svg
            width="59"
            height="60"
            viewBox="0 0 59 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
        >
            <path
                d="M17.0656 15.2958L16.8955 40.4177C16.8746 43.4953 18.0772 46.4386 20.2387 48.6001C22.4002 50.7616 25.3434 51.9641 28.4211 51.9433C31.4987 51.9224 34.4586 50.6799 36.6495 48.4889C38.8405 46.298 40.083 43.3381 40.1039 40.2605L40.274 15.1386C40.2879 13.0868 39.4862 11.1246 38.0452 9.68365C36.6042 8.24267 34.642 7.44094 32.5903 7.45484C30.5385 7.46873 28.5653 8.29712 27.1047 9.75775C25.644 11.2184 24.8157 13.1916 24.8018 15.2434L24.6452 40.3789C24.6382 41.4047 25.0391 42.3858 25.7596 43.1063C26.4801 43.8268 27.4612 44.2277 28.487 44.2207C29.5129 44.2138 30.4995 43.7996 31.2299 43.0693C31.9602 42.339 32.3744 41.3523 32.3813 40.3265L32.5247 17.1318"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.3178 53.955L33.486 60H14.1963C13.5383 60 13 59.4559 13 58.791V14.209C13 13.5441 13.5383 13 14.1963 13H43.8037C44.4617 13 45 13.5441 45 14.209V48.3633L39.3178 53.955Z"
                fill="#5447C8"
            />
            <path d="M33.9346 48.6655L33.486 59.9999H23.3926L33.9346 48.6655Z" fill="#302D75" />
            <path
                d="M39.1681 54.1061L33.4858 60V49.5874C33.4858 48.9073 34.0242 48.3633 34.6971 48.3633H44.9999L39.1681 54.1061Z"
                fill="#7666FF"
            />
            <path
                d="M27.1049 9.75796C25.6443 11.2186 24.8159 13.1918 24.802 15.2436L24.6454 40.3791C24.6384 41.405 25.0393 42.3861 25.7598 43.1065C26.4803 43.827 27.4614 44.2279 28.4873 44.221C29.5131 44.214 30.4997 43.7998 31.2301 43.0695C31.9604 42.3392 32.3746 41.3526 32.3815 40.3267L32.525 17.132"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PaperClipFull;
