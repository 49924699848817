import { AxiosPromise } from "axios";
import isObject from "lodash/isObject";
import { useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { getResourceUuid } from "./../utils/getResourceUuid";
import { IUser } from "./IUser";
import { IUserResource } from "./IUserResource";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    user?: string | IUserResource;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useUser = ({ queryKey, user, enabled = true, ...options }: Params<IUser, any>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();
    const uuid = isObject(user) ? getResourceUuid(user) : user;

    const url = `/users/${uuid}`;
    const queryFn = useCallback(() => unwrapAPIResult(api.get<IUser>(url)), [api, url]);

    return useQuery<IUser, any>({
        queryKey: queryKey || url,
        queryFn,
        config: { ...options, enabled: !!uuid && enabled },
    });
};
