import { IUser } from "@sol/sdk";

import { ActionTypes } from "./model";

export const setUser = (user: IUser) => ({
    type: ActionTypes.SET_USER,
    user,
});

export const unsetUser = () => ({
    type: ActionTypes.UNSET_USER,
});
