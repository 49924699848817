import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const StrikeThrough = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M16.2451 7V8.27273H12.7726V17.5H11.2274V8.27273H7.75487V7H16.2451Z"
                fill="black"
                className={fill}
            />
            <path d="M7.5 13.7121H16.5V14.9091H7.5V13.7121Z" fill="black" className={fill} />
        </SvgIcon>
    );
};

export default StrikeThrough;
