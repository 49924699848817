import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IBriefResource, IClassroomResource, IGroupSpaceList, IUserResource, WorkStatus } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";
import { IPaginated } from "@sol/sdk/types/IPaginated";
import { mapRelationalFilter, OneOrManyFilter, RelationalFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        classrooms?: RelationalFilter<IClassroomResource>;
        briefs?: RelationalFilter<IBriefResource>;
        learners?: RelationalFilter<IUserResource>;
        correctedLearners?: RelationalFilter<IUserResource>;
        lastGroupWorkStatus?: OneOrManyFilter<WorkStatus>;
        briefTitle?: string;
        hasCorrections?: boolean;
    };
    queryKey?: QueryKey;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
    orderBy?: "title" | "createdAt" | "briefTitle" | "lastActivityDate" | "lastGroupWorkDate";
    order?: "asc" | "desc";
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { classrooms, briefs, learners, correctedLearners, lastGroupWorkStatus, briefTitle, hasCorrections } =
        filters ?? {};

    return {
        "brief.uuid": mapRelationalFilter(briefs),
        "learners.uuid": mapRelationalFilter(learners),
        "classroom.uuid": mapRelationalFilter(classrooms),
        "groupCorrections.learner.uuid": mapRelationalFilter(correctedLearners),
        lastGroupWorkStatus: lastGroupWorkStatus,
        "brief.title": briefTitle,
        "exists[groupCorrections]": hasCorrections,
    };
};

export const useGroupSpaces = ({
    queryKey,
    pagination,
    filters,
    order,
    orderBy,
    ...options
}: Params<IGroupSpaceList>) => {
    const api = useApi();

    const url = useMemo(
        () =>
            `/group_spaces${serialize(
                {
                    ...(!pagination ? { pagination: false } : pagination),
                    ...mapFilters(filters),
                    [`order[${orderBy === "briefTitle" ? "brief.title" : orderBy}]`]: order,
                },
                true,
            )}`,
        [pagination, filters, orderBy, order],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IGroupSpaceList>(url)), [api, url]);

    return useQuery<IGroupSpaceList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
