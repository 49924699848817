import React from "react";

const ErrorIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <circle cx="12" cy="12" r="12" fill="#CE0033" fillOpacity="0.1" />
        <path d="M12 5V14.051" stroke="#CE0033" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="12" cy="18" r="1.25" fill="#CE0033" stroke="#CE0033" strokeWidth="0.5" />
    </svg>
);

export default ErrorIcon;
