import { IGroupTasksResource } from "@sol/sdk";
import { createApiMutationHook, IApiMutationFunction } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type IGroupTaskEditMutationParameters = {
    task: RelationalValue<IGroupTasksResource>;
    title?: string;
    description?: string;
    status?: "done" | "todo";
};

export type IGroupTaskUpdateMutationResult = IGroupTasksResource;

export const groupTaskEditMutation: IApiMutationFunction<IGroupTaskEditMutationParameters> = ({
    task,
    title,
    description,
    status,
    ...data
}) => {
    return {
        method: "PUT",
        url: `/group_tasks/${mapRelationalValue(task)}`,
        data: {
            ...data,
            title,
            description,
            status,
        },
    };
};

export const useGroupTaskEditMutation = createApiMutationHook<
    IGroupTaskEditMutationParameters,
    IGroupTaskUpdateMutationResult
>(groupTaskEditMutation);
