import { INotificationList, NotificationStatus } from "@sol/sdk";
import { createApiQueryHook, IApiQueryFunction, IPaginateable, mapPagination } from "@sol/sdk/utils/api";
import { OneOrManyFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

export interface INotificationListQueryParameters extends IPaginateable {
    filters?: {
        status?: OneOrManyFilter<NotificationStatus>;
    };
}

export type INotificationListQueryResult = INotificationList;

const mapFilters = (filters: INotificationListQueryParameters["filters"]) => {
    const { status } = filters ?? {};
    return {
        status,
    };
};

export const notificationListQuery: IApiQueryFunction<INotificationListQueryParameters> = ({
    filters,
    pagination,
}) => ({
    method: "GET",
    url: `/notifications${serialize(
        {
            ...mapPagination(pagination),
            ...mapFilters(filters),
        },
        true,
    )}`,
});

export const useNotificationListQuery = createApiQueryHook<
    INotificationListQueryParameters,
    INotificationListQueryResult
>(notificationListQuery);
