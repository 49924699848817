import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IFollowUp } from "./";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    followUpFileId?: string | null;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useFollowUp = ({ queryKey, followUpFileId, ...options }: Params<IFollowUp>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(() => `/follow_ups/${followUpFileId}`, [followUpFileId]);

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IFollowUp>(url)), [api, url]);

    return useQuery<IFollowUp>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
