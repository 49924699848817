import SvgIcon, { classes } from "@sol/icons/SvgIcon";
import { SvgIconProps } from "@sol/icons/types";

const BriefCase = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M17.6 7.79999H6.4C5.6268 7.79999 5 8.42679 5 9.19999V16.2C5 16.9732 5.6268 17.6 6.4 17.6H17.6C18.3732 17.6 19 16.9732 19 16.2V9.19999C19 8.42679 18.3732 7.79999 17.6 7.79999Z"
                stroke="#16151E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M14.8 17.6V6.4C14.8 6.0287 14.6525 5.6726 14.3899 5.41005C14.1273 5.1475 13.7713 5 13.4 5H10.6C10.2286 5 9.87255 5.1475 9.61 5.41005C9.34745 5.6726 9.19995 6.0287 9.19995 6.4V17.6"
                stroke="#16151E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default BriefCase;
