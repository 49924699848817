import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Person = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M17 19.5V15.4591C17 14.6743 16.719 13.9216 16.219 13.3667C15.7189 12.8118 15.0406 12.5 14.3333 12.5H9.66667C8.95942 12.5 8.28115 12.8118 7.78105 13.3667C7.28095 13.9216 7 14.6743 7 15.4591V19.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M12 9.5C13.6569 9.5 15 8.15685 15 6.5C15 4.84315 13.6569 3.5 12 3.5C10.3431 3.5 9 4.84315 9 6.5C9 8.15685 10.3431 9.5 12 9.5Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Person;
