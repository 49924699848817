import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Message = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M18 14C18 14.3536 17.8595 14.6928 17.6095 14.9428C17.3594 15.1929 17.0203 15.3333 16.6667 15.3333H8.66667L6 18V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H16.6667C17.0203 6 17.3594 6.14048 17.6095 6.39052C17.8595 6.64057 18 6.97971 18 7.33333V14Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Message;
