import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import { useRouter as useNextRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import { interpolate } from "./interpolate";
import { Route } from "./Route";

const HISTORY_STATE_ROOT = "sol";

const getHistoryState = () => typeof window !== "undefined" && window.history?.state?.options?.[HISTORY_STATE_ROOT];

type HistoryStateListener = (state: any, prevState: any) => void;

const watchHistoryState = (() => {
    let prevState = getHistoryState();
    const poll = () => {
        const currentState = getHistoryState();
        if (!isEqual(prevState, currentState)) {
            prevState = currentState;
            listeners.forEach(listener => listener(currentState, prevState));
        }
    };

    let pollingHandle: any = null;
    const startPolling = () => {
        // We use polling to subscribe to history state change
        // There are no clear events other than "onpopstate" to do that
        pollingHandle = setInterval(poll, 100);
    };
    const stopPolling = () => {
        clearInterval(pollingHandle);
    };

    const listeners = new Map<string, HistoryStateListener>();
    // Add a callback to the listeners list and returns a function to remove it from list
    const listen = (callback: HistoryStateListener) => {
        const id = uuid();
        listeners.set(id, callback);

        return () => {
            listeners.delete(id);
        };
    };

    // A watch function that start the polling when there is at leat 1 listener
    // and automatically stop polling wha
    return (callback: HistoryStateListener) => {
        if (listeners.size === 0) {
            startPolling();
        }

        const free = listen(callback);

        return () => {
            free();

            if (listeners.size === 0) {
                stopPolling();
            }
        };
    };
})();

export const useRouter = () => {
    const [historyState, setHistoryState] = useState(getHistoryState());
    const router = useNextRouter();

    // We use router as ref and state as ref in order to have push and
    // replace to have constant reference value and not trigger change
    // when used with memoization
    const routerRef = useRef(router);
    routerRef.current = router;
    const stateRef = useRef(historyState);

    useEffect(() => {
        return watchHistoryState(state => {
            stateRef.current = state;
            setHistoryState(state);
        });
    }, []);

    const push = useCallback(
        (options: { route?: Route; query?: ParsedUrlQuery; state?: SetStateAction<any> }) => {
            const router = routerRef.current;
            const { route = router.pathname, query = {}, state } = options;

            const asPath = interpolate(route as Route, query);

            // Extract search string from "URL"
            const search = new URL(`http://localhost${asPath}`).search;

            router.push(`${route}${search ?? ""}`, asPath, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [HISTORY_STATE_ROOT]: isFunction(state) ? state(stateRef.current) : state,
            });
        },
        [router.pathname, router.push],
    );

    const replace = useCallback(
        (options: { route?: Route; query?: ParsedUrlQuery; state?: SetStateAction<any> }) => {
            const router = routerRef.current;
            const { route = router.route, query = router.query, state } = options;

            const asPath = interpolate(route as Route, query);

            // Extract search string from "URL"
            const search = new URL(`http://localhost${asPath}`).search;

            router.replace(`${route}${search ?? ""}`, asPath, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [HISTORY_STATE_ROOT]: isFunction(state) ? state(stateRef.current) : state,
            });
        },
        [router.replace, router.route, router.query],
    );

    return {
        ...router,
        state: historyState,
        push,
        replace,
        canGoBack: typeof window !== "undefined" && document.referrer.indexOf(window.location.hostname) > -1,
    };
};

export default useRouter;
