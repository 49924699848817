import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { simplonWebsiteUrl } from "../../../constants/externalLinks";
import { Text } from "../../../uikit";

const SimplonLink = styled(Text)`
    && {
        color: ${({ theme }) => theme.palette.red.base};
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin-top: ${({ theme }) => theme.spacing[7]};
        font-size: 1.8rem;
    }
`;

const LoginLayoutHeroContainer = styled.header`
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoginLayoutHeroContent = styled.div`
    max-width: 520px;
    margin: ${({ theme }) => theme.spacing[7]};
    display: flex;
    flex-direction: column;

    & > :first-child {
        align-self: flex-end;
    }
`;

const SimplonLogo = styled.img`
    margin-bottom: ${({ theme }) => theme.spacing[10]};
`;

const LoginLayoutHero = () => {
    const [t] = useTranslation();

    return (
        <LoginLayoutHeroContainer role="banner">
            <LoginLayoutHeroContent>
                <SimplonLogo src="/static/sol-logo.png" alt="Simplonline" />
                <Text variant="h3">{t("layout.login.heroIntroduction")}</Text>
                <SimplonLink
                    variant="h3"
                    lang="en"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={simplonWebsiteUrl}
                >
                    {t("page.login.hero.poweredBySimplon")}
                </SimplonLink>
            </LoginLayoutHeroContent>
        </LoginLayoutHeroContainer>
    );
};

export default memo(LoginLayoutHero);
