import { IModule } from "redux-dynamic-modules";

import reducer from "./reducer";
import { IStoreModuleState, IState } from "./types";

export type IGDPRStoreModuleState = IStoreModuleState;
export type IGDPRState = IState;

const storeModule: IModule<IGDPRStoreModuleState> = {
    id: "gdpr",
    reducerMap: {
        gdpr: reducer,
    },
};

export default storeModule;
