import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Link = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M10.5918 12.6969C10.8941 13.1011 11.2798 13.4355 11.7228 13.6775C12.1657 13.9195 12.6555 14.0634 13.1589 14.0995C13.6624 14.1355 14.1677 14.0629 14.6406 13.8865C15.1135 13.7101 15.5429 13.434 15.8997 13.0771L18.0117 10.9651C18.6528 10.3013 19.0076 9.41217 18.9996 8.48927C18.9916 7.56638 18.6214 6.68355 17.9688 6.03094C17.3162 5.37833 16.4333 5.00815 15.5105 5.00013C14.5876 4.99211 13.6984 5.3469 13.0346 5.98807L11.8237 7.19186"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.4079 11.2889C13.1056 10.8847 12.7199 10.5503 12.277 10.3083C11.834 10.0663 11.3442 9.92243 10.8408 9.88637C10.3373 9.85032 9.83204 9.92296 9.35914 10.0994C8.88624 10.2758 8.45681 10.5518 8.09998 10.9088L5.98807 13.0207C5.3469 13.6845 4.99211 14.5737 5.00013 15.4966C5.00815 16.4195 5.37833 17.3023 6.03094 17.9549C6.68355 18.6075 7.56638 18.9777 8.48927 18.9857C9.41217 18.9937 10.3013 18.6389 10.9651 17.9978L12.1689 16.794"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
