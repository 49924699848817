import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Text } from "../../../uikit";

const SkipLink = styled.a`
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 8px 16px;
    z-index: 999;
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    text-decoration: none;
    transform: translateX(-105%);
    transition: transform 0.3s;

    &:focus {
        outline-width: 3px;
        transform: translateX(0);
    }

    @media (prefers-reduced-motion: reduce) {
        transition: all 0s;
    }
`;

const SkipLinks = () => {
    const [t] = useTranslation();

    return (
        <SkipLink href="#main-content">
            <Text variant="label">{t("layout.skip-links.go-to-main-content")}</Text>
        </SkipLink>
    );
};

export default SkipLinks;
