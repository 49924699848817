import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

interface PutFollowUpItemVariables {
    uuid: string;
    learner: string;
    title: string;
    waypoint: string;
    missions?: string[];
    briefs?: string[];
    periodStart: string;
    periodEnd: string;
}

const putFollowup =
    (api: AxiosInstance) =>
    ({ uuid, ...payload }: PutFollowUpItemVariables) =>
        api.put(`/follow_ups/${uuid}`, payload);

export const usePutFollowUp = (config: MutateConfig<AxiosResponse, any, PutFollowUpItemVariables>) => {
    const api = useApi();
    const putFollowUpMutation = useMemo(() => putFollowup(api), [api]);

    const [updateFollowUp, mutationState] = useMutation(putFollowUpMutation, config);

    return { updateFollowUp, ...mutationState };
};
