import { AxiosRequestConfig } from "axios";

import { IClassroom, IClassroomWebinar } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type IClassroomWebinarQueryParameters = {
    classroom?: RelationalValue<IClassroom>;
};

export type IClassroomWebinarQueryResult = IClassroomWebinar;

export function classroomWebinarQuery({ classroom }: IClassroomWebinarQueryParameters): AxiosRequestConfig | undefined {
    if (!classroom) {
        return;
    }

    return {
        method: "GET",
        url: `/classrooms/${mapRelationalValue(classroom)}/webinar`,
    };
}

export const useClassroomWebinarQuery = createApiQueryHook<
    IClassroomWebinarQueryParameters,
    IClassroomWebinarQueryResult
>(classroomWebinarQuery);
