import React, { FC } from "react";
import styled from "styled-components";

import BaseSkeleton from "./BaseSkeleton";

const LineSkeleton = styled(BaseSkeleton)<{ width?: string; height?: string }>`
    height: ${({ height }) => height || "1em"};
    width: ${({ width }) => width || "100%"};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    display: inline-block;
`;

type Props = {
    count?: number;
    width?: string;
    height?: string;
    color?: string;
    highlightColor?: string;
    className?: string;
};

const TextSkeleton: FC<Props> = ({ count, width, height, color, highlightColor, className }) => {
    return count ? (
        <>
            {new Array(count).fill(null).map(c => (
                <LineSkeleton
                    key={c}
                    width={width}
                    height={height}
                    color={color}
                    highlightColor={highlightColor}
                    className={className}
                />
            ))}
        </>
    ) : (
        <LineSkeleton
            width={width}
            height={height}
            color={color}
            highlightColor={highlightColor}
            className={className}
        />
    );
};

export default styled(TextSkeleton)``;
