import { EntityTypes, IGroupSpace, IGroupTasksResource } from "@sol/sdk";
import { IApiMutationFunction, createApiMutationHook } from "@sol/sdk/utils/api";
import { RelationalValue, mapRelationalValueAsIRI } from "@sol/sdk/utils/filters";

export type IGroupTaskCreateMutationParameters = {
    uuid: string;
    groupSpace?: RelationalValue<IGroupSpace>;
    title: string;
    description?: string;
};

export type IGroupTaskCreateMutationResult = IGroupTasksResource;

export const groupTaskCreateMutation: IApiMutationFunction<IGroupTaskCreateMutationParameters> = ({
    groupSpace,
    title,
    description,
    ...data
}) => {
    return {
        method: "POST",
        url: "/group_tasks",
        data: {
            ...data,
            groupSpace: mapRelationalValueAsIRI(EntityTypes.GROUP_SPACE, groupSpace),
            title,
            description,
        },
    };
};

export const useGroupTaskCreateMutation = createApiMutationHook<
    IGroupTaskCreateMutationParameters,
    IGroupTaskCreateMutationResult
>(groupTaskCreateMutation);
