import { isString, isUndefined } from "lodash";

import EntityTypes from "../EntityTypes";
import { IHydraResource } from "../types/IHydraResource";

export const uuidRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;

export type EntityTypesWithComputableIRI = Exclude<EntityTypes, EntityTypes.GROUP_CORRECTED_SKILL_LEVEL>;

export const getResourceIRI = (resource: Pick<IHydraResource, "@id">) => resource["@id"];

export const buildResourceIRI = <T extends Pick<IHydraResource, "@id">>(
    type: EntityTypesWithComputableIRI,
    resource: T | string | undefined,
) => {
    if (isUndefined(resource)) {
        return;
    }

    const uuid = getResourceUuid(resource);

    const BUILD_MAP: Record<EntityTypesWithComputableIRI, string> = {
        [EntityTypes.BRAND]: "/brands",
        [EntityTypes.BRIEF]: "/briefs",
        [EntityTypes.CATEGORY]: "/categories",
        [EntityTypes.CLASSROOM]: "/classrooms",
        [EntityTypes.COMMENT]: "/comments",
        [EntityTypes.CURRENT_BRAND]: "/current_brand",
        [EntityTypes.DATAVIZ_DAY_POINT]: "/dataviz_day_points",
        [EntityTypes.DATAVIZ_LINE]: "/dataviz_lines",
        [EntityTypes.EDUCATIONAL_BRIEF]: "/educational_briefs",
        [EntityTypes.EDUCATIONAL_SCENARIO_ITEM]: "/educational_scenario_items",
        [EntityTypes.EDUCATIONAL_SCENARIO_SLOT]: "/educational_scenario/classrooms",
        [EntityTypes.EVALUATION]: "/evaluations",
        [EntityTypes.FACTORY]: "/factories",
        [EntityTypes.FOLLOW_UP]: "/follow_ups",
        [EntityTypes.FRAMEWORK]: "/frameworks",
        [EntityTypes.GROUP_CORRECTION]: "/group_corrections",
        [EntityTypes.GROUP_MESSAGE]: "/group_messages",
        [EntityTypes.GROUP_SPACE]: "/group_spaces",
        [EntityTypes.GROUP_TASK]: "/group_tasks",
        [EntityTypes.GROUP_WORK]: "/group_works",
        [EntityTypes.LANGUAGE]: "/languages",
        [EntityTypes.LOCATION]: "/locations",
        [EntityTypes.MEDIA]: "/media",
        [EntityTypes.MEDIA_SECURED]: "/media_secureds",
        [EntityTypes.MISSION]: "/missions",
        [EntityTypes.NOTIFICATION]: "/notifications",
        [EntityTypes.PAGE]: "/pages",
        [EntityTypes.PROFESSIONAL_SITUATION]: "/professional_situations",
        [EntityTypes.RESOURCE]: "/resources",
        [EntityTypes.SELF_EVALUATION]: "/self_evaluations",
        [EntityTypes.SKILL]: "/skills",
        [EntityTypes.SKILL_LEVEL]: "/skill_levels",
        [EntityTypes.STATISTIC]: "/statistics",
        [EntityTypes.TAG]: "/tags",
        [EntityTypes.TOPIC]: "/topics",
        [EntityTypes.USER]: "/users",
        [EntityTypes.USER_SETTINGS]: "/user_settings",
        [EntityTypes.VALIDATION]: "/validations",
        [EntityTypes.WORK]: "/works",
    };

    return [BUILD_MAP[type], uuid].join("/");
};

export const getResourceUuid = <T extends Pick<IHydraResource, "@id">>(resource: T | string) => {
    const { ["@id"]: id } = isString(resource) ? { "@id": resource } : resource;

    const matchResult = id.match(uuidRegex);

    if (!matchResult || matchResult.length <= 1) {
        throw new Error("Cannot retrieve uuid from @id");
    }

    return matchResult[1];
};
