import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";
import { IUserResource, UserRole } from "../users";

export interface IFollowUpAuthor extends IUserResource {
    role: UserRole;
}

export interface IFollowUpResource extends IHydraResource, Pick<ITimestampable, "createdAt"> {
    learner: IUserResource;
    createdBy: IFollowUpAuthor;
    periodStart: string;
    periodEnd: string;
    periodType: ("classroom" | "company")[];
    title: string;
    nbComments: number;
}

export function isFollowUpResource(resource: IHydraResource): resource is IFollowUpResource {
    return resource["@type"] === EntityTypes.FOLLOW_UP;
}
