import { Button, ConfigProvider, Flex, theme, type ThemeConfig } from "antd";
import { cx } from "class-variance-authority";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";

import { useActiveClassroom, useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { getResourceUuid, INotificationResource, NotificationStatus, useNotificationListQuery } from "@sol/sdk";
import { useNotificationUpdateMutation } from "@sol/sdk/notifications";
import { Bell } from "@sol/uikit/core/icons";

import useClickAway from "../../../hooks/useClickAway";
import SOLNotification from "../../SOLNotification";
import { getRedirectUrlNotification } from "./getRedirectUrlNotification";

const PER_PAGE = 5;

export const Notifications = () => {
    const { useToken } = theme;
    const { token } = useToken();
    const { classrooms = [] } = useAuthenticatedUserClassrooms();
    const { setActiveClassroom } = useActiveClassroom();
    const router = useRouter();

    const notificationsListRef = useRef<HTMLDivElement>(null);

    const [displayedNotifications, setDisplayedNotifications] = useState<INotificationResource[] | []>([]);
    const [page, setPage] = useState(1);
    const [numberPageMax, setNumberPageMax] = useState(0);
    const [showNotificationList, setShowNotificationList] = useClickAway(notificationsListRef);

    const hasMore = page < numberPageMax;

    const {
        data: notifications,
        isLoading,
        refetch: refetchNotifications,
    } = useNotificationListQuery({
        pagination: { page, perPage: PER_PAGE },
    });

    const [updateNotification, { isLoading: isUpdateMutationLoading }] = useNotificationUpdateMutation({
        onSuccess: () => refetchNotifications(),
    });

    const themeConfig: ThemeConfig = {
        cssVar: { key: "notifications" },
        token: {
            controlHeight: 26,
        },
        components: {
            Button: {
                defaultColor: token.colorPrimary,
                defaultBorderColor: token.colorWhite,
                defaultHoverBg: token.colorPrimary,
                defaultHoverColor: token.colorWhite,
                defaultHoverBorderColor: token.colorPrimary,
            },
        },
    };

    useEffect(() => {
        if (!notifications) {
            refetchNotifications();
        } else {
            setDisplayedNotifications([
                ...(notifications["hydra:member"].filter(
                    notification => notification.status !== NotificationStatus.HIDDEN,
                ) ?? []),
            ]);
            setNumberPageMax(Math.ceil(notifications["hydra:totalItems"] / PER_PAGE));
        }
    }, [notifications, refetchNotifications]);

    const handleLoadMore = useCallback(() => {
        if (hasMore) {
            setPage(page => page + 1);
            refetchNotifications();
        }
    }, [hasMore, refetchNotifications]);

    const updateStatusNotification = useCallback(
        async (notification: INotificationResource, status: NotificationStatus) => {
            if (notification.status === status) {
                return notification;
            }
            updateNotification({ notification, status });
        },
        [updateNotification],
    );

    const updateArrAllNotifications = useCallback(
        notificationUpdated =>
            [
                ...displayedNotifications?.filter(item => item["@id"] !== notificationUpdated["@id"]),
                notificationUpdated,
            ].filter(item => item.status !== NotificationStatus.HIDDEN),
        [displayedNotifications],
    );

    const handleClickNotification = useCallback(
        async (notification: INotificationResource) => {
            await updateNotification({ notification, status: NotificationStatus.READ });
            const classroomIRI = notification.recipientClassroom;
            const classroom = classrooms.find(cu => cu["@id"] === classroomIRI);

            if (classroom) {
                setActiveClassroom(classroom["@id"]);
            }
            const updatedNotifications = updateArrAllNotifications(notification);
            setDisplayedNotifications(updatedNotifications);
            const { href, as } = getRedirectUrlNotification(notification, getResourceUuid(classroomIRI));
            router.push(href, as);
            setShowNotificationList(false);
        },
        [
            classrooms,
            setActiveClassroom,
            router,
            setShowNotificationList,
            updateArrAllNotifications,
            updateStatusNotification,
        ],
    );

    const updateAllNotifications = useCallback(
        async (status: NotificationStatus, conditionFn?: (v: INotificationResource) => boolean) => {
            const updatedNotifications = await Promise.all(
                displayedNotifications.map(async notification => {
                    if (conditionFn && conditionFn(notification)) {
                        return notification;
                    }
                    return await updateNotification({ notification, status });
                }),
            );

            const filteredNotifications = updatedNotifications.map(
                notification => notification as unknown as INotificationResource,
            );

            setDisplayedNotifications(filteredNotifications);
        },
        [displayedNotifications, updateNotification],
    );

    const removeOneNotification = useCallback(
        async (notification: INotificationResource) => {
            const updatedNotification = await updateNotification({ notification, status: NotificationStatus.HIDDEN });
            const updatedNotifications = updateArrAllNotifications(updatedNotification);
            setDisplayedNotifications(updatedNotifications);
        },
        [updateArrAllNotifications, updateNotification],
    );

    const removeAllNotifications = useCallback(async () => {
        await updateAllNotifications(NotificationStatus.HIDDEN);
        setDisplayedNotifications([]);
        setShowNotificationList(false);
        refetchNotifications();
    }, [updateArrAllNotifications, setDisplayedNotifications, refetchNotifications]);

    const transformNewToSeen = useCallback(() => {
        updateAllNotifications(NotificationStatus.SEEN, item => item.status !== NotificationStatus.NEW);
    }, [updateArrAllNotifications]);

    const isNewNotification = useMemo(
        () => notifications?.["hydra:member"].some(item => item.status === NotificationStatus.NEW),
        [notifications],
    );
    const isNewNotificationCssClasses = useMemo(
        () => [
            "after:content['']",
            "after:absolute",
            "after:top-0",
            "after:-right-px",
            "after:w-1.5",
            "after:h-1.5",
            "after:rounded-full",
            "after:bg-[color:var(--ant-color-error)]",
        ],
        [isNewNotification],
    );

    return (
        <Flex justify="center" align="center" className="relative ml-auto">
            <ConfigProvider theme={themeConfig}>
                <Button
                    shape="circle"
                    onClick={() => {
                        setShowNotificationList(true);
                    }}
                    className={cx([
                        isNewNotification ? isNewNotificationCssClasses : "",
                        "bg-inherit inline-flex items-center justify-center bg-[color:--ant-color-white] relative",
                    ])}
                >
                    <Bell fill="transparent-base" />
                </Button>
            </ConfigProvider>
            {showNotificationList && (
                <SOLNotification
                    listRef={notificationsListRef}
                    allNotifs={displayedNotifications}
                    removeOneNotif={removeOneNotification}
                    removeAllNotif={async () => {
                        if (notifications) {
                            removeAllNotifications();
                        }
                    }}
                    showLoader={isLoading || isUpdateMutationLoading}
                    handleClickNotif={handleClickNotification}
                    transformNewToSeen={transformNewToSeen}
                    infiniteScroll={children => (
                        <>
                            {children}
                            {hasMore && <div>Loading</div>}
                            <Waypoint
                                bottomOffset="-160px"
                                onEnter={() => {
                                    handleLoadMore();
                                }}
                            />
                        </>
                    )}
                />
            )}
        </Flex>
    );
};
