import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";

export enum MediaType {
    URL = "url",
    FILE = "file",
}

export interface IMediaResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.MEDIA;
    title: string;
    mimetype: string;
    size: number;
    width?: number;
    height?: number;
    url: string;
    type: MediaType;
}
