import React, { FC } from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Move: FC<SvgIconProps> = props => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <circle cx={14.665} cy={8} className={fill} r={1.333} />
            <circle cx={14.665} cy={16} className={fill} r={1.333} />
            <circle cx={14.665} cy={12} className={fill} r={1.333} />
            <circle cx={8.333} cy={8} className={fill} r={1.333} />
            <circle cx={8.333} cy={16} className={fill} r={1.333} />
            <circle cx={8.333} cy={12} r={1.333} className={fill} />
        </SvgIcon>
    );
};

export default styled(Move)``;
