import { ILanguageResource, ISkill, IUserResource } from "..";
import EntityTypes from "../EntityTypes";
import { ITimestampable, IHydraResource } from "../types";

interface ProfessionalSituationBrief extends IHydraResource {
    title: string;
}

export interface IProfessionalSituationResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.PROFESSIONAL_SITUATION;
    title: string;
    targetedDomain: string;
    problematic: string;
    description: string;
    skills: ISkill[];
    nbSkills: number;
    language: ILanguageResource;
    briefs?: ProfessionalSituationBrief[];
    createdBy: IUserResource;
}

export function isProfessionalSituationResource(resource: IHydraResource): resource is IProfessionalSituationResource {
    return resource["@type"] === EntityTypes.PROFESSIONAL_SITUATION;
}
