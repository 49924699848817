import { Button } from "antd";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Merge } from "type-fest";

import { Arrow } from "@sol/icons";
import { useGoBackNavigation } from "@sol/routing/useGoBackNavigation";
import { Typography } from "@sol/uikit/core/Typography";

import { AuthenticatedLayoutContent, AuthenticatedLayoutHeader } from "./AuthenticatedLayout";

type Props = Merge<
    ComponentPropsWithoutRef<typeof AuthenticatedLayoutContent>,
    {
        showBackButton?: boolean;
        title?: ReactNode;
    }
>;

export function PageLayout({ title, children, showBackButton, ...props }: Props) {
    const { goBack } = useGoBackNavigation();

    return (
        <>
            {title ? (
                <AuthenticatedLayoutHeader>
                    <div className="flex items-center gap-2">
                        {showBackButton ? (
                            <Button
                                onClick={() => goBack()}
                                shape="circle"
                                className="
                                inline-flex
                                items-center justify-center border-[color:var(--ant-color-white)] bg-[color:var(--ant-color-white)]
                                                text-[color:var(--ant-color-primary)] [--icon-color:var(--ant-color-primary)]"
                            >
                                <Arrow direction="left" />
                            </Button>
                        ) : null}
                        <Typography tag="h1" variant="h3" className="flex items-center gap-[var(--ant-margin-sm)]">
                            {title}
                        </Typography>
                    </div>
                </AuthenticatedLayoutHeader>
            ) : null}
            <AuthenticatedLayoutContent {...props}>{children}</AuthenticatedLayoutContent>
        </>
    );
}
