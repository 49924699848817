import { SvgIcon, type SvgIconProps } from "./SvgIcon";

export const Binder = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 4C5.73478 4 5.48043 4.10536 5.29289 4.2929C5.10535 4.48043 5 4.73479 5 5.00001L5.00012 19C5.00012 19.5523 5.44784 20 6.00012 20H18C18.5523 20 19 19.5523 19 19V5C19 4.44772 18.5523 4 18 4H6ZM7.00011 18L7.00007 13H17V18H7.00011ZM17 11H7.00005L7.00001 6H17V11ZM10 14.5C9.44772 14.5 9 14.9477 9 15.5C9 16.0523 9.44772 16.5 10 16.5H14C14.5523 16.5 15 16.0523 15 15.5C15 14.9477 14.5523 14.5 14 14.5H10ZM10 7.5C9.44772 7.5 9 7.94772 9 8.5C9 9.05228 9.44772 9.5 10 9.5H14C14.5523 9.5 15 9.05228 15 8.5C15 7.94772 14.5523 7.5 14 7.5H10Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
