import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const EmptyWork = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M2 10.8841H5.57945C5.95825 10.8841 6.30453 11.0981 6.47391 11.4369L7.97877 14.4472C8.14815 14.786 8.49443 15 8.87323 15H15.1505C15.5312 15 15.8789 14.7838 16.0473 14.4424L17.5274 11.4417C17.6958 11.1002 18.0435 10.8841 18.4243 10.8841H22L19.2281 3.64252C19.08 3.25554 18.7085 3 18.2942 3H5.70581C5.29145 3 4.92002 3.25554 4.77189 3.64252L2 10.8841Z"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M22 11V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V11"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default EmptyWork;
