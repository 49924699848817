import isArray from "lodash/isArray";
import isString from "lodash/isString";

import { IHydraResource } from "../types/IHydraResource";
import { EntityTypesWithComputableIRI, buildResourceIRI, getResourceUuid } from "./getResourceUuid";

export type OneOrManyFilter<T> = T | T[];

export type RelationalFilter<T extends Pick<IHydraResource, "@id">> = OneOrManyFilter<string> | OneOrManyFilter<T>;

export type RelationalValue<T extends Pick<IHydraResource, "@id">> = string | T;

export const mapRelationalValue = <T extends Pick<IHydraResource, "@id">>(value: RelationalValue<T> | undefined) =>
    value && getResourceUuid(isString(value) ? { "@id": value } : value);

export const mapRelationalFilter = <T extends Pick<IHydraResource, "@id">>(filter?: RelationalFilter<T>) => {
    if (!filter) {
        return;
    }

    if (!isArray(filter)) {
        return mapRelationalValue(filter);
    }

    // Here we cast as we are sure the value is not undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ignore map error due to typscript version
    return filter.map((value: string | T) => mapRelationalValue(value) as string);
};

// This function maps a relational value to its IRI
export const mapRelationalValueAsIRI = <T extends Pick<IHydraResource, "@id">>(
    entityType: EntityTypesWithComputableIRI,
    value: RelationalValue<T> | undefined,
) => buildResourceIRI(entityType, value);

// This function maps a relational filter to an IRI or an array of IRIs
export const mapRelationalFilterToIRI = <T extends Pick<IHydraResource, "@id">>(
    entityType: EntityTypesWithComputableIRI,
    filter?: RelationalFilter<T>,
) => {
    if (!filter) {
        return;
    }

    if (!isArray(filter)) {
        return mapRelationalValueAsIRI(entityType, filter);
    }

    return filter.map((value: string | T) => mapRelationalValueAsIRI(entityType, value) as string);
};
