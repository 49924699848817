import { AxiosRequestConfig } from "axios";

import { INotificationResource } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { RelationalValue, mapRelationalValue } from "@sol/sdk/utils/filters";

export type INotificationQueryParameters = {
    notification?: RelationalValue<INotificationResource>;
};

export type INotificationQueryResult = INotificationResource;

export function notificationQuery({ notification }: INotificationQueryParameters): AxiosRequestConfig | undefined {
    if (!notification) {
        return;
    }

    return {
        method: "GET",
        url: `/notifications/${mapRelationalValue(notification)}`,
    };
}

export const useNotifiationQuery = createApiQueryHook<INotificationQueryParameters, INotificationQueryResult>(
    notificationQuery,
);
