import React from "react";

const CloseIcon = ({ color = "#F0F0F0", ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        {...props}
    >
        <circle cx="12" cy="12" r="12" fill={color} />
        <path d="M16 8L8 16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 8L16 16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default CloseIcon;
