import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

type Direction = "up" | "down";

const DIRECTIONS: {
    [key in Direction]: number;
} = {
    up: 0,
    down: 180,
};

type Props = { direction?: Direction };

const StyledSvgIcon = styled(SvgIcon)<Props>`
    transform: ${({ direction }) => direction && `rotateZ(${DIRECTIONS[direction]}deg)`};
    transition: transform 0.2s ease-in-out;
`;

const Order = (props: Props & SvgIconProps) => {
    const { fill } = classes;

    return (
        <StyledSvgIcon {...props}>
            <path
                d="M11.1999 8.38182C11.5999 7.87273 12.3999 7.87273 12.7999 8.38182L16.7999 13.4727C17.2943 14.102 16.8239 15 15.9999 15H7.99986C7.17582 15 6.70544 14.102 7.19986 13.4727L11.1999 8.38182Z"
                fill="black"
                className={fill}
            />
        </StyledSvgIcon>
    );
};

export default Order;
