import { App } from "antd";
import { useRouter } from "next/router";
import { ReactNode } from "react";

import { Route } from "@sol/routing";

import useToken from "../../hooks/useToken";
import { AuthenticatedLayout } from "./AuthenticatedLayout";
import LoginLayout from "./LoginLayout";

type AppLayoutProps = {
    title: string;
    children: ReactNode;
};

export function AppLayout({ children, ...props }: AppLayoutProps) {
    const token = useToken();
    const router = useRouter();

    let Layout = token ? AuthenticatedLayout : LoginLayout;
    if (token && router.pathname.startsWith(Route.ADMIN)) {
        return children;
    }

    if (router.pathname.includes(Route.GDPR_DISCLAIMER)) {
        Layout = LoginLayout;
    }

    return (
        <App>
            <Layout {...props}>{children}</Layout>
        </App>
    );
}
