import { cva } from "class-variance-authority";
import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Direction = "up" | "down";
type Props = { direction?: Direction; className?: string };

const orderVariants = cva(["transition-transform", "ease-in-out", "transition-200"], {
    variants: {
        variant: {
            up: ["rotate-0"],
            down: ["rotate-180"],
        },
    },
});

export const Order = ({ direction, className, ...props }: Props & SvgIconProps) => {
    return (
        <SvgIcon {...props} className={orderVariants({ variant: direction, className })}>
            <path d="M11.1999 8.38182C11.5999 7.87273 12.3999 7.87273 12.7999 8.38182L16.7999 13.4727C17.2943 14.102 16.8239 15 15.9999 15H7.99986C7.17582 15 6.70544 14.102 7.19986 13.4727L11.1999 8.38182Z" />
        </SvgIcon>
    );
};
