import EntityTypes from "../EntityTypes";
import { ISkillLevelResource } from "../skillLevels";
import { ISkillResource } from "../skills";
import { IHydraResource, ITimestampable } from "../types";

export enum ValidationStatus {
    VALIDATED = "validated",
    UNVALIDATED = "unvalidated",
}

interface IValidationSkillLevel extends ISkillLevelResource {
    skill: ISkillResource;
}

export interface IValidationResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.VALIDATION;
    status: ValidationStatus;
    skillLevel: IValidationSkillLevel;
}
