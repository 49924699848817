import isString from "lodash/isString";
import { ReactNode, Children } from "react";
import styled, { css } from "styled-components";

import { SvgIcon } from "@sol/icons";

import { Theme } from "../../../uikit/theme";
import { ButtonVariant } from "./Button";

export const wrapStringChildren = (child: ReactNode) => {
    // We wrap text content with span in order to
    // target icon as first and last child
    if (isString(child)) {
        return <span>{child}</span>;
    }

    return child;
};

type GhostButtonProps = {
    /**
     * Define the button colorscheme variant
     */
    variant?: ButtonVariant;
    /**
     * Disable button without visual feedback
     */
    loading?: boolean;
    /**
     * Reduce the size of the button
     */
    small?: boolean;
    /**
     * Selected position
     */
    selected?: boolean;
};

const getVariant = (theme: Theme, { variant, selected }: GhostButtonProps) => {
    const white = theme.palette.white.base;

    switch (variant) {
        case "primary":
            return {
                textColor: theme.palette.purple.base,
                backgroundColor: theme.palette.purple.lighter,
                focusColor: theme.palette.purple.light,
                ...(selected && {
                    backgroundColor: theme.palette.purple.base,
                    textColor: white,
                }),
            };
        case "success":
            return {
                textColor: theme.palette.green.base,
                backgroundColor: theme.palette.green.lightest,
                focusColor: theme.palette.green.light,
                ...(selected && {
                    backgroundColor: theme.palette.green.base,
                    textColor: white,
                }),
            };
        case "danger":
            return {
                textColor: theme.palette.red.base,
                backgroundColor: theme.palette.red.lightest,
                focusColor: theme.palette.red.light,
                ...(selected && {
                    backgroundColor: theme.palette.red.base,
                    textColor: white,
                }),
            };
        default:
            return {
                textColor: theme.palette.grey.base,
                backgroundColor: theme.palette.grey.lightest,
                focusColor: theme.palette.grey.light,
                ...(selected && {
                    backgroundColor: theme.palette.grey.base,
                    textColor: white,
                }),
            };
    }
};

export const style = ({
    theme,
    variant = "default",
    small,
    loading,
    selected,
}: {
    theme: Theme;
} & GhostButtonProps) => {
    const { backgroundColor, textColor } = getVariant(theme, {
        variant,
        selected,
    });

    const iconSize = `${small ? 1.6 : 2.4}rem`;
    const borderSize = theme.spacing[1];

    const verticalPadding = small
        ? `calc(${theme.spacing[2]} - (${borderSize}))`
        : `calc(${theme.spacing[4]} - (${borderSize}))`;
    const horizontalPadding = small
        ? `calc(${theme.spacing[3]} - (${borderSize}))`
        : `calc(${theme.spacing[6]} - (${borderSize}))`;

    return css`
        ${small ? theme.typography.subheading : theme.typography.button};
        color: ${textColor};
        --icon-color: ${textColor};

        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing[3]};

        margin: ${({ theme }) => theme.spacing[2]};
        padding: ${verticalPadding} ${horizontalPadding};
        border-radius: ${theme.shape.borderRadius.medium};
        border: ${borderSize} solid transparent;

        background-color: ${selected ? backgroundColor : "transparent"};

        cursor: pointer;
        transition: all 0.2s;
        will-change: box-shadow, transform;

        :focus {
            outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
            outline-offset: 2px;
        }

        :hover,
        :active {
            background-color: ${backgroundColor};
        }

        :disabled {
            ${!loading && "opacity: 0.65;"}
            pointer-events: none;
        }

        ${SvgIcon} {
            height: ${iconSize};
            width: ${iconSize};

            flex-shrink: 0;
        }
    `;
};

const GhostButton = styled.button.attrs<GhostButtonProps>(props => {
    const { loading, children, disabled } = props;

    return {
        disabled: disabled || loading,
        children: Children.map(children, wrapStringChildren),
    };
})`
    ${style}
`;

export default GhostButton;
