import Fuse from "fuse.js";
import { useMemo, useState } from "react";

const FUSE_THRESHOLD = 0;

const useSearch = <T>(collection: T[], options?: Fuse.IFuseOptions<T>, initialQuery = "") => {
    const [search, setSearch] = useState(initialQuery);

    const fuse = useMemo(() => {
        return new Fuse<T>(collection, {
            ...options,
            threshold: options?.threshold ? options.threshold : FUSE_THRESHOLD,
        });
    }, [collection, options]);

    const results = useMemo(() => {
        if (search) {
            return fuse.search(search).map(result => result.item);
        } else {
            return collection;
        }
    }, [search, fuse]);

    return {
        search,
        setSearch,
        results,
    };
};

export default useSearch;
