import BaseError from "es6-error";

class NetworkError extends BaseError {
    constructor(error) {
        super("The API is not responding");

        const { config } = error;

        this.config = config;
    }
}

export default NetworkError;
