import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Italic = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path d="M12.5152 7H13.9848L11.4697 17.5H10L12.5152 7Z" fill="black" className={fill} />
        </SvgIcon>
    );
};

export default Italic;
