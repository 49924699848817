import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";
import { MutateConfig, useMutation } from "react-query";
import { v4 as uuid } from "uuid";

import { useApi } from "@sol/sdk/SDKProvider";

import { IClassroomResource } from "../classrooms";
import { IProfessionalSituationResource } from "../professionalSituations";
import { RelationalValue, mapRelationalValue } from "../utils/filters";
import { formatDate } from "../utils/parseDate";
import { IEducationalScenarioItem } from "./IEducationalScenarioItem";

export interface ICreateEducationalScenarioItemInput {
    professionalSituation: RelationalValue<IProfessionalSituationResource>;
    classroom: RelationalValue<IClassroomResource>;
    start: Date | number;
    end: Date | number;
}

export const useCreateEducationalScenarioItemMutation = (
    config: MutateConfig<AxiosResponse<IEducationalScenarioItem>, AxiosError<any>, ICreateEducationalScenarioItemInput>,
) => {
    const api = useApi();
    const mutationFn = useCallback(
        ({ professionalSituation, classroom, start, end }: ICreateEducationalScenarioItemInput) => {
            return api.post<IEducationalScenarioItem>("/educational_scenario_items", {
                uuid: uuid(),
                // API Expect an IRI as uuid
                professionalSituation: `/professional_situations/${mapRelationalValue(professionalSituation)}`,
                // API Expect an IRI as classroom
                classroom: `/classrooms/${mapRelationalValue(classroom)}`,
                startDate: formatDate(start),
                endDate: formatDate(end),
            });
        },
        [api],
    );

    const [mutate, state] = useMutation(mutationFn, config);

    return { mutate, ...state };
};
