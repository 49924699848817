import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IGroupCorrectionList, IGroupSpaceResource, IUserResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";
import { IPaginated } from "@sol/sdk/types/IPaginated";
import { mapRelationalFilter, RelationalValue } from "@sol/sdk/utils/filters";

import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        groupSpace?: RelationalValue<IGroupSpaceResource>;
        learner?: RelationalValue<IUserResource>;
    };
    queryKey?: QueryKey;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { groupSpace, learner } = filters ?? {};

    return {
        "groupSpace.uuid": mapRelationalFilter(groupSpace),
        "learner.uuid": mapRelationalFilter(learner),
    };
};

export const useGroupCorrections = ({
    queryKey,
    pagination,
    filters,
    enabled,
    ...options
}: Params<IGroupCorrectionList>) => {
    const api = useApi();

    const url = useMemo(() => {
        return `/group_corrections${serialize(
            {
                ...(!pagination ? { pagination: false } : pagination),
                ...mapFilters(filters),
            },
            true,
        )}`;
    }, [pagination, filters]);

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IGroupCorrectionList>(url)), [api, url]);

    return useQuery<IGroupCorrectionList>({
        queryKey: queryKey ?? url,
        queryFn,
        config: { ...options, enabled: enabled },
    });
};
