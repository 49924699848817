import { IBriefItem, IClassroom, ITopicList, IUserResource, TopicStatus, WorkStatus } from "@sol/sdk";
import {
    createApiQueryHook,
    IApiQueryFunction,
    IOrderable,
    IPaginateable,
    mapOrder,
    mapPagination,
} from "@sol/sdk/utils/api";
import { mapRelationalFilter, OneOrManyFilter, RelationalFilter } from "@sol/sdk/utils/filters";

import { serialize } from "../../utils/url";

export interface ITopicListQueryParameters
    extends IPaginateable,
        IOrderable<"brief.title" | "lastRenderedWork.learnerDatetime"> {
    filters?: {
        briefs?: RelationalFilter<IBriefItem>;
        classroom?: RelationalFilter<IClassroom>;
        learner?: RelationalFilter<IUserResource>;
        status?: TopicStatus;
        lastRenderedWorkStatus?: OneOrManyFilter<WorkStatus>;
    };
}

export type ITopicListQueryResult = ITopicList;

const mapFilters = (filters: ITopicListQueryParameters["filters"]) => {
    const { briefs, classroom, learner, status, lastRenderedWorkStatus } = filters ?? {};

    return {
        "brief.uuid": mapRelationalFilter(briefs),
        "classroom.uuid": mapRelationalFilter(classroom),
        "learner.uuid": mapRelationalFilter(learner),
        status: status,
        "lastRenderedWork.status": lastRenderedWorkStatus,
    };
};

export const topicListQuery: IApiQueryFunction<ITopicListQueryParameters> = ({ filters, pagination, order }) => {
    return {
        method: "GET",
        url: `/topics${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
};

export const useTopicListQuery = createApiQueryHook<ITopicListQueryParameters, ITopicListQueryResult>(topicListQuery);
