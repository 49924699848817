import { SET_TOKEN } from "./actions";

const initialState = {
    token: null,
    decodedToken: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                token: action.payload.token,
                decodedToken: action.payload.decodedToken,
                refreshToken: action.payload.refreshToken,
            };

        default:
            return state;
    }
};

export default reducer;
