import { EntityTypes } from "../EntityTypes";
import { IHydraResource } from "../types";

export enum TopicStatus {
    VALIDATED = "validated",
    UNVALIDATED = "unvalidated",
}

export interface ITopicResource extends IHydraResource {
    "@type": EntityTypes.TOPIC;
    learnerInClassroom: boolean;
    status: TopicStatus;
}
