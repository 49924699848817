import { AxiosRequestConfig } from "axios";

import { IClassroomUser, IFramework, IFrameworkResource, IUser } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type IFrameworkQueryParameters = {
    framework?: RelationalValue<IFrameworkResource>;
    learner?: RelationalValue<IClassroomUser | IUser>;
};

export type IFrameworkQueryResult = IFramework;

export function frameworkQuery({ framework, learner }: IFrameworkQueryParameters): AxiosRequestConfig | undefined {
    if (!framework) {
        return;
    }

    return {
        method: "GET",
        url: `/frameworks/${mapRelationalValue(framework)}`,
        headers: learner && { "x-learner-uuid": mapRelationalValue(learner) },
    };
}

export const useFrameworkQuery = createApiQueryHook<IFrameworkQueryParameters, IFrameworkQueryResult>(frameworkQuery);
