import { AxiosRequestConfig } from "axios";

import { IClassroom, IClassroomResource } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type IClassroomQueryParameters = {
    classroom?: RelationalValue<IClassroomResource>;
};

export type IClassroomQueryResult = IClassroom;

export function classroomQuery({ classroom }: IClassroomQueryParameters): AxiosRequestConfig | undefined {
    if (!classroom) {
        return;
    }

    return {
        method: "GET",
        url: `/classrooms/${mapRelationalValue(classroom)}`,
    };
}

export const useClassroomQuery = createApiQueryHook<IClassroomQueryParameters, IClassroomQueryResult>(classroomQuery);
