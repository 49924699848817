import { Set } from "immutable";
import { ComponentPropsWithoutRef } from "react";

import { Feature, FeatureGuard, FeaturesProvider } from "@sol/features";

import ActiveClassroomSelect from "../ActiveClassroomSelect";
import { PageLayout } from "./PageLayout";

type Props = ComponentPropsWithoutRef<typeof PageLayout>;

const classroomLayoutFeatures = (parentFeatures: Set<Feature>) =>
    Set([...parentFeatures.toArray(), Feature.HAS_ACTIVE_CLASSROOM_CTX]);

export function ClassroomLayout({ title, children, ...props }: Props) {
    return (
        <FeaturesProvider features={classroomLayoutFeatures}>
            <PageLayout
                title={
                    <>
                        {title}
                        <FeatureGuard feature={Feature.SELECT_ACTIVE_CLASSROOM}>
                            <ActiveClassroomSelect />
                        </FeatureGuard>
                    </>
                }
                {...props}
            >
                {children}
            </PageLayout>
        </FeaturesProvider>
    );
}
