import { OrderedSet } from "immutable";
import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useIsMounted } from "usehooks-ts";
import { v4 as uuid } from "uuid";

type ModalContext = {
    modalNode: HTMLDivElement | null;
    register: () => void;
};

const VisibilityGate = styled.div`
    &[aria-hidden="true"] {
        pointer-events: none;
    }
`;

const ModalContext = React.createContext<ModalContext>({
    modalNode: null,
    register: () => undefined,
});

export const ModalProvider: FC = ({ children }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [modalNode, setModalNode] = useState<HTMLDivElement | null>(null);
    const [modals, setModals] = useState(OrderedSet<string>());
    const isMounted = useIsMounted();

    const register = useCallback(() => {
        const id = uuid();

        if (isMounted()) {
            setModals(modals => modals.add(id));
        }

        return () => {
            if (isMounted()) {
                setModals(modals => modals.remove(id));
            }
        };
    }, []);

    useEffect(() => {
        if (isMounted()) {
            setModalNode(modalRef.current);
        }
    }, []);

    return (
        <>
            <ModalContext.Provider value={{ modalNode, register }}>
                <VisibilityGate aria-hidden={modals.size > 0 ? "true" : undefined}>{children}</VisibilityGate>
            </ModalContext.Provider>

            <div ref={modalRef} />
        </>
    );
};

export default ModalContext;
