import middleware from "./middleware";
import reducer from "./reducer";

const AuthenticationModule = {
    id: "authentication",
    reducerMap: {
        authentication: reducer,
    },
    middlewares: [middleware],
};

export default AuthenticationModule;
