const BlockquoteIcon = () => (
    <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <path
            d="M17.4356 0.0668068H14.8427C13.9408 0.0668068 13.0765 0.367433 12.4001 0.893529C11.4606 1.60752 10.8594 2.73486 10.8594 4.01253C10.8594 5.29019 11.4606 6.41754 12.4001 7.13152C13.0765 7.65762 13.9032 7.95825 14.8427 7.95825H15.7446H16.684V8.10856C16.684 9.79958 15.4439 11.3027 13.7153 11.8288C13.1517 12.0167 12.8134 12.5804 13.0013 13.144C13.1141 13.595 13.565 13.8956 14.016 13.8956C14.1287 13.8956 14.2038 13.8956 14.3166 13.858C17.0222 13.0689 18.826 10.739 18.826 8.10856V7.95825V6.45512V4.01253V1.41963C18.7884 0.668059 18.1871 0.0668068 17.4356 0.0668068Z"
            className="icon-fillcolor"
            fill="white"
        />
        <path
            d="M7.36429 0.0668068H4.77138C3.83193 0.0668068 3.0052 0.367433 2.3288 0.893529C1.38934 1.64509 0.788086 2.77244 0.788086 4.05011C0.788086 5.32777 1.38934 6.45512 2.3288 7.1691C3.0052 7.6952 3.83193 7.99583 4.77138 7.99583H5.67326H6.61272V8.14614C6.61272 9.83716 5.37264 11.3403 3.64404 11.8664C3.08036 12.0543 2.74216 12.618 2.93005 13.1816C3.04278 13.6326 3.49372 13.9332 3.94466 13.9332C4.0574 13.9332 4.13255 13.9332 4.24529 13.8956C6.95092 13.1065 8.75468 10.7766 8.75468 8.14614V7.99583V6.49269V4.05011V1.41963C8.7171 0.668059 8.11585 0.0668068 7.36429 0.0668068Z"
            className="icon-fillcolor"
            fill="white"
        />
    </svg>
);

export default BlockquoteIcon;
