import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const PlusSquared = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <rect x="5" y="5" width="14" height="14" rx="3" stroke="black" strokeWidth="2" className={stroke} />
            <line
                x1="12"
                y1="8"
                x2="12"
                y2="16"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
            <line
                x1="8"
                y1="12"
                x2="16"
                y2="12"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default PlusSquared;
