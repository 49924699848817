import { queryCache } from "react-query";
import { REHYDRATE } from "redux-persist";

import { api } from "../../services/api";
import { unsetUser } from "../user/actions";
import { SET_TOKEN, setToken } from "./actions";

const SEC_TO_MS = 1000;
const REFRESH_THRESHOLD = 2 * 60 * SEC_TO_MS; // 2 minutes in ms
let timeoutHandle = null;

const middleware =
    ({ dispatch, getState }) =>
    next =>
    action => {
        const result = next(action);

        if ([SET_TOKEN, REHYDRATE].includes(action.type)) {
            const { refreshToken, decodedToken, token } = getState().authentication;

            if (timeoutHandle) {
                // The token has changed so we release the refresh timeout handler
                clearTimeout(timeoutHandle);
                timeoutHandle = null;
            }

            if (token) {
                // There is a token so we should check its validity
                const expirationDateTime = decodedToken.exp * SEC_TO_MS;
                const nextRefreshDateTime = expirationDateTime - REFRESH_THRESHOLD;
                const timeUntilNextRefresh = nextRefreshDateTime - Date.now();

                if (timeUntilNextRefresh < 0) {
                    // The token has expired
                    // so we force logout
                    dispatch(setToken(null));
                } else {
                    // The token has NOT expired so we can plan its refresh
                    timeoutHandle = setTimeout(() => {
                        // DEBT async-await-promises
                        api.post("/login/refresh_token", {
                            refresh_token: refreshToken,
                        })
                            .then(response => {
                                dispatch(setToken(response.data));
                            })
                            .catch(() => {
                                // We can't refresh the token for whatever reason
                                // so we force logout
                                dispatch(setToken(null));
                            });
                    }, timeUntilNextRefresh);
                }
            } else if (action.type === SET_TOKEN) {
                // Clear cache on logout (token is null)
                queryCache.clear();
                dispatch(unsetUser());
            }
        }

        return result;
    };

export default middleware;
