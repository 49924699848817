import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IProfessionalSituationList } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

import { IClassroom, IFrameworkResource, ILanguageResource, IUserResource } from "..";
import { IPaginated } from "../types/IPaginated";
import { RelationalFilter, mapRelationalFilter } from "../utils/filters";
import { serialize } from "../utils/url";

export type ResourceOrderBy = "updatedAt" | "createdAt" | "title";
export type ResourceOrder = "asc" | "desc";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        frameworks?: RelationalFilter<IFrameworkResource>;
        languages?: RelationalFilter<ILanguageResource>;
        createdBy?: RelationalFilter<IUserResource>;
        createdByConnectedUser?: boolean;
        title?: string | null;
        compatibleClassroom?: RelationalFilter<IClassroom>;
    };
    queryKey?: QueryKey;
    orderBy?: ResourceOrderBy;
    order?: ResourceOrder;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { frameworks, languages, createdBy, createdByConnectedUser, title, compatibleClassroom } = filters || {};
    return {
        "language.uuid": mapRelationalFilter(languages),
        "skills.frameworks.uuid": mapRelationalFilter(frameworks),
        "createdBy.uuid": mapRelationalFilter(createdBy),
        createdByConnectedUser,
        title,
        "compatibleClassroom.uuid": mapRelationalFilter(compatibleClassroom),
    };
};

export const useProfessionalSituations = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IProfessionalSituationList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/professional_situations${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IProfessionalSituationList>(url)), [api, url]);

    return useQuery<IProfessionalSituationList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
