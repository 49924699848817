import { IApiMutationFunction, createApiMutationHook } from "@sol/sdk/utils/api";
import { RelationalValue, mapRelationalValue } from "@sol/sdk/utils/filters";

import { INotificationResource, NotificationStatus } from "../INotificationResource";

export interface INotificationUpdateMutationParameters {
    notification: RelationalValue<INotificationResource>;
    status: NotificationStatus;
}

export type INotificationUpdateResult = INotificationResource;

export const notificationUpdateMutation: IApiMutationFunction<INotificationUpdateMutationParameters> = ({
    notification,
    status,
}) => {
    return {
        method: "PUT",
        url: `/notifications/${mapRelationalValue(notification)}`,
        data: { status },
    };
};

export const useNotificationUpdateMutation = createApiMutationHook<
    INotificationUpdateMutationParameters,
    INotificationUpdateResult
>(notificationUpdateMutation);
