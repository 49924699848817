import { Set } from "immutable";
import isArray from "lodash/isArray";
import negate from "lodash/negate";
import partial from "lodash/partial";
import { useMemo } from "react";

import Feature from "./Feature";
import { useFeatures } from "./FeaturesContext";

export enum FeatureCheckStrategy {
    /**
     * At least one specified feature is enabled (default)
     */
    ONE,
    /**
     * All the specified features are enabled
     */
    EVERY,
    /**
     * All the specified features are disabled
     */
    NONE,
}

export type FeatureFilter = (featuresSet: Set<Feature>) => Set<Feature>;

const identity = (featuresSet: Set<Feature>) => featuresSet;

/**
 * Check if the provided feature are available in the feature set
 * against a given stategy
 * @param featuresSet The set of enabled features
 * @param features The features to check
 * @param strategy The check strategy
 * @param scope A featuresSet filter usually to restrict by scope
 */
export const checkFeatureFlag = (
    featuresSet: Set<Feature>,
    features: Feature | Feature[],
    {
        strategy = FeatureCheckStrategy.ONE,
        scope = identity,
    }: {
        strategy?: FeatureCheckStrategy;
        scope?: FeatureFilter;
    } = {},
) => {
    const featuresList = isArray(features) ? features : [features];
    const localFeatureSet = scope(featuresSet);
    const hasFeature = localFeatureSet.has.bind(localFeatureSet);

    switch (strategy) {
        case FeatureCheckStrategy.EVERY:
            return featuresList.findIndex(negate(hasFeature)) === -1;
        case FeatureCheckStrategy.NONE:
            return featuresList.findIndex(hasFeature) === -1;
        case FeatureCheckStrategy.ONE:
        default:
            return featuresList.findIndex(hasFeature) > -1;
    }
};

/**
 * Custom hook to check enabled feature against context
 * @returns a function to check enabled features
 */
export const useCheckFeatureFlag = () => {
    const featuresSet = useFeatures();

    // Memo is used here to avoid reference update accross rerenders
    return useMemo(() => partial(checkFeatureFlag, featuresSet), [featuresSet]);
};

export default useCheckFeatureFlag;
