import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { mapRelationalValue, RelationalValue } from "../utils/filters";
import { IEducationalScenarioItemResource } from "./IEducationalScenarioItemResource";

type DeleteEducationalScenarioItemInput = RelationalValue<IEducationalScenarioItemResource>;

export const useDeleteEducationalScenarioItemMutation = (
    config?: MutateConfig<AxiosResponse<void>, any, DeleteEducationalScenarioItemInput>,
) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const query = (eductationalScenarioItem: DeleteEducationalScenarioItemInput) =>
        api.delete(`/educational_scenario_items/${mapRelationalValue(eductationalScenarioItem)}`);

    return useMutation(query, config);
};
