import { PressEvent } from "@react-types/shared";
import React, { useRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import styled from "styled-components";

type Props = {
    className?: string;
    disabled?: boolean;
    onClick?: (e: PressEvent) => void;
} & AriaButtonProps<"button">;

const AriaButton = function ({ className, disabled, onClick, ...props }: Props) {
    const ref = useRef<HTMLButtonElement | null>(null);
    const { buttonProps } = useButton({ isDisabled: disabled, onPress: onClick, ...props }, ref);
    const { children } = props;

    return (
        <button className={className} {...buttonProps} ref={ref}>
            {children}
        </button>
    );
};

export default styled(AriaButton)``;
