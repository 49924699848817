import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

export type SortOrder = "ascending" | "descending";

const ORDERS: {
    [key in SortOrder]: number;
} = {
    ascending: 180,
    descending: 0,
};

type Props = { order?: SortOrder };

const StyledSvgIcon = styled(SvgIcon)<Props>`
    transform: ${({ order }) => order && `rotateZ(${ORDERS[order]}deg)`};
    transition: transform 0.2s ease-in-out;
`;

const Sort = (props: Props & SvgIconProps) => {
    const { stroke } = classes;

    return (
        <StyledSvgIcon {...props}>
            <line
                x1="5"
                y1="9"
                x2="19"
                y2="9"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
            <line
                x1="7"
                y1="13"
                x2="17"
                y2="13"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
            <line
                x1="9"
                y1="17"
                x2="15"
                y2="17"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
        </StyledSvgIcon>
    );
};

export default Sort;
