import { cva } from "class-variance-authority";
import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type SortOrder = "ascending" | "descending";
type Props = { order?: SortOrder; className?: string };

const sortVariants = cva(["transition-transform", "ease-in-out", "transition-200"], {
    variants: {
        variant: {
            ascending: ["rotate-0"],
            descending: ["rotate-180"],
        },
    },
});

export const Sort = ({ order, className, ...props }: Props & SvgIconProps) => {
    return (
        <SvgIcon {...props} className={sortVariants({ variant: order, className })}>
            <line x1="5" y1="9" x2="19" y2="9" strokeWidth="2" strokeLinecap="round" />
            <line x1="7" y1="13" x2="17" y2="13" strokeWidth="2" strokeLinecap="round" />
            <line x1="9" y1="17" x2="15" y2="17" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};
