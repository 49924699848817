import React from "react";

type Props = {
    className?: string;
};

const InfoIcon = ({ className }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            aria-hidden="true"
            focusable="false"
            className={className}
        >
            <circle cx="24" cy="24" r="24" fill="#504DC1" fillOpacity="0.2" />
            <circle cx="24" cy="35" r="3" fill="#504DC1" />
            <circle cx="24" cy="35" r="3" stroke="#504DC1" />
            <rect x="22" y="10" width="4" height="18" rx="2" fill="#504DC1" />
        </svg>
    );
};

export default InfoIcon;
