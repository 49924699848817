import { Dropdown as ADropdown, DropdownProps as ADropdownProps, Button, ConfigProvider, ThemeConfig } from "antd";
import { cloneElement, ReactElement, ReactNode, useState } from "react";
import styled, { css } from "styled-components";

import { Chevron } from "@sol/uikit/core/icons";

type Props = {
    variant?: "inline" | "basic" | "twofold";
    triggerContent?: ReactNode;
    customDropdownRender?: (menu: ReactElement) => ReactElement;
} & ADropdownProps;

// Get the conditional CSS styles based on variant and open state
const getTriggerStyles = (variant: Props["variant"], open: boolean) => {
    switch (variant) {
        case "inline":
            return (
                open &&
                css`
                    color: var(--ant-color-primary-hover);
                `
            );
        case "basic":
            return (
                open &&
                css`
                    color: var(--ant-color-primary-hover);
                    border-color: var(--ant-color-primary-hover);
                `
            );
        default:
            return css``;
    }
};

const DropdownTrigger = styled(Button)<{ variant: Props["variant"]; open: boolean }>`
    display: inline-flex;
    align-items: center;
    border-radius: var(--ant-border-radius-lg);
    gap: var(--ant-margin-xs);

    ${({ variant }) =>
        variant === "inline" &&
        css`
            padding: 0;
        `}

    ${({ variant, open }) => getTriggerStyles(variant, open)};
`;

const DropdownContent = styled.div`
    border-radius: 0.75rem;
    max-height: 500px;
    overflow: auto;
    box-shadow: var(--ant-box-shadow-secondary);

    & .ant-dropdown-menu-item {
        font-size: var(--ant-font-size-sm) !important;
    }
`;

const Dropdown = ({ variant = "basic", triggerContent, customDropdownRender, ...props }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const themeConfig: ThemeConfig = {
        token: {
            borderRadiusLG: 8,
        },
        components: {
            // Button: {
            //     contentFontSize: 14,
            //     paddingInline: 20,
            //     paddingBlock: 0,
            // },
        },
    };

    const handleOpenChange: ADropdownProps["onOpenChange"] = (nextOpen, info) => {
        if (info.source === "trigger" || nextOpen) {
            setIsOpen(nextOpen);
        }
    };

    // Default dropdown render function with custom styling
    const defaultDropdownRender = (menu: ReactElement) => (
        <DropdownContent>{cloneElement(menu as ReactElement)}</DropdownContent>
    );
    const dropdownRender = customDropdownRender || defaultDropdownRender;

    if (variant === "twofold") {
        return (
            <ConfigProvider theme={themeConfig}>
                <ADropdown.Button
                    open={isOpen}
                    onOpenChange={handleOpenChange}
                    trigger={["click"]}
                    dropdownRender={dropdownRender}
                    {...props}
                >
                    {triggerContent}
                </ADropdown.Button>
            </ConfigProvider>
        );
    }

    return (
        <ConfigProvider theme={themeConfig}>
            <ADropdown open={isOpen} onOpenChange={handleOpenChange} trigger={["click"]} {...props}>
                <DropdownTrigger open={isOpen} variant={variant} type={variant === "inline" ? "link" : "default"}>
                    {triggerContent}
                    <Chevron direction={!isOpen ? "down" : "up"} fill="transparent-base" />
                </DropdownTrigger>
            </ADropdown>
        </ConfigProvider>
    );
};

export default styled(Dropdown)`
    &.ant-dropdown-button {
        & .ant-btn-compact-first-item {
            border-top-left-radius: var(--ant-border-radius-lg);
            border-bottom-left-radius: var(--ant-border-radius-lg);
        }

        & .ant-btn-compact-last-item {
            border-top-right-radius: var(--ant-border-radius-lg);
            border-bottom-right-radius: var(--ant-border-radius-lg);

            &.ant-dropdown-open {
                color: var(--ant-color-primary-text-hover);
                border-color: var(--ant-color-primary-hover);
            }
        }
    }
`;
