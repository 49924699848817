const spacing: { [key: number]: string } = {
    1: "2px",
    2: "4px",
    3: "8px",
    4: "12px",
    5: "16px",
    6: "24px",
    7: "32px",
    8: "40px",
    9: "48px",
    10: "64px",
};

export default spacing;
