import styled, { css } from "styled-components";

import { TextProps } from "./types";

export default styled.p<TextProps>`
    margin: 0;
    ${({ theme, variant }) => (variant ? theme.typography[variant] : theme.typography.p)};
    ${({ textAlign }) => (textAlign ? { textAlign } : null)};
    ${({ lines }) =>
        (lines || 0) > 0
            ? css`
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: ${lines}; /* number of lines to show */
                  -webkit-box-orient: vertical;
              `
            : undefined}
    ${({ preserve }) => (preserve ? { whiteSpace: "pre-wrap" } : null)};
    ${({ link }) =>
        link &&
        css`
            --text-color: ${({ theme }) => theme.palette.purple.base};
            border-radius: ${({ theme }) => theme.shape.borderRadius.small};

            box-shadow: 0px 0px 0px 0px transparent;

            transition: box-shadow 0.2s;
            will-change: box-shadow;

            outline: none;
            cursor: pointer;

            &:focus {
                box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.palette.purple.light};
            }

            &:hover:not(:active) {
                text-decoration: underline;
            }
        `}
`;
