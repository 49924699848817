import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { getResourceUuid, IClassroomResource, ITagResource, UserRole } from "..";
import { IPaginated } from "../types/IPaginated";
import { mapRelationalFilter, RelationalFilter } from "../utils/filters";
import { serialize } from "../utils/url";
import { IResourceList } from "./IResourceList";

export type ResourceOrderBy = "updatedAt" | "createdAt" | "title";
export type ResourceOrder = "asc" | "desc";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        classroom?: IClassroomResource;
        term?: string;
        createdBy?: UserRole;
        tags?: RelationalFilter<ITagResource>;
    };
    queryKey?: QueryKey;
    orderBy?: ResourceOrderBy;
    order?: ResourceOrder;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { classroom, term, createdBy, tags } = filters || {};
    return {
        "classroom.uuid": classroom && getResourceUuid(classroom),
        "createdBy.roles": createdBy,
        "tags.uuid": mapRelationalFilter(tags),
        term,
    };
};

export const useResources = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IResourceList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/resources${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IResourceList>(url)), [api, url]);

    return useQuery<IResourceList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
