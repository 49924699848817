// TODO Create and use functional colors (ex: primary, info, success, warning, danger)
export type PaletteColorType = keyof typeof palette;

const palette = {
    purple: {
        base: "#504DC1",
        light: "#8683CD",
        lighter: "#DCDBF3",
        lighter2: "#EDEDF9",
        lightest: "#F6F6FC",
    },
    yellow: {
        base: "#FFD007",
    },
    blue: {
        base: "#0663C0",
        focus: "#1B68E4",
        light: "#E0EFFE",
        lightest: "#F3F9FF",
    },
    green: {
        base: "#0D7A23",
        light: "#4B9B5C",
        lightest: "#E6FFF0",
    },
    red: {
        base: "#CE0033",
        light: "#DB647F",
        lightest: "#FCE6ED",
    },
    grey: {
        base: "#6E6A69",
        light: "#8E8A88",
        lighter: "#B3B3B3",
        lightest: "#F0F0F0",
    },
    black: { base: "#000000" },
    white: { base: "#FFFFFF" },
    transparent: { base: "transparent" },
    orange: { base: "#F46C10" },
};

export default palette;
