import { AnimatePresence, motion } from "framer-motion";

type Props = {
    className?: string;
    isCollapsed?: boolean;
};

export const SideBarLogo = ({ isCollapsed = false, ...props }: Props) => {
    return (
        <AnimatePresence>
            {isCollapsed ? (
                <motion.svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    {...props}
                >
                    <g id="Prise">
                        <g id="Group">
                            <path
                                id="Vector"
                                d="M15.5771 2.68006C17.3402 4.45979 18.2321 6.59546 18.2321 9.06614C18.2321 11.5578 17.3402 13.6725 15.5771 15.4522C13.8141 17.2319 11.6569 18.1323 9.10568 18.1323C6.57517 18.1323 4.41802 17.2319 2.65496 15.4522C0.8919 13.6725 0 11.5368 0 9.06614C0 6.59546 0.8919 4.48073 2.65496 2.68006C4.41802 0.900333 6.57517 0 9.10568 0C11.6569 0 13.8141 0.900333 15.5771 2.68006ZM9.10568 1.94723C7.23891 1.94723 5.62104 2.63818 4.25208 4.02009C2.88312 5.40199 2.19864 7.07703 2.19864 9.06614C2.19864 11.0552 2.88312 12.7512 4.25208 14.1331C5.62104 15.515 7.23891 16.206 9.10568 16.206C10.9724 16.206 12.5903 15.515 13.9593 14.1331C15.3282 12.7512 16.0127 11.0762 16.0127 9.06614C16.0127 7.07703 15.3282 5.40199 13.9593 4.02009C12.5903 2.63818 10.9724 1.94723 9.10568 1.94723Z"
                                fill="#CE0033"
                            />
                        </g>
                        <path
                            id="Vector_2"
                            d="M10.454 5.29736H7.77832V7.99837H10.454V5.29736ZM10.454 10.1131H7.77832V12.8141H10.454V10.1131Z"
                            fill="#CE0033"
                        />
                    </g>
                </motion.svg>
            ) : (
                <motion.svg
                    id="uuid-07a2a964-87d5-48de-b9bb-c63a36d62fe2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 32"
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    {...props}
                >
                    <path
                        d="M124.9,2.7c1.8,1.8,2.7,3.9,2.7,6.4s-.9,4.6-2.7,6.4-3.9,2.7-6.5,2.7-4.7-.9-6.5-2.7-2.7-3.9-2.7-6.4.9-4.6,2.7-6.4C113.7.9,115.8,0,118.4,0c2.5,0,4.7.9,6.5,2.7ZM118.4,1.9c-1.9,0-3.5.7-4.9,2.1s-2.1,3.1-2.1,5c0,2,.7,3.7,2.1,5.1s3,2.1,4.9,2.1,3.5-.7,4.9-2.1c1.4-1.4,2.1-3.1,2.1-5.1s-.7-3.7-2.1-5c-1.4-1.4-3-2.1-4.9-2.1Z"
                        fill="#ce0033"
                        strokeWidth="0"
                    />
                    <path
                        d="M119.7,5.3h-2.7v2.7h2.7v-2.7ZM119.7,10.1h-2.7v2.7h2.7v-2.7Z"
                        fill="#ce0033"
                        strokeWidth="0"
                    />
                    <path
                        d="M57.1,8.6c-1.3-.4-2.3-1-3-1.8-.8-.8-1.1-1.7-1.1-2.8s.4-2,1.2-2.8C54.9.4,55.9,0,57.1,0s2.2.4,3,1.2c.4.4.8,1,1,1.8l-2,.8c-.2-.7-.5-1.1-.7-1.3-.4-.4-.9-.6-1.4-.6s-1,.2-1.3.6c-.4.4-.6.9-.6,1.5s.2,1.1.6,1.6c.4.4,1,.8,1.7,1.1,1.5.5,2.6,1.2,3.6,2.2,1.1,1.1,1.6,2.4,1.6,3.8,0,1.5-.5,2.8-1.6,3.8-1.1,1.1-2.4,1.6-3.9,1.6-1.6,0-2.9-.5-4-1.7-.8-.9-1.3-1.9-1.5-3.3l2.1-.8c.1,1.3.5,2.2,1.1,2.8s1.4,1,2.3,1,1.7-.3,2.4-1,1-1.5,1-2.5-.3-1.8-1-2.5c-.7-.6-1.5-1.1-2.4-1.5Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                    <path d="M66.9.2v17.8h-2.2V.2h2.2Z" fill="#16151e" strokeWidth="0" />
                    <path
                        d="M85.5.2v17.8h-2.2V3.6l-5.8,7.6-5.8-7.6v14.4h-2.2V.2h2.3l5.6,7.5L83,.2s2.5,0,2.5,0Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                    <path d="M101,18V.2h2.2v15.8h6.3v1.9l-8.5.1h0Z" fill="#16151e" strokeWidth="0" />
                    <path
                        d="M131.8.2l10,14.2V.2h2.2v17.8h-2.4l-9.7-13.8v13.8h-2.2V.2h2.1Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                    <path
                        d="M97.3,2.2c-1.1-1.1-2.4-1.7-3.9-1.9h-5.2v17.7h2.2v-5h2.3c1.8,0,3.4-.6,4.6-1.9,1.2-1.3,1.9-2.8,1.9-4.5-.1-1.7-.7-3.2-1.9-4.4ZM95.6,9.7c-.8.8-1.8,1.2-2.9,1.2h-2.4V2.3h2.4c1.1,0,2.1.4,2.9,1.2.8.8,1.3,1.8,1.3,3.1s-.4,2.3-1.3,3.1Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                    <path d="M91.3,25.5h-1v6.4h3.1v-.9h-2.2v-5.5h.1Z" fill="#16151e" strokeWidth="0" />
                    <path d="M95,25.5h-1v6.4h1v-6.4Z" fill="#16151e" strokeWidth="0" />
                    <path
                        d="M100,30.2l-3.3-4.7h-.9v6.4h1v-4.6l3.2,4.6h1v-6.4h-1s0,4.7,0,4.7Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                    <path
                        d="M105.2,26.3v-.9h-3.5v6.4h3.5v-.8h-2.5v-1.9h2.5v-.9h-2.5v-1.9s2.5,0,2.5,0Z"
                        fill="#16151e"
                        strokeWidth="0"
                    />
                </motion.svg>
            )}
        </AnimatePresence>
    );
};
