import omit from "lodash/omit";
import Image from "next/image";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "styled-components";
import { Merge, MergeExclusive } from "type-fest";

import Text from "../../uikit/Text";

type SharedProps = Merge<
    DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLDivElement>,
    {
        size?: "small" | "medium" | "large";
    }
>;

type ImageAvatarProps = Merge<
    SharedProps,
    {
        src: string;
        alt: string;
        unoptimized?: boolean;
    }
>;

type TextAvatarProps = Merge<
    SharedProps,
    {
        children: string;
    }
>;

type Props = MergeExclusive<ImageAvatarProps, TextAvatarProps>;

const isImageAvatarProps = (props: Props): props is ImageAvatarProps => (props as ImageAvatarProps).src !== undefined;

const Avatar = (props: Props) => {
    return (
        <Text
            as="span"
            variant={props.size === "small" ? "p" : "h3"}
            {...(isImageAvatarProps(props)
                ? omit(props, ["src", "alt", "size", "ref", "unoptimized"])
                : omit(props, ["children", "size", "ref"]))}
        >
            {isImageAvatarProps(props) ? (
                <Image
                    src={props.src}
                    alt={props.alt}
                    unoptimized={props.unoptimized}
                    layout="fixed"
                    objectFit="cover"
                    objectPosition="center"
                    width={getSize({ size: props.size })}
                    height={getSize({ size: props.size })}
                />
            ) : (
                props.children.substring(0, 3)
            )}
        </Text>
    );
};

const getSize = ({ size }: SharedProps) => {
    switch (size) {
        case "small":
            return 32;
        case "large":
            return 90;
        case "medium":
        default:
            return 48;
    }
};

export default styled(Avatar)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: ${getSize}px;
    width: ${getSize}px;
    border-radius: 50%;
    overflow: hidden;

    color: var(--text-color, ${({ theme }) => theme.palette.white.base});
    background: var(--bg-color, ${({ theme }) => theme.palette.purple.base});

    // As next/image add intermediate
    // markup with position absolute
    position: relative;

    > * {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
