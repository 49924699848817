import { Middleware } from "redux";
import { REHYDRATE } from "redux-persist";

import analytics from "../../analytics";
import { AnalyticsEvent } from "../../analytics/Analytics";
import GoogleTagManagerPlugin from "../../analytics/plugins/gtm";
import { SET_TOKEN } from "../authentication/actions";
import { getDecodedToken } from "../authentication/selectors";
import { getGDPRState } from "../gdpr/selector";
import { ActionType as GDPRActionType } from "../gdpr/types";

const isServer = typeof window === "undefined";

let gtmActivated = false;

const activateGTM = () => {
    if (!isServer) {
        if (!gtmActivated) {
            try {
                const gtmConf = JSON.parse(process.env.GTM_CONFIG || "");
                const gtmPlugin = new GoogleTagManagerPlugin(gtmConf);

                analytics.addPlugin(gtmPlugin);
                gtmActivated = true;
            } catch {
                // eslint-disable-next-line no-console
                console.warn("[SOL:Analytics]: Google tag manager configuration missing");
            }
        } else {
            analytics.track(AnalyticsEvent.OptIn, { cookieConsent: true });
        }
    }
};

const middleware: Middleware = store => next => action => {
    const oldState = store.getState();
    const result = next(action);
    const state = store.getState();

    const oldGdpr = getGDPRState(oldState);
    const gdpr = getGDPRState(state);

    if ([REHYDRATE].includes(action.type) && !gdpr.initial && gdpr.settings.analytics) {
        activateGTM();
    } else if ([GDPRActionType.Configure].includes(action.type)) {
        const isFirstConfig = gdpr.initial !== oldGdpr.initial && !gdpr.initial;
        const hasConfigChanged = gdpr.settings.analytics !== oldGdpr.settings.analytics;

        if (isFirstConfig || hasConfigChanged) {
            if (gdpr.settings.analytics) {
                // OptIn
                activateGTM();
            } else {
                // OptOut
                analytics.track(AnalyticsEvent.OptOut, { cookieConsent: false });
            }
        }
    }

    if ([REHYDRATE, SET_TOKEN].includes(action.type)) {
        const oldToken = getDecodedToken(oldState);
        const token = getDecodedToken(state);

        if (!token !== !oldToken) {
            // token change triggered
            if (token?.sub) {
                // the user is now authenticated
                const { sub, roles } = token;
                analytics.identify({
                    userId: sub,
                    traits: {
                        role: roles[0],
                    },
                });
            } else {
                // the user is now anonymous
                analytics.identify(undefined);
            }
        }
    }

    return result;
};

export default middleware;
