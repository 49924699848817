import first from "lodash/first";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import omitBy from "lodash/omitBy";
import { ParsedUrlQuery } from "querystring";
import React, { FC, useMemo, useContext, ReactNode, useEffect } from "react";
import { QueryResult } from "react-query";

import { usePersistedState } from "@sol/persistance";
import { Route, useRouter } from "@sol/routing";
import { IClassroomItem, IClassroom, getResourceUuid, useClassroomQuery } from "@sol/sdk";

export type ContextType = Omit<QueryResult<IClassroom, any>, "data" | "error"> & {
    // TODO: rename activeClassroomId to activeClassroomUUID
    // TODO: use UUIDs instead of IRIs
    activeClassroomId?: string;
    activeClassroom?: IClassroom;
    // TODO: deprecate setActiveClassroom by using clasrrooms sub-routes router.push("/classrooms/<uuid>/subroute")
    // TODO: rename parameter id to uuid
    setActiveClassroom: (id?: string) => void;
};

const Context = React.createContext<ContextType | undefined>(undefined);

const { Provider } = Context;

export type ProviderRenderProp = (ctx: ContextType) => ReactNode;

const parseUniqueValue = (query: ParsedUrlQuery): Record<string, string | undefined> => {
    return omitBy(query, isArray);
};

export const ActiveClassroomProvider: FC<{
    classrooms?: IClassroomItem[];
    children: ReactNode | ProviderRenderProp;
}> = ({ classrooms, children }) => {
    const router = useRouter();
    const urlClassroomUUID = parseUniqueValue(router.query).classroomId;
    const [persistedUUID, setPersistedUUID] = usePersistedState<string>("activeClassroom");

    const activeClassroomUUID = useMemo(() => {
        const classroomUUIDs = classrooms?.map(classroom => getResourceUuid(classroom)) ?? [];

        return [urlClassroomUUID, persistedUUID, first(classroomUUIDs)].find(
            uuid => uuid && classroomUUIDs.includes(uuid),
        );
    }, [classrooms, urlClassroomUUID, persistedUUID]);

    useEffect(() => {
        if (persistedUUID !== activeClassroomUUID) {
            setPersistedUUID(activeClassroomUUID);
        }
    }, [activeClassroomUUID]);

    const activeClassroomIRI = activeClassroomUUID ? `/classrooms/${activeClassroomUUID}` : undefined;

    const {
        data: activeClassroom,
        error,
        ...result
    } = useClassroomQuery({ classroom: activeClassroomUUID }, { enabled: !!activeClassroomUUID });

    if (error) {
        throw error;
    }

    const context = useMemo<ContextType>(() => {
        return {
            activeClassroomId: activeClassroomIRI,
            activeClassroom,
            setActiveClassroom: (id: string) => {
                setPersistedUUID(id); // MAJ localstorage
                if (router.route.startsWith(Route.CLASSROOMS)) {
                    // update url
                    router.replace({
                        query: {
                            // Keep same query
                            ...router.query,
                            // Override classroomId,
                            classroomId: getResourceUuid({ "@id": id }),
                        },
                    });
                }
            },
            ...result,
        };
    }, [activeClassroomIRI, activeClassroom, result, setPersistedUUID, router]);

    return <Provider value={context}>{children && isFunction(children) ? children(context) : children}</Provider>;
};

export const useActiveClassroom = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("ActiveClassroomProvider context is missing");
    }

    return context;
};

export default Context;
