import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const H1 = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M10.3251 7H12.6857V17.4863H10.3251V13.0376H6.36056V17.4863H4V7H6.36056V11.0099H10.3251V7Z"
                fill="black"
                className={fill}
            />
            <path
                d="M14.9611 9.17898C15.2537 9.05793 15.5563 8.92174 15.869 8.77042C16.1918 8.60902 16.5045 8.43752 16.8072 8.25594C17.1098 8.06427 17.3973 7.86756 17.6697 7.6658C17.9522 7.45395 18.2044 7.23202 18.4263 7H20V17.4863H17.7454V9.93557C17.4427 10.1373 17.1048 10.324 16.7315 10.4954C16.3583 10.6569 15.9951 10.7981 15.642 10.9191L14.9611 9.17898Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default H1;
