import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const TrashCan = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 7.5H6.55556H19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M8.88889 7.5V6C8.88889 5.60218 9.05278 5.22064 9.3445 4.93934C9.63623 4.65804 10.0319 4.5 10.4444 4.5H13.5556C13.9681 4.5 14.3638 4.65804 14.6555 4.93934C14.9472 5.22064 15.1111 5.60218 15.1111 6V7.5M17.4444 7.5V18C17.4444 18.3978 17.2806 18.7794 16.9888 19.0607C16.6971 19.342 16.3014 19.5 15.8889 19.5H8.11111C7.69855 19.5 7.30289 19.342 7.01117 19.0607C6.71945 18.7794 6.55556 18.3978 6.55556 18V7.5H17.4444Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10.4444 11.25V15.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5556 11.25V15.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};
