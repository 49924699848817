import { formatDistanceStrict } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserAvatar from "src/components/shared/UserAvatar";
import styled from "styled-components";

import { Feature, useCheckFeatureFlag } from "@sol/features";
import { CheckedWork, EmptyWork, Message } from "@sol/icons";
import { getUserDisplayName, INotificationResource, NotificationType } from "@sol/sdk";

import { WorkToCorrect } from "../../icons";
import SOLNotificationItem from "./SOLNotificationItem";

const NotificationTitle = styled.span`
    color: ${({ theme }) => theme.palette.purple.base};
`;

type Props = {
    removeOneNotif: (notif: INotificationResource) => void;
    handleClickNotif: (notif: INotificationResource) => void;
    notif: INotificationResource;
};

export type NotificationDataProps = {
    text: string;
    icon: React.ReactElement<SVGSVGElement>;
    title: React.ReactNode;
    type: NotificationType;
};

const SOLNotificationItemContainer = ({ notif, removeOneNotif, handleClickNotif }: Props) => {
    const {
        senderUser,
        contentUser,
        type,
        status,
        createdAt,
        titleEntity: { title },
    } = notif;
    const checkFeatureFlag = useCheckFeatureFlag();
    const [t] = useTranslation();

    const canSubmitGroupWork = checkFeatureFlag(Feature.WORKSPACE_GROUP_SUBMIT);

    const notifData: NotificationDataProps = useMemo(() => {
        switch (type) {
            case NotificationType.WORK_NOT_CORRECTED:
            case NotificationType.WORK_NOT_CORRECTED_TO_TUTOR:
                return {
                    text: t("navbar.render"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.submitWork")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.GROUP_WORK_TO_TRAINER:
            case NotificationType.GROUP_WORK_TO_LEARNER:
            case NotificationType.GROUP_WORK_TO_TUTOR:
                return {
                    text: t("navbar.render"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.groupSubmitWork")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.MESSAGE_LEARNER_TO_TRAINER:
            case NotificationType.MESSAGE_TRAINER_TO_LEARNER:
            case NotificationType.MESSAGE_TRAINER_TO_TUTOR:
            case NotificationType.MESSAGE_LEARNER_TO_TUTOR:
                return {
                    text: t("navbar.message"),
                    icon: <Message size={16} />,
                    title: (
                        <span>
                            {t("navbar.messageReceive")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.GROUP_MESSAGE_LEARNER_TO_TRAINER:
            case NotificationType.GROUP_MESSAGE_LEARNER_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_TRAINER_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_TRAINER_TO_TUTOR:
            case NotificationType.GROUP_MESSAGE_LEARNER_TO_TUTOR:
                return {
                    text: t("navbar.message"),
                    icon: <Message size={16} />,
                    title: (
                        <span>
                            {t("navbar.groupMessageReceive")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.BRIEF_ASSIGNED:
                return {
                    text: t("navbar.brief"),
                    icon: <CheckedWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.briefAssignedStart")} <NotificationTitle>{title}</NotificationTitle>{" "}
                            {t("navbar.briefAssignedEnd")}
                        </span>
                    ),
                    type,
                };
            case NotificationType.WORK_CORRECTED:
                return {
                    text: t("navbar.correction"),
                    icon: <WorkToCorrect />,
                    title: (
                        <span>
                            {t("navbar.workCorrected")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.GROUP_CORRECTION_TO_LEARNER:
                return {
                    text: t("navbar.correction"),
                    icon: <WorkToCorrect />,
                    title: (
                        <span>
                            {t("navbar.groupWorkCorrected")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.WORK_CORRECTED_TO_TUTOR:
                return {
                    text: t("navbar.correction"),
                    icon: <WorkToCorrect />,
                    title: (
                        <span>
                            {t("navbar.workCorrectedToTutor", {
                                learner: contentUser && getUserDisplayName(contentUser),
                            })}
                            <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.GROUP_CORRECTION_TO_TUTOR:
                return {
                    text: t("navbar.correction"),
                    icon: <WorkToCorrect />,
                    title: (
                        <span>
                            {t("navbar.groupWorkCorrectedToTutor", {
                                learner: contentUser && getUserDisplayName(contentUser),
                            })}
                            <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.GROUP_SPACE_TO_LEARNER:
                return {
                    text: t("navbar.brief"),
                    icon: <CheckedWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.groupSpaceToLearner")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            case NotificationType.SELF_EVALUATION_TO_TRAINER:
            case NotificationType.SELF_EVALUATION_TO_TUTOR:
                return {
                    text: t("navbar.selfEvaluationType"),
                    icon: <CheckedWork size={16} />,
                    title: <span>{t("navbar.selfEvaluation")}</span>,
                    type,
                };
            case NotificationType.FOLLOW_UP_TO_LEARNER:
            case NotificationType.FOLLOW_UP_TO_TRAINER:
            case NotificationType.FOLLOW_UP_TO_TUTOR:
                return {
                    text: t("navbar.followUp"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t(
                                checkFeatureFlag(Feature.FOLLOW_UPS_NOTIFICATIONS_LEARNER)
                                    ? "navbar.toLearnerFollowUp"
                                    : "navbar.followUpFile",
                            )}{" "}
                            <NotificationTitle>{title}</NotificationTitle>
                            {!checkFeatureFlag(Feature.FOLLOW_UPS_NOTIFICATIONS_LEARNER) &&
                                contentUser &&
                                getUserDisplayName(contentUser)}
                        </span>
                    ),
                    type,
                };
            case NotificationType.BRIEF_COEDITOR_ADDED_TO_TRAINER:
                return {
                    text: t("navbar.briefShared"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.briefSharedStart")} <NotificationTitle>{title}</NotificationTitle>
                            {` - ${t("navbar.briefSharedEnd")}`}
                        </span>
                    ),
                    type,
                };
            case NotificationType.BRIEF_COEDITED_TO_CREATOR:
                return {
                    text: t("navbar.briefCoedited"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.briefCoeditedToCreator")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
            default:
                return {
                    text: t("navbar.render"),
                    icon: <EmptyWork size={16} />,
                    title: (
                        <span>
                            {t("navbar.submitWork")} <NotificationTitle>{title}</NotificationTitle>
                        </span>
                    ),
                    type,
                };
        }
    }, [t, title, contentUser, checkFeatureFlag]);

    const nameToDisplay = useMemo(() => {
        if (type === NotificationType.FOLLOW_UP_TO_LEARNER) {
            return getUserDisplayName(senderUser);
        }

        if (
            canSubmitGroupWork &&
            type !== NotificationType.GROUP_WORK_TO_LEARNER &&
            type !== NotificationType.GROUP_MESSAGE_LEARNER_TO_LEARNER
        ) {
            return t("navbar.trainer");
        } else {
            return getUserDisplayName(senderUser);
        }
    }, [senderUser, canSubmitGroupWork]);

    return (
        <SOLNotificationItem
            nameToDisplay={nameToDisplay}
            notifData={notifData}
            status={status}
            deleteNotif={() => removeOneNotif(notif)}
            clickNotif={() => handleClickNotif(notif)}
            date={formatDistanceStrict(new Date(), new Date(createdAt))}
            avatar={
                <div>
                    <UserAvatar user={senderUser} />
                </div>
            }
        />
    );
};

export default SOLNotificationItemContainer;
