import { IPageList } from "@sol/sdk";
import { PageStatus } from "@sol/sdk/pages/IPageResource";
import { createApiQueryHook, IApiQueryFunction, IPaginateable, mapPagination } from "@sol/sdk/utils/api";
import { OneOrManyFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

export interface IPageListQueryParameters extends IPaginateable {
    filters?: {
        status?: OneOrManyFilter<PageStatus>;
    };
}
export type IPageListQueryResults = IPageList;

const mapFilters = (filters: IPageListQueryParameters["filters"]) => {
    const { status } = filters ?? {};
    return {
        status,
    };
};

export const pageListQuery: IApiQueryFunction<IPageListQueryParameters> = ({ filters, pagination }) => ({
    method: "GET",
    url: `/pages${serialize(
        {
            ...mapPagination(pagination),
            ...mapFilters(filters),
        },
        true,
    )}`,
});

export const usePageListQuery = createApiQueryHook<IPageListQueryParameters, IPageListQueryResults>(pageListQuery);
