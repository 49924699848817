import { AxiosRequestConfig } from "axios";

import { IFactoryList } from "@sol/sdk";
import { createApiQueryHook, IOrderable, IPaginateable, mapOrder, mapPagination } from "@sol/sdk/utils/api";
import { serialize } from "@sol/sdk/utils/url";

export interface IFactoryQueryListParameters extends IPaginateable, IOrderable<"title" | "createdAt" | "updatedAt"> {}

export type IFactoryQueryListQueryResult = IFactoryList;

export function factoryQueryList({ pagination, order }: IFactoryQueryListParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/factories${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
            },
            true,
        )}`,
    };
}

export const useFactoryListQuery = createApiQueryHook<IFactoryQueryListParameters, IFactoryQueryListQueryResult>(
    factoryQueryList,
);
