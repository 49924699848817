import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Minus = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <line
                x1="7"
                y1="12"
                x2="17"
                y2="12"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default styled(Minus)``;
