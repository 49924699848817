export const customActiveBorderClasses = [
    "[&_:where(.ant-menu-item-selected)]:before:absolute",
    "[&_:where(.ant-menu-item-selected)]:before:content['']",
    "[&_:where(.ant-menu-item-selected)]:before:absolute",
    "[&_:where(.ant-menu-item-selected)]:before:-left-2.5",
    "[&_:where(.ant-menu-item-selected)]:before:top-1/2",
    "[&_:where(.ant-menu-item-selected)]:before:-translate-y-1/2",
    "[&_:where(.ant-menu-item-selected)]:before:w-1",
    "[&_:where(.ant-menu-item-selected)]:before:h-9",
    "[&_:where(.ant-menu-item-selected)]:before:bg-[color:var(--ant-color-primary)]",
    "[&_:where(.ant-menu-item-selected)]:before:border",
    "[&_:where(.ant-menu-item-selected)]:before:rounded-full",
];

export const menuItemsBaseClasses = [
    // menu-item
    "[&:where(.ant-menu-item)]:!overflow-visible",
    "[&:where(.ant-menu-item)]:!mx-0",
    "[&:where(.ant-menu-item)]:!my-1",
    "[&:where(.ant-menu-item)]:!p-0",
    "[&:where(.ant-menu-item)]:border",
    "[&:where(.ant-menu-item)]:border-[color:var(--ant-color-white)]",
    "[&:where(.ant-menu-item)]:hover:!bg-[color:var(--ant-color-info-bg-hover)]",
    "[&:where(.ant-menu-item)]:hover:!border-[color:var(--ant-color-info-border-hover)]",
    "[&:where(.ant-menu-item)]:hover:!text-[color:var(--ant-color-text-base)]",
    "[&:where(.ant-menu-item)&_>:where(.ant-menu-title-content)]:inline-flex",
    "[&:where(.ant-menu-item-selected)]:border",
    "[&:where(.ant-menu-item-selected)]:border-[color:var(--ant-color-primary-border)]",
    // menu-item-active
    "[&:where(.ant-menu-item.ant-menu-item-active)]:outline-[length:var(--ant-line-width-focus)]",
    "[&:where(.ant-menu-item.ant-menu-item-active)]:outline-[color:var(--ant-color-primary-border)]",
    "[&:where(.ant-menu-item.ant-menu-item-active)]:outline-offset-1",
    "[&:where(.ant-menu-item.ant-menu-item-active)]:outline",
    "[&:where(.ant-menu-item.ant-menu-item-active)]:hover:outline-none",
    "[&:where(.ant-menu-item-only-child)]:!pr-1",
    "[&:where(.ant-menu-item-only-child)]:!py-1",
    "[&:where(.ant-menu-item-only-child)]:!my-1",
    // submenu-item
    "[&:where(.ant-menu-submenu)&_:where(.ant-menu-title-content)]:!ml-1",
    "[&:where(.ant-menu-submenu)&_:where(.ant-menu-item)]:!pl-7",
    // submenu-title
    "[&_>:where(.ant-menu-submenu-title)]:border",
    "[&_>:where(.ant-menu-submenu-title)]:border-[color:var(--ant-color-white)]",
    "[&_>:where(.ant-menu-submenu-title)]:!p-0",
    "[&_>:where(.ant-menu-submenu-title)]:!my-1",
    "[&_>:where(.ant-menu-submenu-title)]:flex",
    "[&_>:where(.ant-menu-submenu-title)]:items-center",
    "[&_>:where(.ant-menu-submenu-title):hover]:!bg-[color:var(--ant-color-info-bg-hover)]",
    "[&_>:where(.ant-menu-submenu-title):hover]:!border-[color:var(--ant-color-info-border-hover)]",
    "[&_>:where(.ant-menu-submenu-title):hover]:!text-[color:var(--ant-color-text-base)]",
    // submenu-arrow
    "[&_:where(.ant-menu-submenu-arrow)]:!relative",
    "[&_:where(.ant-menu-submenu-arrow)]:!top-0",
    "[&_:where(.ant-menu-submenu-arrow)]:!right-0",
    "[&_:where(.ant-menu-submenu-arrow)]:!text-black-base",
    // submenu-open
    "[&:where(.ant-menu-submenu-open)]:relative",
    "[&:where(.ant-menu-submenu-open)]:before:absolute",
    "[&:where(.ant-menu-submenu-open)]:before:content['']",
    "[&:where(.ant-menu-submenu-open)]:before:absolute",
    "[&:where(.ant-menu-submenu-open)]:before:-left-2.5",
    "[&:where(.ant-menu-submenu-open)]:before:top-0",
    "[&:where(.ant-menu-submenu-open)]:before:w-1",
    "[&:where(.ant-menu-submenu-open)]:before:h-9",
    "[&:where(.ant-menu-submenu-open)]:before:bg-[color:var(--ant-color-primary)]",
    "[&:where(.ant-menu-submenu-open)]:before:border-[color:var(--ant-color-primary)]",
    "[&:where(.ant-menu-submenu-open)]:before:border",
    "[&:where(.ant-menu-submenu-open)]:before:rounded-full",
    "[&:where(.ant-menu-submenu-open)&_:where(.ant-menu-submenu-title)]:!text-[color:var(--ant-color-primary)]",
];

export const quickActionItemBaseClasses = [
    "inline-flex",
    "h-min",
    "w-full",
    "items-center",
    "text-ellipsis",
    "text-nowrap",
    "rounded-lg",
    "border",
    "border-transparent-base",
    "!p-1",
    "!transition-all",
    "!duration-300",
    "!ease-in-out",
    "hover:!bg-[color:var(--ant-color-primary-bg-hover)]",
    "hover:!border-[color:var(--ant-color-primary-border-hover)]",
];

export const dropdownItemBaseClasses = [
    "!p-1.5",
    "border-transparent-base",
    "border",
    "!transition-all",
    "!duration-300",
    "!ease-in-out",
    "hover:!bg-[color:var(--ant-color-primary-bg-hover)]",
    "hover:!border-[color:var(--ant-color-primary-border-hover)]",
    "hover:outline-none",
    "[&:where(.ant-dropdown-menu-item)]:outline-none",
    "[&:where(.ant-dropdown-menu-item.ant-dropdown-menu-item-active)]:outline-[length:var(--ant-line-width-focus)]",
    "[&:where(.ant-dropdown-menu-item.ant-dropdown-menu-item-active)]:outline-[color:var(--ant-color-primary-border)]",
    "[&:where(.ant-dropdown-menu-item.ant-dropdown-menu-item-active)]:outline-offset-1",
    "[&:where(.ant-dropdown-menu-item.ant-dropdown-menu-item-active)]:outline",
    "[&:where(.ant-dropdown-menu-item.ant-dropdown-menu-item-active)]:!bg-inherit",
];
