import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Discord = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M14 5.34463C14 4.79096 14.0873 4.31638 14.4367 4C16.4454 4.15819 18.1047 4.79096 19.4148 5.81921C21.5108 9.06215 22.2095 12.3051 21.9475 15.4689C21.1615 16.8927 19.2401 17.6836 16.358 18H15.048V16.4181"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M15.7681 12.5483C16.5965 12.5483 17.2681 11.8768 17.2681 11.0483C17.2681 10.2199 16.5965 9.54834 15.7681 9.54834C14.9396 9.54834 14.2681 10.2199 14.2681 11.0483C14.2681 11.8768 14.9396 12.5483 15.7681 12.5483Z"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                className={stroke}
            />
            <path
                d="M8.396 12.5483C9.22442 12.5483 9.896 11.8768 9.896 11.0483C9.896 10.2199 9.22442 9.54834 8.396 9.54834C7.56757 9.54834 6.896 10.2199 6.896 11.0483C6.896 11.8768 7.56757 12.5483 8.396 12.5483Z"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                className={stroke}
            />
            <path
                d="M7 7C8.94444 5.66667 14.9861 5.66667 17 7"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                className={stroke}
            />
            <path
                d="M10 5.34463C10 4.79096 9.91267 4.31638 9.56333 4C7.55463 4.15819 5.89526 4.79096 4.58524 5.81921C2.48921 9.06215 1.79053 12.3051 2.05253 15.4689C2.83855 16.8927 4.75991 17.6836 7.64196 18H9L8.95198 16.4181"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M6 15C8.01242 16.3333 15.9876 16.3333 18 15"
                stroke="black"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Discord;
