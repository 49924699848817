import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "src/store/i18n";

import { setLocale as setLocaleAction } from "../store/i18n/actions";
import { getLocale } from "../store/i18n/selectors";

const useLocale = (): [
    string,
    (locale: string) => {
        type: ActionTypes;
        locale: string;
    },
] => {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();

    const setLocale = useCallback((locale: string) => dispatch(setLocaleAction(locale)), [dispatch]);

    return [locale, setLocale];
};

export default useLocale;
