import { EntityTypes, IHydraResource, IUserAsCommentResourceAuthor } from "@sol/sdk";

export interface ICommentResource extends IHydraResource {
    "@type": EntityTypes.COMMENT;
    content: string;
    followUp: string;
    selfEvaluation: string;
    createdBy: IUserAsCommentResourceAuthor;
    createdAt: string;
    updatedAt: string;
}

export function isCommentResource(resource: IHydraResource): resource is ICommentResource {
    return resource["@type"] === EntityTypes.COMMENT;
}
