// DEBT refactor-typescript
// DEBT move-to-module : Move api services to @sol/sdk ?
import axios from "axios";

/**
 * Function used to apply delay on its function
 * @param {function} fn Function with delayed execution
 * @param {number} t Delay in ms (by default it is set on 1 sec)
 * @returns {function} that returns a Promise object
 */
const delay =
    (fn, t = 1000) =>
    (...args) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                fn(...args)
                    .then(resolve)
                    .catch(reject);
            }, t);
        });
    };

const api = axios.create({
    baseURL: process.env.API_URL,
});

export { api, delay };

export default api;
