import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const OrderedList = (props: SvgIconProps) => {
    const { fill, stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M8 7H20"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M8 12H20"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M8 17H20"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M3.26884 5.75756C3.38697 5.71548 3.50917 5.66813 3.63544 5.61552C3.76578 5.5594 3.89206 5.49978 4.01426 5.43665C4.13646 5.37001 4.25255 5.30162 4.36253 5.23148C4.47658 5.15783 4.57841 5.08067 4.66802 5H5.30346V8.64577H4.39308V6.02061C4.27088 6.09075 4.13442 6.15563 3.98371 6.21526C3.83299 6.27137 3.68635 6.32047 3.54379 6.36256L3.26884 5.75756Z"
                fill="black"
                className={fill}
            />
            <path
                d="M5.87169 11.0951C5.87169 11.2284 5.84114 11.3564 5.78004 11.4792C5.71894 11.6019 5.63951 11.7212 5.54175 11.8369C5.44399 11.9491 5.33401 12.0579 5.21181 12.1631C5.08961 12.2683 4.96945 12.3683 4.85132 12.463C4.79022 12.5121 4.72301 12.5682 4.64969 12.6313C4.58045 12.6909 4.51324 12.7523 4.44807 12.8154C4.38289 12.8786 4.32383 12.9382 4.27088 12.9943C4.222 13.0469 4.19145 13.0907 4.17923 13.1258H6V13.7834H3.17719C3.16904 13.7448 3.16497 13.6957 3.16497 13.6361C3.16497 13.5765 3.16497 13.5344 3.16497 13.5099C3.16497 13.3415 3.19552 13.1872 3.25662 13.0469C3.32179 12.9066 3.4053 12.7769 3.50713 12.6576C3.60896 12.5349 3.72301 12.4209 3.84929 12.3157C3.97963 12.2104 4.10794 12.1052 4.23422 12C4.33198 11.9193 4.42363 11.8439 4.50917 11.7738C4.5947 11.7001 4.67006 11.63 4.73523 11.5634C4.80041 11.4932 4.85132 11.4248 4.88798 11.3582C4.92464 11.288 4.94297 11.2179 4.94297 11.1477C4.94297 10.9934 4.89206 10.8847 4.79022 10.8216C4.68839 10.7584 4.56212 10.7269 4.41141 10.7269C4.30143 10.7269 4.19756 10.7427 4.0998 10.7742C4.00611 10.8023 3.91853 10.8374 3.83707 10.8794C3.75967 10.918 3.69246 10.9584 3.63544 11.0004C3.57841 11.039 3.53564 11.0706 3.50713 11.0951L3.05499 10.548C3.23422 10.4042 3.44196 10.2867 3.67821 10.1955C3.91853 10.1008 4.17515 10.0535 4.44807 10.0535C4.69654 10.0535 4.91039 10.078 5.08961 10.1271C5.26884 10.1762 5.41548 10.2464 5.52953 10.3376C5.64766 10.4253 5.7332 10.534 5.78615 10.6637C5.84318 10.79 5.87169 10.9338 5.87169 11.0951Z"
                fill="black"
                className={fill}
            />
            <path
                d="M4.23422 19C4.12424 19 4.00815 18.993 3.88595 18.979C3.76375 18.9684 3.64562 18.9527 3.53157 18.9316C3.41752 18.9106 3.31365 18.8878 3.21996 18.8632C3.12627 18.8387 3.05295 18.8159 3 18.7948L3.17719 18.1425C3.2831 18.1811 3.41752 18.2231 3.58045 18.2687C3.74745 18.3108 3.95316 18.3319 4.19756 18.3319C4.47862 18.3319 4.68432 18.2863 4.81466 18.1951C4.94501 18.1039 5.01018 17.9811 5.01018 17.8268C5.01018 17.7321 4.98574 17.6532 4.93686 17.5901C4.89206 17.5235 4.82892 17.4708 4.74745 17.4323C4.66599 17.3902 4.56823 17.3621 4.45418 17.3481C4.3442 17.3306 4.22607 17.3218 4.0998 17.3218H3.74542V16.6905H4.14868C4.23829 16.6905 4.32383 16.6835 4.4053 16.6694C4.49084 16.6554 4.56619 16.6326 4.63136 16.6011C4.69654 16.566 4.74745 16.5204 4.78411 16.4643C4.82485 16.4046 4.84521 16.331 4.84521 16.2433C4.84521 16.1767 4.82892 16.1188 4.79633 16.0697C4.76375 16.0206 4.72098 15.9803 4.66802 15.9487C4.61914 15.9171 4.56008 15.8943 4.49084 15.8803C4.42566 15.8628 4.35845 15.854 4.28921 15.854C4.11405 15.854 3.95112 15.8768 3.80041 15.9224C3.65377 15.968 3.51935 16.0241 3.39715 16.0908L3.07332 15.5173C3.13849 15.4822 3.21385 15.4454 3.29939 15.4068C3.389 15.3683 3.48676 15.3332 3.59267 15.3016C3.69857 15.2701 3.81059 15.2438 3.92872 15.2227C4.05092 15.2017 4.17923 15.1911 4.31365 15.1911C4.56212 15.1911 4.77597 15.2175 4.95519 15.2701C5.13849 15.3192 5.28921 15.3911 5.40733 15.4858C5.52546 15.5769 5.61303 15.6857 5.67006 15.8119C5.72709 15.9347 5.7556 16.0697 5.7556 16.217C5.7556 16.3608 5.70876 16.5011 5.61507 16.6379C5.52138 16.7712 5.39511 16.8729 5.23625 16.943C5.45621 17.0202 5.62525 17.1359 5.74338 17.2902C5.86558 17.441 5.92668 17.6234 5.92668 17.8374C5.92668 18.0057 5.89409 18.1618 5.82892 18.3056C5.76375 18.4459 5.66191 18.5686 5.52342 18.6738C5.38493 18.7755 5.20774 18.8562 4.99185 18.9158C4.78004 18.9719 4.5275 19 4.23422 19Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default styled(OrderedList)``;
