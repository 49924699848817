import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IWorkItem, IWorkResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

type Params<TResult = unknown, TError = unknown> = {
    work?: RelationalValue<IWorkResource>;
    queryKey?: QueryKey;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useWork = ({ work, queryKey, enabled, ...options }: Params<IWorkItem>) => {
    const api = useApi();

    const url = useMemo(() => (work ? `/works/${mapRelationalValue(work)}` : ""), [work]);

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IWorkItem>(url)), [api, url]);

    return useQuery<IWorkItem>({
        queryKey: queryKey || url,
        queryFn,
        config: { ...options, enabled: enabled ?? !!url },
    });
};
