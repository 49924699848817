import styled from "styled-components";

import BaseSkeleton from "./BaseSkeleton";

type Props = {
    round?: boolean;
    height?: number;
    width?: number;
};

const IconSkeleton = styled(BaseSkeleton)<Props>`
    height: ${({ height }) => (height ? `${height}px` : "1em")};
    width: ${({ width }) => (width ? `${width}px` : "1em")};
    border-radius: ${props => (props.round ? "50%" : 0)};

    display: inline-block;
`;

export default IconSkeleton;
