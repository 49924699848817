import { AutoComplete as AntAutoComplete, AutoCompleteProps as AntAutoCompleteProps } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FC } from "react";
import styled from "styled-components";

export type AutoCompleteProps = AntAutoCompleteProps;

const StyledAntAutoComplete = styled(AntAutoComplete)<{ size?: SizeType }>`
    min-width: 360px;

    & .ant-select-selector {
        height: 100%;
    }

    & .ant-input-affix-wrapper-sm {
        height: var(--ant-control-height-sm);
    }

    & .ant-input-search-button:not(.ant-btn-primary) {
        color: var(--ant-color-primary) !important;
    }

    & .ant-input-group-addon {
        width: ${({ size }) =>
            size === "small"
                ? "var(--ant-control-height-sm)"
                : size === "large"
                ? "var(--ant-select-single-item-height-lg)"
                : "var(--ant-control-height)"};
        padding: 0;
        background-color: transparent;
    }

    & .ant-btn {
        padding-inline: 3px;
    }

    & .ant-btn-sm {
        padding-inline: 0;
    }

    & .ant-btn-lg {
        padding-inline: 11px;
    }
`;

export const AutoComplete: FC<AutoCompleteProps> = props => {
    return <StyledAntAutoComplete {...props} />;
};
