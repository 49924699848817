import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const EmptyWork = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.81713 5C6.97198 5 6.21805 5.53125 5.93372 6.32713L4.0583 11.5766C4.01421 11.7 3.99541 11.829 4.00094 11.9564C4.00032 11.9708 4.00001 11.9854 4.00001 12V17C4.00001 18.6569 5.34315 20 7.00001 20H17C18.6569 20 20 18.6569 20 17V12C20 11.9854 19.9997 11.9708 19.9991 11.9564C20.0046 11.829 19.9858 11.7 19.9417 11.5766L18.0663 6.32713C17.782 5.53125 17.028 5 16.1829 5H7.81713ZM18 12.913L16.7023 12.913L15.8168 14.8364C15.4903 15.5456 14.7809 16 14.0001 16H10.0155C9.23842 16 8.53167 15.5499 8.20309 14.8456L7.30137 12.913H6.00001V17C6.00001 17.5523 6.44772 18 7.00001 18H17C17.5523 18 18 17.5523 18 17V12.913ZM7.81713 7H16.1829L17.5808 10.913H16.7023C15.9214 10.913 15.212 11.3674 14.8855 12.0767L14.0001 14H10.0155L9.11379 12.0674C8.78521 11.3632 8.07846 10.913 7.30137 10.913H6.41917L7.81713 7Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
