import { IModule } from "redux-dynamic-modules";

import { IRootState } from "../index";
import { initialState, reducer } from "./reducer";

const UserModule: IModule<Partial<IRootState>> = {
    id: "user",
    reducerMap: {
        user: reducer,
    },
};

export * from "./model";
export { initialState };
export default UserModule;
