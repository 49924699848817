import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

export const useDeleteProfessionalSituation = (config: MutateConfig<AxiosResponse<void>, any, string>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const query = (professionalSituationId: string) =>
        api.delete(`/professional_situations/${professionalSituationId}`);

    const [deleteProfessionalSituation, { isSuccess, error, reset, isLoading }] = useMutation(query, config);

    return { deleteProfessionalSituation, isSuccess, error, reset, isLoading };
};
