import { ITagResource, IUserResource } from "..";
import { EntityTypes } from "../EntityTypes";
import { IMediaResource } from "../media/IMediaResource";
import { IHydraResource, ITimestampable } from "../types";

export interface IResourceResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.RESOURCE;
    url: string;
    image?: Pick<IMediaResource, "@id" | "@type" | "url">;
    title: string;
    description: string;
    classroomId: string;
    tags: ITagResource[];
    createdBy: IUserResource;
}

export function isResourceResource(resource: IHydraResource): resource is IResourceResource {
    return resource["@type"] === EntityTypes.RESOURCE;
}
