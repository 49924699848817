import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Bold = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M11.4162 17.5C10.8542 17.5 10.2923 17.4803 9.73028 17.4408C9.17817 17.4113 8.60141 17.3324 8 17.2042V7.2662C8.47324 7.17746 8.99084 7.11338 9.55282 7.07394C10.1148 7.02465 10.6373 7 11.1204 7C11.7711 7 12.3676 7.0493 12.9099 7.14789C13.462 7.23662 13.9352 7.39437 14.3296 7.62113C14.7239 7.84789 15.0296 8.14859 15.2465 8.52324C15.4732 8.88803 15.5866 9.34155 15.5866 9.8838C15.5866 10.7021 15.1923 11.3479 14.4035 11.8211C15.0542 12.0676 15.4979 12.4028 15.7345 12.8268C15.9711 13.2507 16.0894 13.7289 16.0894 14.2613C16.0894 15.3359 15.6951 16.1444 14.9063 16.6866C14.1275 17.2289 12.9641 17.5 11.4162 17.5ZM10.2479 12.9007V15.5479C10.4155 15.5676 10.5979 15.5824 10.7951 15.5923C10.9923 15.6021 11.2092 15.607 11.4458 15.607C12.1359 15.607 12.693 15.5085 13.1169 15.3113C13.5408 15.1141 13.7528 14.7493 13.7528 14.2169C13.7528 13.7437 13.5754 13.4085 13.2204 13.2113C12.8655 13.0042 12.3577 12.9007 11.6972 12.9007H10.2479ZM10.2479 11.1408H11.3718C12.0817 11.1408 12.5894 11.0521 12.8951 10.8746C13.2007 10.6873 13.3535 10.3915 13.3535 9.98732C13.3535 9.57324 13.1958 9.28239 12.8803 9.11479C12.5648 8.94718 12.1014 8.86338 11.4901 8.86338C11.293 8.86338 11.081 8.86831 10.8542 8.87817C10.6275 8.87817 10.4254 8.88803 10.2479 8.90775V11.1408Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default Bold;
