import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Menu = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <circle r="2" transform="matrix(-1 0 0 1 12 6)" />
            <circle r="2" transform="matrix(-1 0 0 1 12 12)" />
            <circle r="2" transform="matrix(-1 0 0 1 12 18)" />
        </SvgIcon>
    );
};
