import React, { HTMLAttributes, DetailedHTMLProps } from "react";
import Notification from "src/uikit/Notification";
import styled from "styled-components";

import { NotificationType, useNotifications } from "./NotificationsContext";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type NotificationArray = [string, NotificationType][];

const Notifications = ({ ...rest }: Props) => {
    const { notifications, dismiss } = useNotifications();

    return (
        <div {...rest}>
            {/** Typings from Immutable are wrong for toArray */}
            {(notifications.reverse().toArray() as unknown as NotificationArray).map(notification => (
                <Notification
                    data-cy="toast"
                    key={notification[0]}
                    level={notification[1].level}
                    message={notification[1].message}
                    onClose={() => dismiss(notification[0])}
                />
            ))}
        </div>
    );
};

export default styled(Notifications)`
    position: fixed;
    top: 74px;
    right: 30px;
    z-index: ${({ theme }) => theme.zIndex.navigation};

    div + div {
        margin-top: 16px;
    }
`;
