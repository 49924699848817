import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Plus = ({ className, ...props }: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0001 3.79999C12.5523 3.79999 13.0001 4.2477 13.0001 4.79999L13 11H19.2C19.7523 11 20.2001 11.4477 20.2001 12C20.2001 12.5523 19.7523 13 19.2 13H13L13 19.2C13 19.7523 12.5523 20.2 12 20.2C11.4478 20.2 11 19.7523 11 19.2L11 13H4.80005C4.24776 13 3.80005 12.5523 3.80005 12C3.80005 11.4477 4.24776 11 4.80005 11H11L11.0001 4.79999C11.0001 4.2477 11.4478 3.79999 12.0001 3.79999Z"
            />
        </SvgIcon>
    );
};
