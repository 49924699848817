import { interpolate, Route } from "@sol/routing";
import { EntityTypes, getResourceUuid, INotificationResource, NotificationType } from "@sol/sdk";

import { FollowUpKind } from "../../follow-ups/shared/FollowUpKind";

export const getRedirectUrlNotification = (
    notification: INotificationResource,
    classroomUUID: string,
): { href: string; as?: string } => {
    const { linkEntity } = notification;
    const linkEntityUuid = getResourceUuid(linkEntity);

    if (notification.type.startsWith("group_")) {
        switch (notification.type) {
            case NotificationType.GROUP_CORRECTION_TO_LEARNER:
            case NotificationType.GROUP_WORK_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_LEARNER_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_TRAINER_TO_LEARNER:
                return {
                    href: interpolate(Route.CLASSROOM_WORKSPACES, {
                        classroomId: classroomUUID,
                        tab: "group",
                        groupSpace: linkEntityUuid,
                    }),
                };
            default:
                // Prevent error sentry https://sentry.simplon.space/simplonprod/simplonline-v3-ui/issues/7177/?query=is%3Aunresolved%20firstSeen%3A-8w%20Cannot%20read%20property
                // Waiting for more information on this
                if (notification?.linkEntity?.["@id"]) {
                    return {
                        href: interpolate(Route.CLASSROOM_WORKSPACES, {
                            classroomId: classroomUUID,
                            tab: "group",
                            groupSpace: linkEntityUuid,
                        }),
                    };
                } else {
                    return {
                        href: Route.HOME,
                    };
                }
        }
    }

    if (
        notification.type === NotificationType.SELF_EVALUATION_TO_TRAINER ||
        notification.type === NotificationType.SELF_EVALUATION_TO_TUTOR
    ) {
        return {
            href: interpolate(Route.FOLLOW_UPS_DETAILS, { id: linkEntityUuid }),
        };
    }

    if (
        notification.type === NotificationType.FOLLOW_UP_TO_TRAINER ||
        notification.type === NotificationType.FOLLOW_UP_TO_TUTOR ||
        notification.type === NotificationType.FOLLOW_UP_TO_LEARNER
    ) {
        return {
            href: interpolate(Route.FOLLOW_UPS_DETAILS, {
                followUpId: linkEntityUuid,
                kind:
                    linkEntity["@type"] === EntityTypes.SELF_EVALUATION
                        ? FollowUpKind.SELF_EVALUATION
                        : FollowUpKind.FOLLOW_UP,
            }),
        };
    }

    if (
        notification.type === NotificationType.BRIEF_COEDITOR_ADDED_TO_TRAINER ||
        notification.type === NotificationType.BRIEF_COEDITED_TO_CREATOR
    ) {
        return {
            href: interpolate(Route.BRIEFS_DETAILS, { id: linkEntityUuid }),
        };
    }

    return {
        // Prevent error sentry https://sentry.simplon.space/simplonprod/simplonline-v3-ui/issues/7152/?query=is%3Aunresolved%20firstSeen%3A-8w%20Cannot%20read%20property
        // Waiting for more information on this
        // TODO: wait for notification revamp to get classroomId
        href: notification.linkEntity["@id"]
            ? interpolate(Route.CLASSROOM_WORKSPACES, { classroomId: classroomUUID, topic: linkEntityUuid })
            : Route.HOME,
    };
};
