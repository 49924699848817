/* eslint-disable camelcase */

import jwt from "jsonwebtoken";

import { IUserAuthenticationTokens } from "@sol/sdk";

export const SET_TOKEN = "SET_TOKEN";

/**
 * Set the token received from API to the redux store
 * Returns an action with a required type property and a payload property
 */
export const setToken = (payload?: IUserAuthenticationTokens | null) => {
    return {
        type: SET_TOKEN,
        payload: {
            token: payload?.token,
            refreshToken: payload?.refresh_token,
            decodedToken: payload?.token && jwt.decode(payload.token),
        },
    };
};
