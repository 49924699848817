import { stringify } from "qs";

import { AnalyticsPlugin, AnalyticsEvent } from "../Analytics";

function scriptLoaded(src: any) {
    const scripts = document.querySelectorAll("script[src]");
    return Object.keys(scripts).filter(key => (scripts as any)[key].src === src).length > 0;
}

class GoogleTagManagerPlugin extends AnalyticsPlugin {
    id: string;
    dataLayer: any;

    constructor(config: any) {
        super();

        this.id = config.id;
        this.dataLayer = [];

        const scriptSrc = `https://www.googletagmanager.com/gtm.js?${stringify(config)}`;

        if (!scriptLoaded(scriptSrc)) {
            const dataLayerName = config.l || "dataLayer";
            window[dataLayerName] = window[dataLayerName] || [];
            this.dataLayer = window[dataLayerName];
            this.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            this.dataLayer.push({ event: AnalyticsEvent.OptIn, cookieConsent: true });

            const script = document.createElement("script");
            script.async = true;
            script.src = scriptSrc;

            const body = document.getElementsByTagName("body")[0];
            if (body.firstChild) {
                body.insertBefore(script, body.firstChild);
            } else {
                body.appendChild(script);
            }
        }
    }

    gtm() {
        if (typeof window !== "undefined") {
            const gtm = (window as any)["google_tag_manager"];
            return gtm && gtm[this.id];
        }

        return null;
    }

    track(event: any, data: any) {
        this.dataLayer.push({ event, ...data });
    }

    reset() {
        const dataLayer = this.gtm()?.dataLayer;
        if (dataLayer) {
            dataLayer.reset();
        }
    }
}

export default GoogleTagManagerPlugin;
