import { cloneDeep, omit } from "lodash";
import { interpolateAs } from "next/dist/shared/lib/router/router";
import { stringify } from "qs";
import { ParsedUrlQuery } from "querystring";

import { Route } from "./Route";

/**
 * Interpolate the route as an URI with the given parameters injected
 * @param route the route template to interpolate
 * @param params the parameters to inject
 * @description each values from params will be injected for a given key in the route
 */
export const interpolate = (route: Route, params: ParsedUrlQuery): string => {
    const interpolation = interpolateAs(route, route, params);

    // remove injected keys
    const queryString = stringify(omit(cloneDeep(params), ...interpolation.params), { arrayFormat: "brackets" });

    return `${interpolation.result}${queryString ? `?${queryString}` : ""}`;
};

export default interpolate;
