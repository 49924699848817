import { AxiosResponse } from "axios";
import { MutateConfig, useMutation } from "react-query";

import { IMissionResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

interface PostMissionVariables
    extends Pick<
        IMissionResource,
        "title" | "periodStart" | "periodEnd" | "waypoint" | "context" | "description" | "learners"
    > {
    uuid: string;
    skills: string[];
}

export const useCreateMission = (config: MutateConfig<AxiosResponse<IMissionResource>, any, PostMissionVariables>) => {
    const api = useApi();

    const postMission = (variables: PostMissionVariables) =>
        api.post("/missions", {
            ...variables,
        });

    const [createMission, { isSuccess, error, reset, isLoading }] = useMutation(postMission, config);

    return { createMission, isSuccess, error, reset, isLoading };
};
