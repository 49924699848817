import { Button, ConfigProvider, Tag, theme, type ThemeConfig } from "antd";
import { cx } from "class-variance-authority";
import React from "react";
import styled, { css } from "styled-components";

import { NotificationStatus } from "@sol/sdk";
import { IconButton } from "@sol/uikit/buttons";
import { Cross } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";

import { NotificationDataProps } from "./SOLNotificationItemContainer";

const TagTime = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing[4]};
    --text-color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = {
    nameToDisplay: string;
    notifData: NotificationDataProps;
    date: string;
    deleteNotif: (e: React.MouseEvent<HTMLButtonElement>) => void;
    clickNotif: () => void;
    avatar: React.ReactNode;
    status: NotificationStatus;
    className?: string;
};

// DEBT rework markup
const SOLNotificationItem = ({ deleteNotif, clickNotif, notifData, nameToDisplay, date, avatar, className }: Props) => {
    const { useToken } = theme;
    const { token } = useToken();

    const themeConfig: ThemeConfig = {
        cssVar: { key: "notificationItem" },
        components: {
            Tag: {
                defaultBg: token.colorWhite,
            },
        },
    };
    return (
        <ConfigProvider theme={themeConfig}>
            <div className={cx([className, "pr-12 pl-6 py-4 hover:bg-purple-lightest"])} data-cy="notification-item">
                <Button
                    type="link"
                    onClick={deleteNotif}
                    className="absolute right-2 top-2 p-0 hover:text-[color:--ant-color-primary]"
                >
                    <Cross />
                </Button>
                <div className="flex items-start justify-start gap-4 text-left">
                    {avatar}
                    <div className="flex flex-col">
                        <button type="button" onClick={clickNotif}>
                            <Typography className="text-left">
                                {nameToDisplay} {notifData.title}
                            </Typography>
                        </button>
                        <TagTime>
                            <Tag className="flex items-center justify-center gap-3 rounded-[40px] px-3">
                                <Typography variant="subHeading" className="flex">
                                    1 {notifData.text}
                                </Typography>{" "}
                                <Typography variant="subHeading">{notifData.icon}</Typography>
                            </Tag>
                            <Typography tag="span" variant="subHeading">
                                {date}
                            </Typography>
                        </TagTime>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default styled(SOLNotificationItem)`
    ${({ status, theme }) => {
        const notificationBackground =
            status === NotificationStatus.NEW ? theme.palette.purple.lightest : theme.palette.white.base;
        const opacity = status === NotificationStatus.READ ? 0.5 : 1;

        return css`
            position: relative;
            background-color: ${notificationBackground};
            opacity: ${opacity};

            ${IconButton} {
                width: 24px;
                height: 24px;
                position: absolute;
                right: ${theme.spacing[3]};
                top: ${theme.spacing[2]};
                --icon-color: ${theme.palette.black.base};
            }
        `;
    }}
`;
