import palette from "./palette";

const fontFamily = `font-family: "Nunito Sans", sans-serif;`;
const color = `color: var(--text-color, ${palette.black.base});`;

const typography = {
    h1: `
        ${fontFamily}
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 900;
        ${color}
        text-decoration: none;
    `,
    h2: `
        ${fontFamily}
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 800;
        ${color}
        text-decoration: none;
    `,
    h3: `
        ${fontFamily}
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        ${color}
        text-decoration: none;
    `,
    h4: `
        ${fontFamily}
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 600;
        ${color}
        text-decoration:
    `,
    subheading: `
        ${fontFamily}
        font-size: .75rem;
        line-height: 1rem;
        font-weight: 600;
        ${color}
        text-decoration: none;
    `,
    p: `
        ${fontFamily}
        font-size: .875rem;
        line-height: 1.125rem;
        font-weight: 400;
        ${color}
        text-decoration: none;
    `,
    code: `
        font-family: "Source Code Pro", sans-serif;
        font-size: .875rem;
        line-height: 1.5rem;
        font-weight: 400;
        ${color}
        text-decoration: none;
    `,
    label: `
        ${fontFamily}
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 600;
        ${color}
        text-decoration: none;
    `,
    "label-small": `
        ${fontFamily}
        font-size: .625rem;
        line-height: .75rem;
        font-weight: 600;
        ${color}
        text-decoration: none;
    `,
    button: `
        ${fontFamily}
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
        ${color}
        text-decoration: none;
    `,
    "button-small": `
        ${fontFamily}
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 700;
        ${color}
        text-decoration: none;
    `,
    "inline-link": `
        ${fontFamily}
        font-size: .875rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${palette.purple.base};
        text-decoration: underline;
    `,
    /**
     * @deprecated
     * label-xs is going to be modified or removed from the design system
     * try not to use it again
     */
    "label-xs": `
        ${fontFamily}
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 600;
        ${color}
        text-decoration: none;
    `,
    /**
     * @deprecated
     * label-xs-bold is going to be modified or removed from the design system
     * try not to use it again
     */
    "label-xs-bold": `
        ${fontFamily}
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 700;
        ${color}
        text-decoration: none;
    `,
};

export default typography;
