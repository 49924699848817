import clsx from "clsx";
import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const { stroke, fill } = classes;

const Warning = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M12 19.25C12.6904 19.25 13.25 18.6904 13.25 18C13.25 17.3096 12.6904 16.75 12 16.75C11.3096 16.75 10.75 17.3096 10.75 18C10.75 18.6904 11.3096 19.25 12 19.25Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                className={clsx(stroke, fill)}
            />
            <path d="M12 13L12 4" stroke="black" strokeWidth="2" strokeLinecap="round" className={stroke} />
        </SvgIcon>
    );
};

export default Warning;
