import { Button, Dropdown, App } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { Loader } from "@sol/icons";
import { useCurrentBrand } from "@sol/sdk/brands/useCurrentBrand";

import { getRoleTranslationKey } from "../../../utils/getRole";

const SupportButton = () => {
    const [t] = useTranslation();
    const { data: currentBrand, isLoading: isCurrentBrandLoading } = useCurrentBrand({});
    const supportEmail = currentBrand?.brand?.contactEmail;
    const {
        user: { name, surname, email, roles, language },
    } = useAuthenticatedUser();

    const { activeClassroom } = useActiveClassroom();

    const { message } = App.useApp();

    const mailTemplate = `mailto:${supportEmail}?subject=${t(
        "component.footer.support.helpRequest",
    )} - ${name} ${surname} - ${activeClassroom?.title}&body=%5B${t(
        "component.footer.support.describeYourIssue",
    )}%5D%0D%0A%0D%0A%0D%0A%0D%0A__________%0D%0A${t(
        "component.footer.support.informations",
    )}%0D%0A%0D%0Aname%3A%20${name}%20${surname}%0D%0Aemail%3A%20${email}%0D%0Arole%3A%20${t(
        getRoleTranslationKey(roles),
    )}%0D%0Alanguage%3A%20${language.title}%0D%0Aclassroom%3A%20${activeClassroom?.title}%0D%0Alocation%3A%20${
        activeClassroom?.location?.title
    }%0D%0Areference%3A%20${activeClassroom?.externalReferences?.[0]}`;

    return (
        <Dropdown
            disabled={isCurrentBrandLoading}
            menu={{
                onClick: info => {
                    if (info.key === "copy" && supportEmail) {
                        navigator.clipboard.writeText(supportEmail).then(() => {
                            message.success({ content: t("component.footer.supportButton.copied") });
                        });
                    }
                },
                items: [
                    {
                        key: "open",
                        label: (
                            <a target="_blank" rel="noopener noreferrer" href={mailTemplate}>
                                {t("component.footer.supportButton.openEmailClient")}
                            </a>
                        ),
                    },
                    {
                        key: "copy",
                        label: t("component.footer.supportButton.copyEmailAddress"),
                    },
                ],
            }}
        >
            <Button disabled={isCurrentBrandLoading} type="link">
                {isCurrentBrandLoading ? <Loader /> : supportEmail}
            </Button>
        </Dropdown>
    );
};

export default SupportButton;
