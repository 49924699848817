import { useRouter } from "next/router";
import { FC, createContext, useCallback, useContext, useEffect, useState } from "react";

interface NavigationContextType {
    previousUrl: string | null;
    historyStack: string[];
}

const NavigationContext = createContext<NavigationContextType>({
    previousUrl: null,
    historyStack: [],
});

const MAX_HISTORY_SIZE = 50; // Adjust as needed

/**
 * Provider component for managing navigation state.
 *
 * @component
 */
export const NavigationProvider: FC = ({ children }) => {
    const [previousUrl, setPreviousUrl] = useState<string | null>(null);
    const [historyStack, setHistoryStack] = useState<string[]>([]);
    const router = useRouter();

    /**
     * Handles route changes by updating the previous URL and history stack.
     *
     * @param {string} url - The new URL after route change.
     */
    const handleRouteChange = useCallback(
        (url: string) => {
            setPreviousUrl(historyStack[historyStack.length - 1] || null);
            setHistoryStack(prevStack => {
                const newStack = [...prevStack];
                const existingIndex = newStack.indexOf(url);
                if (existingIndex !== -1) {
                    newStack.splice(existingIndex, 1);
                }
                newStack.push(url);
                return newStack.slice(-MAX_HISTORY_SIZE); // Limit the stack size
            });
        },
        [historyStack],
    );
    useEffect(() => {
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router, historyStack]);

    return <NavigationContext.Provider value={{ previousUrl, historyStack }}>{children}</NavigationContext.Provider>;
};

/**
 * Hook to access the navigation context.
 *
 * @returns {Object} An object containing the previous URL and history stack.
 * @throws {Error} If used outside of NavigationProvider.
 */
export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error("useNavigation must be used within a NavigationProvider");
    }
    return context;
};
