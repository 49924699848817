import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

export const useDeleteResource = (config: MutateConfig<AxiosResponse<void>, any, string>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const query = (resourceId: string) => api.delete(`/resources/${resourceId}`);

    return useMutation(query, config);
};
