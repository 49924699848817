import { IGroupTasksResource } from "@sol/sdk";
import { createApiMutationHook, IApiMutationFunction } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

export type IGroupTaskDeleteMutationParameters = {
    task: RelationalValue<IGroupTasksResource>;
};

export type IGroupTaskDeleteMutationResult = void;

export const groupTaskDeleteMutation: IApiMutationFunction<IGroupTaskDeleteMutationParameters> = ({ task }) => ({
    method: "DELETE",
    url: `/group_tasks/${mapRelationalValue(task)}`,
});

export const useGroupTaskDeleteMutation = createApiMutationHook<
    IGroupTaskDeleteMutationParameters,
    IGroupTaskDeleteMutationResult
>(groupTaskDeleteMutation);
