import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Paperclip = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M19 11.959L12.3811 18.5779C11.5703 19.3887 10.4705 19.8443 9.32376 19.8443C8.17703 19.8443 7.07726 19.3887 6.2664 18.5779C5.45554 17.767 5 16.6672 5 15.5205C5 14.3738 5.45554 13.274 6.2664 12.4631L12.8853 5.84427C13.4259 5.30369 14.159 5 14.9235 5C15.688 5 16.4212 5.30369 16.9618 5.84427C17.5023 6.38484 17.806 7.11802 17.806 7.88251C17.806 8.647 17.5023 9.38017 16.9618 9.92075L10.3357 16.5396C10.0654 16.8099 9.6988 16.9618 9.31656 16.9618C8.93431 16.9618 8.56773 16.8099 8.29744 16.5396C8.02715 16.2693 7.8753 15.9028 7.8753 15.5205C7.8753 15.1383 8.02715 14.7717 8.29744 14.5014L14.4122 8.39387"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
