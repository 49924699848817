import React, { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction } from "react";
import styled from "styled-components";

import { Loader } from "@sol/icons";

type Props = Omit<ComponentPropsWithoutRef<"div">, "children">;

const BlockSkeleton: ForwardRefRenderFunction<HTMLDivElement, Props> = (props, ref) => {
    return (
        <div ref={ref} {...props}>
            <Loader />
        </div>
    );
};

const _BlockSkeleton = forwardRef<HTMLDivElement, Props>(BlockSkeleton);

export default styled(_BlockSkeleton)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.purple.lightest};
    display: flex;
    justify-content: center;
    align-items: center;

    ${Loader} {
        width: 56px;
        height: 56px;

        --icon-color: ${({ theme }) => theme.palette.purple.base};
    }
`;
