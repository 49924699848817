import styled from "styled-components";

export const List = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
`;

export default List;
