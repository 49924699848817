import defaults from "lodash/defaults";
import isString from "lodash/isString";
import { ReactNode, Children } from "react";
import styled, { css } from "styled-components";

import { SvgIcon } from "@sol/icons";

import { Theme } from "../../../uikit/theme";

export const wrapStringChildren = (child: ReactNode) => {
    // We wrap text content with span in order to
    // target icon as first and last child
    if (isString(child)) {
        return <span>{child}</span>;
    }

    return child;
};

export type ButtonVariant = "default" | "primary" | "success" | "danger";

export type ButtonProps = {
    /**
     * Define the button colorscheme variant
     */
    variant?: ButtonVariant;
    /**
     * Display borders and blank background
     */
    outlined?: boolean;
    /**
     * Disable button without visual feedback
     */
    loading?: boolean;
    /**
     * Reduce the size of the button
     */
    small?: boolean;
    /**
     * Neutral colorscheme variant to allow better
     * differentiation with the toggled (filled) state
     */
    toggle?: boolean;
    /**
     * Background filled with main color of the scheme
     */
    filled?: boolean;
    /**
     * Remove raised effect when hovering
     */
    flat?: boolean;
};

const getVariant = (theme: Theme, { variant, filled }: ButtonProps) => {
    const white = theme.palette.white.base;
    const yellow = theme.palette.yellow.base;

    const colorScheme = (color: string) => {
        return {
            borderColor: white,
            backgroundColor: white,
            textColor: color,
            shadowColor: color,

            ...(filled && {
                borderColor: color,
                backgroundColor: color,
                textColor: white,
            }),
        };
    };

    switch (variant) {
        case "primary":
            return {
                ...colorScheme(theme.palette.purple.base),
                shadowColor: yellow,
            };
        case "success":
            return colorScheme(theme.palette.green.base);
        case "danger":
            return colorScheme(theme.palette.red.base);
        default:
            return {
                borderColor: white,
                backgroundColor: white,
                textColor: theme.palette.purple.base,
                shadowColor: theme.palette.purple.base,
            };
    }
};

export const style = ({
    theme,
    variant = "default",
    outlined,
    small,
    loading,
    toggle,
    filled,
    flat,
}: {
    theme: Theme;
} & ButtonProps) => {
    const { backgroundColor, textColor, shadowColor, borderColor } = defaults(
        toggle
            ? {
                  backgroundColor: theme.palette.grey.lightest,
                  textColor: theme.palette.grey.base,
                  borderColor: theme.palette.grey.lightest,
              }
            : {},
        getVariant(theme, {
            variant,
            outlined,
            toggle,
            filled,
        }),
    );

    const iconSize = `${small ? 1 : 1.5}rem`;
    const borderSize = theme.spacing[1];

    const verticalPadding = small
        ? `calc(${theme.spacing[2]} - (${borderSize}))`
        : `calc(${theme.spacing[4]} - (${borderSize}))`;
    const horizontalPadding = small
        ? `calc(${theme.spacing[3]} - (${borderSize}))`
        : `calc(${theme.spacing[6]} - (${borderSize}))`;

    return css`
        ${small ? theme.typography.subheading : theme.typography.button};

        --raise-distance: ${flat ? "0px" : "3px"};
        --hover-shadow: var(--raise-distance) var(--raise-distance) 0px ${shadowColor};
        --bg-color: ${backgroundColor};
        --border-color: ${borderColor};
        --text-color: ${textColor};
        --icon-color: var(--text-color);

        color: var(--text-color);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${theme.spacing[3]};

        margin: ${({ theme }) => theme.spacing[2]};
        padding: ${verticalPadding} ${horizontalPadding};
        border-radius: ${theme.shape.borderRadius.medium};
        border: var(--border-size, ${borderSize}) solid var(--border-color);

        background-color: var(--bg-color);

        cursor: pointer;
        transition: box-shadow 0.2s, transform 0.2s;
        will-change: box-shadow, transform;

        :focus {
            outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
            outline-offset: 2px;
        }

        :hover {
            box-shadow: var(--hover-shadow);
            transform: translate(calc(-1 * var(--raise-distance)), calc(-1 * var(--raise-distance)));
        }

        :disabled {
            ${!loading && "opacity: 0.65;"}
            pointer-events: none;
        }

        :active,
        :active:hover {
            box-shadow: none;
            transform: translate(0);
        }

        > ${SvgIcon} {
            height: ${iconSize};
            width: ${iconSize};

            flex-shrink: 0;
        }
    `;
};

const Button = styled.button
    .withConfig({
        shouldForwardProp: (prop, defaultValidatorFn) => !["loading"].includes(prop) && defaultValidatorFn(prop),
    })
    .attrs<ButtonProps>(props => {
        const { loading, children, disabled } = props;

        return {
            disabled: disabled || loading,
            children: Children.map(children, wrapStringChildren),
        };
    })`
    ${style}
`;

export default Button;
