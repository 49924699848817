import { Avatar, Button, ConfigProvider, Divider, Dropdown, Layout, theme, ThemeConfig } from "antd";
import { cx } from "class-variance-authority";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IUser } from "@sol/sdk";
import { Chevron, DoubleArrow, Plus } from "@sol/uikit/core/icons";
import { SideBarLogo } from "@sol/uikit/core/icons/SideBarLogo";
import { Typography } from "@sol/uikit/core/Typography";
import { Menu as SOLMenu, useRouteBasedMenuState } from "@sol/uikit/navigation";

import { NewContentMenu } from "../NewContentMenu";
import { DropdownMenuItems, type MenuItem } from "./utils";

export type MenuProps = {
    menuItems: MenuItem[];
    quickActionItems: MenuItem[];
    dropdownItems: MenuItem[];
    currentUser?: IUser;
};

export const Menu = ({ menuItems, quickActionItems, dropdownItems, currentUser }: MenuProps) => {
    const { Sider } = Layout;
    const { useToken } = theme;
    const { token } = useToken();
    const [t] = useTranslation();
    const router = useRouter();

    const [menuState, setMenuState] = useState<"collapsed" | "overlay" | "fixed">("fixed");

    const newContentMenuItems = useMemo(
        () => [
            {
                type: "group",
                label: t("component.Navigation.Menu.quickActionsMenu.title"),
                children: quickActionItems.map(item => {
                    if (!item) {
                        return item;
                    }

                    if ("icon" in item) {
                        return item;
                    }

                    return { ...item, icon: <Plus size={16} className="rounded-none" /> };
                }),
            } as const,
        ],
        [t, quickActionItems],
    );

    const mainMenuState = useRouteBasedMenuState(menuItems);
    const newContentMenuState = useRouteBasedMenuState(newContentMenuItems);
    const dropdownMenuState = useRouteBasedMenuState(dropdownItems);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownSelectedKey, setDropdownSelectedKey] = useState<string>("");

    const mainMenuThemeConfig: ThemeConfig = {
        cssVar: { key: "main-menu" },
        token: { colorPrimary: token.red },
    };

    const activeDropdownClasses = [
        "!text-[color:var(--ant-color-error-text)]",
        "bg-[color:var(--ant-color-error-bg)]",
        "border",
        "border-[color:var(--ant-color-error-base)]",
        "hover:border-[color:var(--ant-color-error-base)]",
        "hover:!text-[color:var(--ant-color-text-base)]",
    ];

    const toggleCollapsed = () => {
        setMenuState(menuState !== "fixed" ? "fixed" : "collapsed");
    };

    const handleMouseEnter = () => {
        if (menuState === "collapsed") {
            setMenuState("overlay");
        }
    };

    const handleMouseLeave = () => {
        if (menuState === "overlay") {
            setMenuState("collapsed");
        }
    };

    const handleDropdownOpenChange = (visible: boolean) => {
        setDropdownOpen(visible);
    };

    useEffect(() => {
        for (const item of dropdownItems) {
            if (item?.key === router.asPath) {
                setDropdownOpen(true);
                setDropdownSelectedKey(item.key);
                break;
            }
        }
    }, [dropdownItems, router]);

    const displayedDropdownItems = useMemo(() => {
        if (dropdownSelectedKey && dropdownItems) {
            return dropdownItems.reduce((acc, item) => {
                if (item) {
                    const className = cx([item.key === router.asPath ? activeDropdownClasses : ""], item.className);
                    acc.push({ ...item, className });
                }
                return acc;
            }, [] as DropdownMenuItems);
        } else {
            return dropdownItems;
        }
    }, [dropdownItems, dropdownSelectedKey, router]);

    return (
        <Sider
            theme="light"
            className="flex min-h-full flex-col"
            trigger={null}
            collapsedWidth={50}
            collapsed={menuState === "collapsed"}
            onCollapse={value => (value ? "collapsed" : "fixed")}
            collapsible
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            width={menuState === "fixed" ? 200 : 50}
        >
            <div
                className={cx([
                    menuState === "collapsed" ? "w-[50px]" : "w-[200px]",
                    "absolute z-50 top-0 overflow-hidden pt-6 h-full flex flex-col bg-white-base transition-all ease-in-out duration-300",
                ])}
            >
                <div className="relative">
                    <div className="flex h-8 justify-center">
                        <SideBarLogo className="h-full shrink-0" isCollapsed={menuState === "collapsed"} />
                    </div>
                    {menuState !== "collapsed" ? (
                        <Button
                            onClick={toggleCollapsed}
                            className="absolute right-4 top-1/2 inline-flex h-5 w-5 -translate-y-1/2 items-center justify-center p-0.5"
                        >
                            <DoubleArrow direction={menuState !== "fixed" ? "right" : "left"} />
                        </Button>
                    ) : null}
                </div>
                <div className="overflow-y-auto overflow-x-hidden">
                    <ConfigProvider theme={mainMenuThemeConfig}>
                        <SOLMenu
                            className="mt-4"
                            mode="inline"
                            inlineIndent={0}
                            items={menuItems}
                            {...mainMenuState}
                            openKeys={undefined}
                            defaultOpenKeys={mainMenuState.openKeys}
                        />
                    </ConfigProvider>

                    <AnimatePresence>
                        {menuState !== "collapsed" && quickActionItems?.length > 0 ? (
                            <motion.div
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="mt-3 w-[200px] overflow-hidden"
                            >
                                <Divider className="mt-0" />
                                <NewContentMenu
                                    className="mx-3 mb-3 w-auto"
                                    mode="inline"
                                    items={newContentMenuItems}
                                    {...newContentMenuState}
                                />
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </div>
                <Dropdown
                    menu={{ items: displayedDropdownItems, ...dropdownMenuState }}
                    trigger={["click"]}
                    placement="topLeft"
                    overlayClassName="rounded-lg [&_:where(.ant-dropdown-menu-root)]:flex [&_:where(.ant-dropdown-menu-root)]:flex-col [&_:where(.ant-dropdown-menu-root)]:gap-1 px-3"
                    open={menuState !== "collapsed" && dropdownOpen}
                    onOpenChange={handleDropdownOpenChange}
                >
                    <Button
                        type="text"
                        className="mt-auto flex h-fit items-center rounded-none border-x-0 border-b-0 border-t border-t-[color:var(--ant-button-default-border-color)] p-3"
                    >
                        <Typography variant="subHeading" className="text-nowrap">
                            <span className="inline-flex items-center gap-3">
                                {currentUser?.avatarUrl && <Avatar size={24} src={currentUser.avatarUrl} />}

                                {currentUser && !currentUser.avatarUrl && (
                                    <Avatar size={24}>
                                        {currentUser?.name?.split(" ")[0].charAt(0)}{" "}
                                        {currentUser?.surname?.split(" ")[0].charAt(0)}
                                    </Avatar>
                                )}
                                <span className={cx([menuState === "collapsed" ? "hidden" : "block"])}>
                                    {t("component.Navigation.Menu.dropdownMenu.title")}
                                </span>
                            </span>
                        </Typography>
                        <Chevron
                            direction="right"
                            size={16}
                            className={cx([menuState === "collapsed" ? "hidden" : "block", "ml-auto"])}
                            fill="transparent-base"
                        />
                    </Button>
                </Dropdown>
            </div>
        </Sider>
    );
};
