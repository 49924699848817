import { AxiosPromise } from "axios";
import { useCallback } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { ICurrentBrandResource } from ".";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const url = "/current_brand/self";

export const useCurrentBrand = ({ queryKey, ...options }: Params<ICurrentBrandResource>) => {
    const api = useApi();

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ICurrentBrandResource>(url)), [api, url]);

    return useQuery<ICurrentBrandResource>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
