import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IPaginated } from "../types/IPaginated";
import { serialize } from "../utils/url";
import { ISelfEvaluationList } from "./ISelfEvaluationList";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    filters?: {
        frameworks?: string | string[];
        skills?: string | string[];
        officialTitle?: string;
        learnerUuid?: string | string[];
    };
    orderBy?: "updatedAt" | "createdAt";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { frameworks, skills, officialTitle, learnerUuid } = filters || {};
    return filters
        ? {
              "skillLevels.skill.uuid": skills,
              "skillLevels.skill.frameworks.uuid": frameworks,
              "skillLevels.skill.officialTitle": officialTitle,
              "learner.uuid": learnerUuid,
          }
        : undefined;
};

export const useSelfEvaluations = ({
    queryKey,
    filters,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<ISelfEvaluationList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/self_evaluations${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ISelfEvaluationList>(url)), [api, url]);

    return useQuery<ISelfEvaluationList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
