import { isArray } from "lodash";
import { ParsedUrlQuery } from "querystring";
import { SetStateAction, useMemo } from "react";

import { Route, useRouter } from "@sol/routing";

import { useNavigation } from "./useNavigation";

/**
 * Parses a URL string and extracts pathname and query parameters.
 *
 * @param {string} url - The URL to parse.
 * @returns {Object} An object containing the pathname and query parameters.
 */
const parseUrl = (url: string): { pathname: string; queryParams: ParsedUrlQuery } => {
    try {
        const urlObject = new URL(url, window.location.origin);
        const queryParams: ParsedUrlQuery = {};

        urlObject.searchParams.forEach((value, key) => {
            if (queryParams[key]) {
                if (isArray(queryParams[key])) {
                    (queryParams[key] as string[]).push(value);
                } else {
                    queryParams[key] = [queryParams[key] as string, value];
                }
            } else {
                queryParams[key] = value;
            }
        });

        return { pathname: urlObject.pathname, queryParams };
    } catch (error) {
        return { pathname: "/", queryParams: {} };
    }
};

/**
 * Custom hook to handle navigation logic, particularly for going back in history.
 * It allows specifying special paths that trigger specific navigation behavior.
 *
 * @param {string[]} defaultSpecialPaths - Array of paths considered special for navigation.
 * @returns {Object} An object containing the goBack function.
 */
export const useGoBackNavigation = (defaultSpecialPaths: string[] = ["/create", "/edit", "/assign"]) => {
    const router = useRouter();
    const history = window.history;
    const { previousUrl, historyStack } = useNavigation();

    const specialPaths = useMemo(() => defaultSpecialPaths, []);

    /**
     * Function to handle going back in the application's navigation.
     * It can take a route or a number of steps to go back, and additional options.
     *
     * @param {Route | number} [to] - The route to navigate to or the number of steps to go back.
     * @param {Object} [options] - Additional options for navigation.
     * @param {ParsedUrlQuery} [options.query] - Query parameters for the navigation.
     * @param {SetStateAction<any>} [options.state] - State to be passed with the navigation.
     */
    const goBack = (to?: Route | number, options?: { query?: ParsedUrlQuery; state?: SetStateAction<any> }) => {
        if (typeof to === "string") {
            router.push({ route: to, ...options });
        } else if (typeof to === "number") {
            history.go(to);
        } else if (specialPaths.some(path => previousUrl?.includes(path))) {
            const stepToGoBack = historyStack.findLastIndex(
                (url, index) => index !== 0 && specialPaths.some(path => url.includes(path)),
            );

            if (stepToGoBack !== -1) {
                const targetUrl = historyStack[stepToGoBack - 1];
                const { pathname, queryParams } = parseUrl(targetUrl);
                router.push({ route: pathname as Route, query: queryParams });
            } else {
                router.back();
            }
        } else {
            router.back();
        }
    };

    return { goBack };
};
