export enum UserRole {
    SUPERADMIN = "ROLE_SUPERADMIN",
    ADMIN = "ROLE_ADMIN",
    MANAGER = "ROLE_MANAGER",
    TRAINER = "ROLE_TRAINER",
    TUTOR = "ROLE_TUTOR",
    LEARNER = "ROLE_LEARNER",
}

export default UserRole;
