import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";
import { mapRelationalValue } from "@sol/sdk/utils/filters";

import { IPaginated } from "../types/IPaginated";
import { IUserResource } from "../users";
import { getResourceUuid } from "../utils/getResourceUuid";
import { serialize } from "../utils/url";
import { IClassroom } from "./IClassroom";
import { IClassroomList } from "./IClassroomList";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        learner?: IUserResource | string;
        classroom?: IClassroom;
        restricted?: boolean;
    };
    queryKey?: QueryKey;
    orderBy?: "updatedAt" | "createdAt" | "startDate";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { learner, classroom, restricted } = filters || {};
    return {
        restricted: restricted ? 1 : undefined,
        uuid: classroom && getResourceUuid(classroom),
        "learners.uuid": mapRelationalValue(learner),
    };
};

export const useClassrooms = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IClassroomList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/classrooms${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [pagination, order, orderBy, filters],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IClassroomList>(url)), [api, url]);

    return useQuery<IClassroomList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
