import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Resource = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M5 6H9.2C9.94261 6 10.6548 6.295 11.1799 6.8201C11.705 7.3452 12 8.05739 12 8.8V18.6C12 18.043 11.7788 17.5089 11.3849 17.1151C10.9911 16.7212 10.457 16.5 9.9 16.5H5V6Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M19 6H14.8C14.0574 6 13.3452 6.295 12.8201 6.8201C12.295 7.3452 12 8.05739 12 8.8V18.6C12 18.043 12.2212 17.5089 12.6151 17.1151C13.0089 16.7212 13.543 16.5 14.1 16.5H19V6Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default styled(Resource)``;
