import { Reducer } from "react";
import { AnyAction, StoreEnhancer, compose } from "redux";
import { createStore as createReduxStore } from "redux-dynamic-modules";
import { reducer as formReducer } from "redux-form";
import { PersistConfig, persistReducer } from "redux-persist";
import getStoredState from "redux-persist/lib/getStoredState";
import storage from "redux-persist/lib/storage";

import analytics from "./analytics";
import authentication from "./authentication";
import gdpr from "./gdpr";
import { getGDPRState } from "./gdpr/selector";
import i18n, { II18nState, initialState as i18nInitialState } from "./i18n";
import requests, { IRequestState, initialState as requestInitialState } from "./requests";
import user, { IUserState, initialState as userInitialState } from "./user";

export interface IRootState {
    user: IUserState;
    request: IRequestState;
    i18n: II18nState;

    // TODO: Delete when all stores will be typed
    [key: string]: any;
}

const storeInitialState: IRootState = {
    user: userInitialState,
    request: requestInitialState,
    i18n: i18nInitialState,
};

const persistConfig: PersistConfig<IRootState> = {
    key: "simplonline",
    storage: {
        getItem: (key: string): Promise<string | null> => {
            return storage.getItem(key);
        },
        setItem: async (key: string, item: string): Promise<void> => {
            // We override the storage to be aware of GDPR policy

            // We deserialize the item to get state
            const state: any = await getStoredState({
                key: "dummy",
                storage: {
                    async setItem() {
                        return;
                    },
                    getItem() {
                        return Promise.resolve(item);
                    },
                    async removeItem() {
                        return;
                    },
                },
            });

            // We trigger storage against GDPR policy configuration
            if (getGDPRState(state).initial) {
                return Promise.resolve();
            } else {
                return storage.setItem(key, item);
            }
        },
        removeItem: (key: string): Promise<void> => storage.removeItem(key),
    }, // set by default to localStorage
    whitelist: ["gdpr", "authentication", "i18n", "classroom"],
};

const persistEnhancer =
    (createStore: any) => (reducer: Reducer<IRootState, AnyAction>, initialState: IRootState, enhancer: any) => {
        const persistedReducer = persistReducer(persistConfig, reducer);

        return createStore(persistedReducer, initialState, enhancer);
    };

const composeEnhancers =
    (typeof window !== "undefined" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const initializeStore = (initialState = storeInitialState) =>
    createReduxStore(
        {
            initialState,
            extensions: [],
            enhancers: [persistEnhancer as StoreEnhancer],
            advancedComposeEnhancers: composeEnhancers,
        },
        gdpr,
        {
            id: "redux-form",
            reducerMap: {
                form: formReducer,
            },
        },
        authentication,
        i18n,
        requests,
        user,
        analytics,
    );

export default initializeStore;
