import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";
import { IUserResource } from "../users/IUserResource";

export enum NotificationType {
    WORK_NOT_CORRECTED = "work_non_corrected_to_trainer",
    WORK_NOT_CORRECTED_TO_TUTOR = "work_non_corrected_to_tutor",
    MESSAGE_LEARNER_TO_TRAINER = "work_message_learner_to_trainer",
    MESSAGE_TRAINER_TO_LEARNER = "work_message_trainer_to_learner",
    MESSAGE_TRAINER_TO_TUTOR = "work_message_trainer_to_tutor",
    MESSAGE_LEARNER_TO_TUTOR = "work_message_learner_to_tutor",
    GROUP_MESSAGE_LEARNER_TO_TRAINER = "group_message_learner_to_trainer",
    GROUP_MESSAGE_LEARNER_TO_LEARNER = "group_message_learner_to_learner",
    GROUP_MESSAGE_TRAINER_TO_LEARNER = "group_message_trainer_to_learner",
    GROUP_MESSAGE_LEARNER_TO_TUTOR = "group_message_learner_to_tutor",
    GROUP_MESSAGE_TRAINER_TO_TUTOR = "group_message_trainer_to_tutor",
    BRIEF_ASSIGNED = "topic_to_learner",
    WORK_CORRECTED = "work_corrected_to_learner",
    WORK_CORRECTED_TO_TUTOR = "work_corrected_to_tutor",
    GROUP_CORRECTION_TO_LEARNER = "group_correction_to_learner",
    GROUP_CORRECTION_TO_TUTOR = "group_correction_to_tutor",
    GROUP_WORK_TO_TRAINER = "group_work_to_trainer",
    GROUP_WORK_TO_LEARNER = "group_work_to_learner",
    GROUP_WORK_TO_TUTOR = "group_work_to_tutor",
    GROUP_SPACE_TO_LEARNER = "group_space_to_learner",
    SELF_EVALUATION_TO_TRAINER = "self_evaluation_to_trainer",
    SELF_EVALUATION_TO_TUTOR = "self_evaluation_to_tutor",
    FOLLOW_UP_TO_TRAINER = "follow_up_to_trainer",
    FOLLOW_UP_TO_TUTOR = "follow_up_to_tutor",
    FOLLOW_UP_TO_LEARNER = "follow_up_to_learner",
    BRIEF_COEDITOR_ADDED_TO_TRAINER = "brief_coeditor_added_to_trainer",
    BRIEF_COEDITED_TO_CREATOR = "brief_coedited_to_creator",
}

export enum NotificationStatus {
    NEW = "new",
    SEEN = "seen",
    READ = "read",
    HIDDEN = "hidden",
}

export interface INotificationUser extends IUserResource {
    avatarUrl?: string;
}

interface INotificationEntity extends IHydraResource {
    title: string;
}

export interface INotificationResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.NOTIFICATION;
    senderUser: INotificationUser;
    contentUser?: INotificationUser;
    recipientClassroom: string;
    type: NotificationType;
    status: NotificationStatus;
    linkEntity: INotificationEntity;
    titleEntity: INotificationEntity;
}
