import { OrderedMap } from "immutable";
import React, { FC, useState, useRef, useCallback, useEffect, useContext } from "react";
import { v4 as uuid } from "uuid";

import Notifications from "./Notifications";

export enum NotificationLevel {
    INFO = "info",
    SUCCESS = "success",
    ERROR = "error",
}

const DEFAULT_TTL = 3000; // ms

export type NotificationType = {
    /**
     * The information level
     */
    level: NotificationLevel;
    /**
     * The message to be displayed (must be serializable)
     */
    message: string;
    /**
     * The time to leave in milliseconds (default to 2000ms)
     */
    ttl: number;
    /**
     * The creation date must be automatically set to Date.now()
     */
    createdAt: number;
};

export type Context = {
    notifications: OrderedMap<string, NotificationType>;
    notify: (
        notification: Omit<NotificationType, "ttl" | "createdAt"> &
            Partial<Pick<NotificationType, "ttl" | "createdAt">>,
    ) => string;
    dismiss: (uuid: string) => void;
    clear: () => void;
};

export const NotificationsContext = React.createContext<Context | undefined>(undefined);

export const NotificationsProvider: FC = ({ children }) => {
    const [notifications, setNotifications] = useState(OrderedMap<string, NotificationType>());
    const notificationInterval = useRef<number | null>();

    const clearNotificationInterval = () => {
        if (notificationInterval.current) {
            clearInterval(notificationInterval.current);
            notificationInterval.current = null;
        }
    };

    // TODO: Better handling of notifications timeout with setTimeout
    const startNotificationInterval = useCallback(() => {
        clearNotificationInterval();
        notificationInterval.current = window.setInterval(() => {
            notifications.forEach((notif: NotificationType, key: string) => {
                if (notif.createdAt + notif.ttl < Date.now()) {
                    dismiss(key);
                }
            });
        }, 1000);
    }, [notifications]);

    useEffect(() => {
        if (notifications.size > 0 && !notificationInterval.current) {
            startNotificationInterval();
        } else if (notifications.size === 0 && notificationInterval.current) {
            clearNotificationInterval();
        }

        return () => clearNotificationInterval();
    }, [notifications]);

    const notify = (
        notification: Omit<NotificationType, "ttl" | "createdAt"> &
            Partial<Pick<NotificationType, "ttl" | "createdAt">>,
    ) => {
        const newNotification = {
            ttl: DEFAULT_TTL,
            createdAt: Date.now(),
            ...notification,
        };

        const notificationId = uuid();

        setNotifications(notifs => notifs.set(notificationId, newNotification));

        return notificationId;
    };

    const dismiss = (uuid: string) => {
        setNotifications(notifications.delete(uuid));
    };

    const clear = () => {
        setNotifications(notifications.clear());
    };

    return (
        <NotificationsContext.Provider value={{ notifications, notify, dismiss, clear }}>
            <Notifications />
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    const context = useContext(NotificationsContext);

    if (!context) {
        throw new Error("NotificationsProvider context is missing");
    }

    return context;
};

export const useNotify = () => {
    const { notify } = useNotifications();

    return notify;
};

export default NotificationsContext;
