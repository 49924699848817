import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { IBriefResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

import { mapRelationalValue, RelationalValue } from "../utils/filters";
import { IBrief } from "./IBrief";

type Params<TResult = unknown, TError = unknown> = {
    brief?: RelationalValue<IBriefResource>;
    queryKey?: QueryKey;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useBrief = ({ brief, queryKey, enabled, ...options }: Params<IBrief>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(() => (brief ? `/briefs/${mapRelationalValue(brief)}` : ""), [brief]);

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IBrief>(url)), [api, url]);

    return useQuery<IBrief>({
        queryKey: queryKey || url,
        queryFn,
        config: { ...options, enabled: enabled ?? !!url },
    });
};
