import { Action } from "../types";

export enum ActionType {
    Configure = "gdpr/CONFIGURE",
}

export enum Category {
    Analytics = "analytics",
}

export type IGDPRSettings = {
    [key in Category]?: boolean;
};

export interface IOptInOutAction extends Action {
    type: ActionType;
    payload: IGDPRSettings;
}

export interface IState {
    initial: boolean;
    settings: IGDPRSettings;
}

export interface IStoreModuleState {
    gdpr: IState;
}
