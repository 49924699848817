import React from "react";
import styled, { keyframes } from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const DEG_TO_RAD = Math.PI / 180;
const partialCircle = (cx: number, cy: number, r: number, startAngle: number, endAngle: number) => {
    const start = DEG_TO_RAD * startAngle;
    const end = DEG_TO_RAD * endAngle;

    const length = end - start;
    if (length === 0) {
        throw new Error();
    }

    const fromX = r * Math.cos(start) + cx;
    const fromY = r * Math.sin(start) + cy;
    const toX = r * Math.cos(end) + cx;
    const toY = r * Math.sin(end) + cy;
    const large = Math.abs(length) <= Math.PI ? "0" : "1";
    const sweep = length < 0 ? "0" : "1";

    return `M${fromX} ${fromY} A${r} ${r} 0 ${large} ${sweep} ${toX} ${toY}`;
};

const D = partialCircle(12, 12, 7, 0, 270);

const Loading = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path d={D} stroke="black" strokeWidth="2" strokeLinecap="round" className={stroke} />
        </SvgIcon>
    );
};

const spinAnim = keyframes`
    from { 
        transform: rotate(0deg); 
    }
    
    to { 
        transform: rotate(360deg); 
    }
`;

export default styled(Loading)`
    animation-name: ${spinAnim};
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;
