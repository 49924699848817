import { Set } from "immutable";
import React, { useMemo, ReactNode } from "react";

import { Feature, FeaturesProvider, FeaturesProviderRenderProp } from "@sol/features";
import { UserRole } from "@sol/sdk";

import { UserRoleFeatureMap, AnonymousFeatures } from "./UserRoleFeatureMap";

type Props = { role?: UserRole; children: ReactNode | FeaturesProviderRenderProp };

/**
 * Override available features based on user role
 */
export const RBACProvider = ({ role, children }: Props) => {
    const features = useMemo(() => {
        if (!role) {
            return Set<Feature>(AnonymousFeatures);
        }
        return Set<Feature>(UserRoleFeatureMap[role]);
    }, [role]);

    return <FeaturesProvider features={features}>{children}</FeaturesProvider>;
};

export default RBACProvider;
