import { Children, ReactNode, cloneElement, isValidElement } from "react";
import styled, { css } from "styled-components";

import SvgIcon from "@sol/icons/SvgIcon";
import { SvgIconProps } from "@sol/icons/types";

import { Text } from "../../../uikit";

export type IconButtonVariant = "primary" | "secondary";

type Props = {
    loading?: boolean;
    variant?: IconButtonVariant;
    size?: "tiny" | "xs" | "small" | "large";
};

const IconButton = styled.button
    .withConfig({
        shouldForwardProp: (prop, defaultValidatorFn) => !["loading"].includes(prop) && defaultValidatorFn(prop),
    })
    .attrs<Props>(({ children, size = "small", loading = false, disabled }) => {
        const wrapChildren = (child: ReactNode) => {
            // Need a more coneninet way to distinguish icons
            // in order to avoid passing props to other types of components
            // ex: SROnly will have props that were not intended
            if (isValidElement(child)) {
                let iconSize: SvgIconProps["size"] = undefined;

                switch (size) {
                    case "large":
                        iconSize = 48;
                        break;
                    case "small":
                        iconSize = 32;
                        break;
                    case "xs":
                        iconSize = 24;
                        break;
                    case "tiny":
                        iconSize = 16;
                        break;
                    default:
                        iconSize = 32;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return cloneElement(child, { size: iconSize });
            }

            // We wrap text content in order to
            // target icon as first and last child
            return (
                <Text as="span" variant="label">
                    {child}
                </Text>
            );
        };

        return {
            disabled: disabled || loading,
            children: Children.count(children) === 1 ? wrapChildren(children) : Children.map(children, wrapChildren),
        };
    })<Props>`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};

    ${({ children }) => {
        if (Children.count(children) < 2) {
            return css`
                border-radius: 50%;
            `;
        }
    }};

    text-decoration: none;
    border: none;
    background: none;
    padding: 0;
    outline: none;

    ${SvgIcon} {
        transition: all 0.2s;
        flex-shrink: 0;
    }

    --icon-bg-color: ${({ theme, variant }) =>
        variant === "secondary" ? theme.palette.white.base : theme.palette.grey.lightest};
    --icon-color: ${({ theme }) => theme.palette.purple.base};

    :not(:disabled) {
        cursor: pointer;

        &:focus-within ${SvgIcon} {
            outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
            outline-offset: 2px;
        }

        &:hover,
        &:active {
            --icon-bg-color: ${({ theme }) => theme.palette.purple.base};
            --icon-color: ${({ theme }) => theme.palette.purple.lightest};
        }

        &:active ${SvgIcon} {
            box-shadow: 0 0 0 2px
                    ${({ theme, variant }) =>
                        variant === "secondary" ? theme.palette.white.base : theme.palette.grey.lightest},
                0 0 0 4px ${({ theme }) => theme.palette.purple.light};
        }
    }

    :disabled {
        ${({ theme, loading }) =>
            !loading &&
            css`
                opacity: 0.6;
                --icon-color: ${theme.palette.grey.base};
            `}
    }
`;

export default IconButton;
