import React, { FC, createContext } from "react";

import analytics from "../analytics";
import Analytics from "../analytics/Analytics";

const AnalyticsContext = createContext<Analytics>(analytics);

export const AnalyticsProvider: FC = ({ children }) => {
    return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContext;
