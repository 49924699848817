import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSearch from "src/hooks/useSearch";
import styled from "styled-components";

import { useActiveClassroom, useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { Chevron } from "@sol/uikit/core/icons";
import { AutoComplete } from "@sol/uikit/data-entry/AutoComplete";
import Input from "@sol/uikit/data-entry/Input";

const InputContainer = styled.span<{ width: string }>`
    display: flex;
    align-items: center;
    width: auto;
    gap: var(--ant-margin-sm);

    & .ant-input {
        // ! Value of 1.5rem (24px) is not defined in antd css variables.
        font-size: 1.5rem !important;
        font-weight: 400;
        color: var(--ant-color-primary);
        height: 100%;
        padding: 0;
        width: ${({ width }) => width};
    }

    & svg {
        stroke: var(--ant-color-primary);
    }
`;

/**
 * ActiveClassroomSelect Component
 * @description A component that allows users to select and search for active classroom
 */
const ActiveClassroomSelect = () => {
    const { setActiveClassroom, activeClassroom } = useActiveClassroom();
    const { classrooms } = useAuthenticatedUserClassrooms();

    const [isOpen, setIsOpen] = useState(false);
    const [inputWidth, setInputWidth] = useState("auto");
    const [currentClassroomTitle, setCurrentClassroomTitle] = useState<string | undefined>(activeClassroom?.title);

    const { results: searchResults, setSearch } = useSearch(classrooms ?? [], {
        keys: ["title"],
        threshold: 0.5,
    });

    // Canvas context for performance
    const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);

    // Initialize canvas context
    useEffect(() => {
        const canvas = document.createElement("canvas");
        canvasContextRef.current = canvas.getContext("2d");
        return () => {
            canvasContextRef.current = null;
        };
    }, []);

    const updateInputWidth = useCallback((value: string | undefined) => {
        if (canvasContextRef.current && value) {
            canvasContextRef.current.font = '1.5rem "Nunito Sans"';
            const metrics = canvasContextRef.current.measureText(value);
            const width = Math.ceil(metrics.width);
            setInputWidth(`${width + 5}px`);
        }
    }, []);

    const handleSelect = useCallback(
        (value: string) => {
            const selectedClassroom = searchResults.find(c => c["@id"] === value);
            if (selectedClassroom) {
                setActiveClassroom(value);
                setCurrentClassroomTitle(selectedClassroom.title);
                updateInputWidth(selectedClassroom.title);
            }
            setIsOpen(false);
        },
        [searchResults, setActiveClassroom, updateInputWidth],
    );

    const handleOpenChange = (open: boolean) => {
        if (!open) {
            if (currentClassroomTitle !== activeClassroom?.title) {
                setCurrentClassroomTitle(activeClassroom?.title);
            }
        }
        setIsOpen(open);
    };

    const handleSearch = useCallback((value: string) => setSearch(value), [setSearch]);

    const handleInputChange = useCallback(
        (value: string) => {
            setCurrentClassroomTitle(value);
        },
        [updateInputWidth],
    );

    const options = useMemo(
        () =>
            searchResults.map(classroom => ({
                label: classroom.title,
                value: classroom["@id"],
            })),
        [searchResults],
    );

    useEffect(() => {
        updateInputWidth(currentClassroomTitle);
    }, []);

    return (
        <AutoComplete
            onDropdownVisibleChange={handleOpenChange}
            options={options}
            variant="borderless"
            onSearch={handleSearch}
            onSelect={handleSelect}
            onChange={handleInputChange}
            value={currentClassroomTitle}
        >
            <InputContainer width={inputWidth}>
                <Input variant="borderless" value={currentClassroomTitle} aria-label="Select active classroom" />
                <Chevron size={16} direction={isOpen ? "up" : "down"} fill="transparent" />
            </InputContainer>
        </AutoComplete>
    );
};

export default ActiveClassroomSelect;
