import { decode as decodeJWT } from "jsonwebtoken";
import { isFunction } from "lodash";
import React, { ReactNode, useEffect, useMemo, useContext } from "react";
import { RefetchOptions } from "react-query/types/core/query";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "src/store/user/actions";
import { getUserSelector } from "src/store/user/selectors";

import { IUser, IUserSettings, useUserSettings, useUser } from "@sol/sdk";

export type ContextType = {
    user?: IUser;
    settings?: IUserSettings;
    isLoading: boolean;
    refetchUser: (options?: RefetchOptions | undefined) => Promise<IUser | undefined>;
    refetchSettings: (options?: RefetchOptions | undefined) => Promise<IUserSettings | undefined>;
};

const Context = React.createContext<ContextType>({} as ContextType);

const { Provider } = Context;

type ProviderRenderProp = (context: ContextType) => ReactNode;

type Props = {
    token?: string;
    children: ReactNode | ProviderRenderProp;
};

export const AuthenticatedUserProvider = ({ children, token }: Props) => {
    const dispatch = useDispatch();
    const storedUser = useSelector(getUserSelector);

    const userUuid = useMemo(() => {
        const decodedToken = token && decodeJWT(token);
        return !!decodedToken?.sub ? (decodedToken.sub as string) : undefined;
    }, [token]);

    const {
        data: user,
        isLoading: isUserLoading,
        refetch: refetchUser,
    } = useUser({
        user: userUuid,
    });

    const {
        data: settings,
        refetch: refetchSettings,
        isLoading: areSettingsLoading,
    } = useUserSettings({
        user: userUuid,
    });

    useEffect(() => {
        if (user) {
            dispatch(setUser(user));
        }
    }, [user]);

    const value = useMemo(() => {
        return {
            user: storedUser ? storedUser : undefined,
            settings,
            refetchUser,
            refetchSettings,
            isLoading: isUserLoading || areSettingsLoading || !storedUser || !settings,
        };
    }, [storedUser, settings, isUserLoading, areSettingsLoading]);

    return <Provider value={value}>{children && isFunction(children) ? children(value) : children}</Provider>;
};

export const useAuthenticatedUser = () => {
    const context = useContext(Context);
    const { user, settings } = context;

    if (!user || !settings) {
        throw new Error("No user and or settings found. Use this inside AuthenticatedLayout");
    }

    return { ...context, user, settings };
};

export default Context;
