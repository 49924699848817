import { IModule } from "redux-dynamic-modules";

import { IRootState } from "../index";
import middleware from "./middleware";
import { initialState, reducer } from "./reducer";

const I18nModule: IModule<Partial<IRootState>> = {
    id: "i18n",
    reducerMap: {
        i18n: reducer,
    },
    middlewares: [middleware],
};

export * from "./model";
export { initialState };
export default I18nModule;
