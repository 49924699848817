import React from "react";

const SuccessIcon = ({ color = "#24AB4A" }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <circle opacity="0.1" cx="12" cy="12" r="12" fill={color} />
        <path
            d="M17 8.625L10.125 15.5L7 12.375"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SuccessIcon;
