import { ComponentProps } from "react";
import styled from "styled-components";

import { IUserResource } from "@sol/sdk";
import Avatar from "@sol/uikit/Avatar";

type Props = { user: IUserResource } & Omit<ComponentProps<typeof Avatar>, "src" | "alt" | "children">;

const UserAvatar = (props: Props) => {
    const { user } = props;

    const avatar = user.avatarUrl
        ? { src: user.avatarUrl, alt: "" }
        : {
              children: (user.name && user.surname
                  ? `${user.name.charAt(0)}${user.surname.charAt(0)}`
                  : user.email.substring(0, 2)
              ).toUpperCase(),
          };

    return <Avatar {...props} {...avatar} />;
};

export default styled(UserAvatar)``;
