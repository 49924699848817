import { Layout, Button } from "antd";
import clsx from "clsx";
import Link from "next/link";
import React from "react";

import { Route, interpolate } from "@sol/routing";
import { getResourceUuid, usePageListQuery } from "@sol/sdk";
import { PageStatus } from "@sol/sdk/pages/IPageResource";

import SupportButton from "./SupportButton";

type Props = {
    noFooterMargin?: boolean;
    className?: string;
};
const { Footer } = Layout;

export function AuthenticatedLayoutFooter({ className }: Props) {
    const { data: pages } = usePageListQuery({ filters: { status: PageStatus.PUBLISHED } });

    return (
        <Footer className={clsx("bg-white-base px-8 py-3", className)} role="contentinfo">
            <ul className="flex list-none items-center justify-between ">
                {pages &&
                    pages["hydra:member"].map(page => {
                        const pageUUID = getResourceUuid(page);
                        return (
                            <li key={pageUUID}>
                                <Link href={interpolate(Route.PAGE, { pageUUID })} passHref>
                                    <Button type="link">{page.title}</Button>
                                </Link>
                            </li>
                        );
                    })}
                <li>
                    <SupportButton />
                </li>
            </ul>
        </Footer>
    );
}
