import React, { FC } from "react";

const ScrollContext = React.createContext<HTMLElement | null>(null);

type Props = {
    scroll?: HTMLElement | null;
};

const root = typeof document === "undefined" ? null : document.getElementById("__next");
export const ScrollProvider: FC<Props> = ({ children, scroll }) => {
    return <ScrollContext.Provider value={scroll || root}>{children}</ScrollContext.Provider>;
};

export default ScrollContext;
