import { createElement, forwardRef, ForwardRefRenderFunction, HTMLProps, ReactNode } from "react";

import { typographyVariants, type TypographyVariantsProps } from "./variants";

type TagVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "a" | "label";

export type TypographyProps = {
    tag?: TagVariants;
    children: ReactNode;
    className?: string;
} & TypographyVariantsProps &
    HTMLProps<HTMLElement>;
const Typography: ForwardRefRenderFunction<HTMLElement, TypographyProps> = (
    { tag = "p", children, variant = "paragraph", className, ...props },
    ref,
) => {
    return createElement(tag, { ref, className: typographyVariants({ variant, className }), ...props }, children);
};

export default forwardRef<HTMLElement, TypographyProps>(Typography);
