import { AxiosResponse } from "axios";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

export const useDeleteSelfEvaluation = (config: MutateConfig<AxiosResponse<void>, any, string>) => {
    const api = useApi();

    const query = (selfEvaluationId: string) => api.delete(`/self_evaluations/${selfEvaluationId}`);

    const [deleteSelfEvaluation, { isSuccess, error, reset, isLoading }] = useMutation(query, config);

    return { deleteSelfEvaluation, isSuccess, error, reset, isLoading };
};
