import { AxiosRequestConfig } from "axios";

import { IPage } from "@sol/sdk";
import { createApiQueryHook } from "@sol/sdk/utils/api";
import { RelationalValue, mapRelationalValue } from "@sol/sdk/utils/filters";

import { IPageResource } from "../IPageResource";

export type IPageQueryParameters = {
    page?: RelationalValue<IPageResource>;
};

export type IPageQueryResult = IPage;

export function pageQuery({ page }: IPageQueryParameters): AxiosRequestConfig | undefined {
    if (!page) {
        return;
    }

    return { method: "GET", url: `/pages/${mapRelationalValue(page)}` };
}

export const usePageQuery = createApiQueryHook<IPageQueryParameters, IPageQueryResult>(pageQuery);
