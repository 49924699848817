import { useRouter } from "next/router";
import { stringify } from "qs";
import React, { FC, ReactElement, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Route } from "@sol/routing";

import { IGDPRStoreModuleState } from "../../store/gdpr";

type Props = {
    placeholder?: ReactElement;
};

const GDPRGuard: FC<Props> = ({ placeholder = null, children }) => {
    const initial = useSelector<IGDPRStoreModuleState>((state: IGDPRStoreModuleState): boolean => state.gdpr.initial);
    const router = useRouter();

    // DEBT overkilled-memoization-usage
    const redirect = useMemo(() => initial && router.pathname !== Route.GDPR_DISCLAIMER, [router.pathname, initial]);

    useEffect(() => {
        if (redirect) {
            router.replace(`${Route.GDPR_DISCLAIMER}?${stringify({ redirect: router.asPath })}`);
        }
    }, [redirect]);

    if (redirect) {
        return placeholder;
    }

    return <>{children}</>;
};

export default GDPRGuard;
