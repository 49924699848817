import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Work = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M5 11.913h2.301a1 1 0 01.907.577l.901 1.933a1 1 0 00.906.577H14a1 1 0 00.909-.582l.885-1.923a1 1 0 01.908-.582H19l-1.875-5.25A1 1 0 0016.183 6H7.817a1 1 0 00-.942.664L5 11.913z"
                stroke="black"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M19 12v5a2 2 0 01-2 2H7a2 2 0 01-2-2v-5"
                stroke="black"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M9 9l3 3 3-3"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path d="M12 11V3" stroke="black" strokeWidth={2} strokeLinecap="round" className={stroke} />
        </SvgIcon>
    );
};

export default Work;
