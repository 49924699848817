import React, { FC } from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const { stroke } = classes;

const Open: FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M19.0001 11V5M19.0001 5L13.0001 5M19.0001 5L11 13"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M10 8H5V19H16V14"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Open;
