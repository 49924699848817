import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Brief = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 5V6H14V5H10ZM7.5 5H8V4.75C8 3.7835 8.7835 3 9.75 3H14.25C15.2165 3 16 3.7835 16 4.75V5H16.5C17.175 5 17.8147 5.27842 18.2805 5.76148C18.7451 6.24329 19 6.88928 19 7.55556V18.4444C19 19.1107 18.7451 19.7567 18.2805 20.2385C17.8147 20.7216 17.175 21 16.5 21H7.5C6.82498 21 6.1853 20.7216 5.7195 20.2385C5.25488 19.7567 5 19.1107 5 18.4444V7.55556C5 6.88928 5.25488 6.2433 5.7195 5.76148C6.1853 5.27842 6.82498 5 7.5 5ZM14.25 8C14.9481 8 15.5507 7.59124 15.8316 7H16.5C16.6206 7 16.744 7.04936 16.8408 7.14975C16.9388 7.25137 17 7.39672 17 7.55556V18.4444C17 18.6033 16.9388 18.7486 16.8408 18.8503C16.744 18.9506 16.6206 19 16.5 19H7.5C7.37937 19 7.25599 18.9506 7.15918 18.8503C7.06119 18.7486 7 18.6033 7 18.4444V7.55556C7 7.39672 7.06119 7.25137 7.15918 7.14975C7.25599 7.04936 7.37937 7 7.5 7H8.16841C8.44927 7.59124 9.0519 8 9.75 8H14.25ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H9ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H12.75C13.3023 16 13.75 15.5523 13.75 15C13.75 14.4477 13.3023 14 12.75 14H9Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
