import { cva, type VariantProps } from "class-variance-authority";

export type TypographyVariantsProps = VariantProps<typeof typographyVariants>;

const baseClasses: string[] = [];

export const typographyVariants = cva(baseClasses, {
    variants: {
        variant: {
            h1: ["text-4xl", "font-black", "leading-12"],
            h2: ["text-3xl", "leading-10"],
            h3: ["text-2xl"],
            h4: ["text-xl"],
            subHeading: ["text-xs", "leading-5"],
            paragraph: ["text-sm", "leading-3.5"],
            label: ["text-base", "leading-4"],
            labelSmall: ["text-xxs", "leading-3"],
            code: ["font-code", "text-sm", "leading-6", "tracking-tight"],
        },
    },
    compoundVariants: [
        {
            variant: ["h1", "h2", "h3", "h4", "subHeading", "paragraph", "label", "labelSmall", "code"],
            className: ["font-main"],
        },
        {
            variant: ["h2", "h3"],
            className: ["font-bold"],
        },
        {
            variant: ["h4", "subHeading", "label", "labelSmall"],
            className: ["font-semibold"],
        },
        {
            variant: ["code", "paragraph"],
            className: ["font-normal"],
        },
    ],
});
