import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { getResourceUuid } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

import { IPaginated } from "../types/IPaginated";
import { IUserResource } from "../users";
import { serialize } from "../utils/url";
import { IFollowUpList } from "./";

type Params<TResult = unknown, TError = unknown> = {
    learner?: IUserResource;
    queryKey?: QueryKey;
    orderBy?: "updatedAt" | "createdAt";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useFollowUps = ({
    learner,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IFollowUpList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/follow_ups/${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...(learner ? { "learner.uuid": getResourceUuid(learner) } : undefined),
                },
                true,
            )}`,
        [pagination, order, orderBy, learner],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IFollowUpList>(url)), [api, url]);

    return useQuery<IFollowUpList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
