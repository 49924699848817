import { ReactNode, ReactElement } from "react";

import Feature from "./Feature";
import useCheckFeatureFlag, { FeatureCheckStrategy, FeatureFilter } from "./useCheckFeatureFlag";

type Props = {
    /**
     * Content to display if the features don't match
     */
    placeholder?: ReactNode;
    children: ReactNode;

    /**
     * The check strategy.
     * @default FeatureCheckStrategy.ONE
     */
    strategy?: FeatureCheckStrategy;
    /**
     * A filter to restrict features by scope
     */
    scope?: FeatureFilter;
};

type SingleFeatureProps = Props & {
    /**
     * Feature to check
     */
    feature: Feature;
};

type MultipleFeaturesProps = Props & {
    /**
     * Features to check
     */
    features: Feature[];
};

/**
 * Prevent children from rendering if feature(s) mismatch
 */
export const FeatureGuard = (props: SingleFeatureProps | MultipleFeaturesProps) => {
    const { placeholder = null, strategy, scope, children } = props;
    const featuresList = (props as SingleFeatureProps).feature
        ? [(props as SingleFeatureProps).feature]
        : (props as MultipleFeaturesProps).features;

    const checkFeatureFlag = useCheckFeatureFlag();

    // NOTE: The usage of cast to ReactElement instead of ReactNode here
    // is to circonvent the following typing error
    // 'FeatureGuard' cannot be used as a JSX component.
    // Its return type '{} | null | undefined' is not a valid JSX element.
    // Type 'undefined' is not assignable to type 'Element | null'.
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
    return (
        checkFeatureFlag(featuresList, {
            strategy,
            scope,
        })
            ? children
            : placeholder
    ) as ReactElement<any, any> | null;
};

export default FeatureGuard;
