import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IUserSettings } from "./IUserSettings";

interface PutUserSettingsVariables {
    uuid: string;
    user: string;
    homecards: IUserSettings["homecards"];
}

export const useUpdateUserSettings = (
    config: MutateConfig<AxiosResponse<IUserSettings>, any, PutUserSettingsVariables>,
) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const putSettings = ({ uuid, user, homecards }: PutUserSettingsVariables) =>
        api.put<IUserSettings>(`/user_settings/${uuid}`, {
            user,
            homecards,
        });

    const [putUserSettings, { isSuccess, error, reset, isLoading }] = useMutation(putSettings, config);

    return { putUserSettings, isSuccess, error, reset, isLoading };
};
