import { AxiosRequestConfig } from "axios";

import { IClassroomResource, IFrameworkList, ISkillResource, IUserResource } from "@sol/sdk";
import { createApiQueryHook, IOrderable, IPaginateable, mapOrder, mapPagination } from "@sol/sdk/utils/api";
import { mapRelationalFilter, RelationalFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

export interface IFrameworkListQueryParameters extends IPaginateable, IOrderable<"title" | "createdAt" | "updatedAt"> {
    filters?: {
        title?: string | null;
        restricted?: boolean;
        skills?: RelationalFilter<ISkillResource>;
        classrooms?: RelationalFilter<IClassroomResource>;
        learners?: RelationalFilter<IUserResource>;
    };
}

export type IFrameworkListQueryResult = IFrameworkList;

const mapFilters = (filters: IFrameworkListQueryParameters["filters"]) => {
    const { restricted = true, title, skills, classrooms, learners } = filters ?? {};

    return {
        restricted: restricted ? 1 : undefined,
        title,
        "skills.uuid": mapRelationalFilter(skills),
        "classrooms.uuid": mapRelationalFilter(classrooms),
        "classrooms.learners.uuid": mapRelationalFilter(learners),
    };
};

export function frameworkListQuery({ filters, pagination, order }: IFrameworkListQueryParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/frameworks${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
}

export const useFrameworkListQuery = createApiQueryHook<IFrameworkListQueryParameters, IFrameworkListQueryResult, any>(
    frameworkListQuery,
);
