import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const SelfEvaluation = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 18V6H18V18H6ZM4 5C4 4.44771 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5ZM11.53 6.652C11.9983 6.94471 12.1407 7.56166 11.848 8.03L9.973 11.03C9.82392 11.2685 9.58128 11.4332 9.30458 11.4837C9.02788 11.5343 8.74269 11.4659 8.51896 11.2954L7.39396 10.4383C6.95465 10.1036 6.86986 9.47612 7.20457 9.03681C7.53928 8.59751 8.16674 8.51272 8.60604 8.84742L8.85843 9.03972L10.152 6.97C10.4447 6.50166 11.0617 6.35929 11.53 6.652ZM7.29289 12.7929C7.68342 12.4024 8.31658 12.4024 8.70711 12.7929L9.5 13.5858L10.2929 12.7929C10.6834 12.4024 11.3166 12.4024 11.7071 12.7929C12.0976 13.1834 12.0976 13.8166 11.7071 14.2071L10.9142 15L11.7071 15.7929C12.0976 16.1834 12.0976 16.8166 11.7071 17.2071C11.3166 17.5976 10.6834 17.5976 10.2929 17.2071L9.5 16.4142L8.70711 17.2071C8.31658 17.5976 7.68342 17.5976 7.29289 17.2071C6.90237 16.8166 6.90237 16.1834 7.29289 15.7929L8.08578 15L7.29289 14.2071C6.90237 13.8166 6.90237 13.1834 7.29289 12.7929ZM13 9C13 8.44772 13.4477 8 14 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H14C13.4477 10 13 9.55228 13 9ZM14 14C13.4477 14 13 14.4477 13 15C13 15.5523 13.4477 16 14 16H16C16.5523 16 17 15.5523 17 15C17 14.4477 16.5523 14 16 14H14Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
