/* eslint-disable @typescript-eslint/ban-ts-comment */
import RouterSingleton, { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

export const usePreventRouteChange = (prevent: boolean) => {
    const [resume, setResume] = useState<null | (() => void)>(null);
    const resumeRef = useRef(false);

    useEffect(() => {
        resumeRef.current = false;

        if (prevent) {
            const router = RouterSingleton.router;
            // @ts-ignore
            if (!router?.change) {
                return;
            }
            // @ts-ignore
            const originalChangeFunction = router.change;

            // @ts-ignore
            router.change = async (...args) => {
                const [historyMethod, , asPath] = args;
                // @ts-ignore
                const currentUrl = router.state.asPath.split("?")[0];
                const changedUrl = asPath.split("?")[0];

                const hasNavigatedAwayFromPage = currentUrl !== changedUrl;
                const wasBackOrForwardBrowserButtonClicked = historyMethod === "replaceState";

                if (hasNavigatedAwayFromPage) {
                    if (!resumeRef.current) {
                        setResume(() => () => {
                            resumeRef.current = false;
                            originalChangeFunction.apply(router, args);
                        });

                        if (wasBackOrForwardBrowserButtonClicked) {
                            /*
                             * The URL changes even if the user clicks "false" to navigate away from the page.
                             * It is necessary to update it to reflect the current URL.
                             */
                            // @ts-ignore
                            await router.push(router.state.asPath);

                            /*
                             * @todo
                             *   I attempted to determine if the user clicked the forward or back button on the browser,
                             *   but was unable to find a solution after several hours of effort. As a result, I temporarily
                             *   hardcoded it to assume the back button was clicked, since that is the most common scenario.
                             *   However, this may cause issues with the URL if the forward button is actually clicked.
                             *   I hope that a solution can be found in the future.
                             */
                            const browserDirection = "back";

                            browserDirection === "back"
                                ? history.go(1) // back button
                                : history.go(-1); // forward button
                        }
                    } else {
                        originalChangeFunction.apply(router, args);
                    }
                }
            };

            return () => {
                // @ts-ignore
                router.change = originalChangeFunction;
            };
        } else {
            setResume(null);
        }
    }, [prevent, useRouter()]);

    return {
        force: useCallback(() => {
            resumeRef.current = true;
        }, []),
        resume,
        reset: useCallback(() => {
            resumeRef.current = false;
            setResume(null);
        }, []),
    };
};
