import { isArray } from "lodash";

import { UserRole } from "@sol/sdk/users/UserRole";

import { translationKeysByRole } from "../constants/roles";

export const getRoleTranslationKey = (roles: UserRole[] | UserRole) => {
    if (isArray(roles)) {
        return translationKeysByRole[roles[0]];
    }
    return translationKeysByRole[roles];
};
