import React from "react";

const WebUrlFull = () => {
    return (
        <svg
            width="74"
            height="47"
            viewBox="0 0 74 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
        >
            <path
                d="M53.9 0.7C53.7 0.3 53.3 0 52.8 0H33.5H23.2C22.5 0 22 0.5 22 1.2V11.6V45.8C22 46 22 46.1 22.1 46.3C22.2 46.6 22.5 46.8 22.7 46.9C22.9 47 23 47 23.2 47H42.5H52.8C53.5 47 54 46.5 54 45.8V35.4V1.2C54 1 54 0.9 53.9 0.7Z"
                fill="#5447C8"
            />
            <path
                d="M35.8999 27.7H24.8999C24.2999 27.7 23.8999 27.3 23.8999 26.7V17.7C23.8999 17.1 24.2999 16.7 24.8999 16.7H35.8999C36.4999 16.7 36.8999 17.1 36.8999 17.7V26.7C36.8999 27.3 36.4999 27.7 35.8999 27.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 18.7H38.8999C38.2999 18.7 37.8999 18.3 37.8999 17.7C37.8999 17.1 38.2999 16.7 38.8999 16.7H50.8999C51.4999 16.7 51.8999 17.1 51.8999 17.7C51.8999 18.3 51.4999 18.7 50.8999 18.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 21.7H38.8999C38.2999 21.7 37.8999 21.3 37.8999 20.7C37.8999 20.1 38.2999 19.7 38.8999 19.7H50.8999C51.4999 19.7 51.8999 20.1 51.8999 20.7C51.8999 21.3 51.4999 21.7 50.8999 21.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 24.7H38.8999C38.2999 24.7 37.8999 24.3 37.8999 23.7C37.8999 23.1 38.2999 22.7 38.8999 22.7H50.8999C51.4999 22.7 51.8999 23.1 51.8999 23.7C51.8999 24.3 51.4999 24.7 50.8999 24.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 27.7H38.8999C38.2999 27.7 37.8999 27.3 37.8999 26.7C37.8999 26.1 38.2999 25.7 38.8999 25.7H50.8999C51.4999 25.7 51.8999 26.1 51.8999 26.7C51.8999 27.3 51.4999 27.7 50.8999 27.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 4.69995H24.8999C24.2999 4.69995 23.8999 4.29995 23.8999 3.69995V2.69995C23.8999 2.09995 24.2999 1.69995 24.8999 1.69995H50.8999C51.4999 1.69995 51.8999 2.09995 51.8999 2.69995V3.69995C51.8999 4.29995 51.4999 4.69995 50.8999 4.69995Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 15.7H24.8999C24.2999 15.7 23.8999 15.3 23.8999 14.7V6.69995C23.8999 6.09995 24.2999 5.69995 24.8999 5.69995H50.8999C51.4999 5.69995 51.8999 6.09995 51.8999 6.69995V14.7C51.8999 15.3 51.4999 15.7 50.8999 15.7Z"
                fill="#625FC7"
            />
            <path
                d="M50.8999 44.7H24.8999C24.2999 44.7 23.8999 44.3 23.8999 43.7V29.7C23.8999 29.1 24.2999 28.7 24.8999 28.7H50.8999C51.4999 28.7 51.8999 29.1 51.8999 29.7V43.7C51.8999 44.3 51.4999 44.7 50.8999 44.7Z"
                fill="#625FC7"
            />
            <path
                d="M53.9 0.7C53.7 0.3 53.3 0 52.8 0H33.5H23.2C22.5 0 22 0.5 22 1.2V11.6V45.8C22 46 22 46.1 22.1 46.3C22.2 46.6 22.5 46.8 22.7 46.9C22.9 47 23 47 23.2 47H42.5H52.8C53.5 47 54 46.5 54 45.8V35.4V1.2C54 1 54 0.9 53.9 0.7Z"
                fill="#5447C8"
            />
            <path
                d="M35.8999 27.7H24.8999C24.2999 27.7 23.8999 27.3 23.8999 26.7V17.7C23.8999 17.1 24.2999 16.7 24.8999 16.7H35.8999C36.4999 16.7 36.8999 17.1 36.8999 17.7V26.7C36.8999 27.3 36.4999 27.7 35.8999 27.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 18.7H38.8999C38.2999 18.7 37.8999 18.3 37.8999 17.7C37.8999 17.1 38.2999 16.7 38.8999 16.7H50.8999C51.4999 16.7 51.8999 17.1 51.8999 17.7C51.8999 18.3 51.4999 18.7 50.8999 18.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 21.7H38.8999C38.2999 21.7 37.8999 21.3 37.8999 20.7C37.8999 20.1 38.2999 19.7 38.8999 19.7H50.8999C51.4999 19.7 51.8999 20.1 51.8999 20.7C51.8999 21.3 51.4999 21.7 50.8999 21.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 24.7H38.8999C38.2999 24.7 37.8999 24.3 37.8999 23.7C37.8999 23.1 38.2999 22.7 38.8999 22.7H50.8999C51.4999 22.7 51.8999 23.1 51.8999 23.7C51.8999 24.3 51.4999 24.7 50.8999 24.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 27.7H38.8999C38.2999 27.7 37.8999 27.3 37.8999 26.7C37.8999 26.1 38.2999 25.7 38.8999 25.7H50.8999C51.4999 25.7 51.8999 26.1 51.8999 26.7C51.8999 27.3 51.4999 27.7 50.8999 27.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 4.69995H24.8999C24.2999 4.69995 23.8999 4.29995 23.8999 3.69995V2.69995C23.8999 2.09995 24.2999 1.69995 24.8999 1.69995H50.8999C51.4999 1.69995 51.8999 2.09995 51.8999 2.69995V3.69995C51.8999 4.29995 51.4999 4.69995 50.8999 4.69995Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 15.7H24.8999C24.2999 15.7 23.8999 15.3 23.8999 14.7V6.69995C23.8999 6.09995 24.2999 5.69995 24.8999 5.69995H50.8999C51.4999 5.69995 51.8999 6.09995 51.8999 6.69995V14.7C51.8999 15.3 51.4999 15.7 50.8999 15.7Z"
                fill="#7666FF"
            />
            <path
                d="M50.8999 44.7H24.8999C24.2999 44.7 23.8999 44.3 23.8999 43.7V29.7C23.8999 29.1 24.2999 28.7 24.8999 28.7H50.8999C51.4999 28.7 51.8999 29.1 51.8999 29.7V43.7C51.8999 44.3 51.4999 44.7 50.8999 44.7Z"
                fill="#7666FF"
            />
            <path
                d="M23.2643 20.72H26.0483L22.0163 32H19.6803L17.2963 25.232L14.8803 32H12.5283L8.51231 20.72H11.5043L13.8403 27.872L16.3683 20.72H18.4003L20.8163 28L23.2643 20.72ZM41.2018 20.72H43.9858L39.9538 32H37.6178L35.2338 25.232L32.8178 32H30.4658L26.4498 20.72H29.4418L31.7778 27.872L34.3058 20.72H36.3378L38.7538 28L41.2018 20.72ZM59.1393 20.72H61.9233L57.8913 32H55.5553L53.1713 25.232L50.7553 32H48.4033L44.3873 20.72H47.3793L49.7153 27.872L52.2433 20.72H54.2753L56.6913 28L59.1393 20.72ZM62.0083 29.088H64.9683V32H62.0083V29.088Z"
                fill="black"
            />
        </svg>
    );
};

export default WebUrlFull;
