import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Underline = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M12 16.0964C11.407 16.0964 10.8924 16.0064 10.4564 15.8266C10.0203 15.6467 9.66279 15.4026 9.38372 15.0942C9.10465 14.7773 8.89535 14.4047 8.75581 13.9764C8.625 13.5482 8.55959 13.0857 8.55959 12.5889V7H9.82849V12.4475C9.82849 13.3383 10.0291 13.985 10.4302 14.3876C10.8314 14.7902 11.3547 14.9914 12 14.9914C12.3227 14.9914 12.6148 14.9443 12.8765 14.8501C13.1468 14.7473 13.3779 14.5931 13.5698 14.3876C13.7616 14.182 13.9099 13.9208 14.0145 13.6039C14.1192 13.2784 14.1715 12.8929 14.1715 12.4475V7H15.4404V12.5889C15.4404 13.0857 15.3706 13.5482 15.2311 13.9764C15.1003 14.4047 14.891 14.7773 14.6032 15.0942C14.3241 15.4026 13.9666 15.6467 13.5305 15.8266C13.1032 16.0064 12.593 16.0964 12 16.0964Z"
                fill="black"
                className={fill}
            />
            <path d="M7.5 17.985H16.5V19H7.5V17.985Z" fill="black" className={fill} />
        </SvgIcon>
    );
};

export default Underline;
