import { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IProfessionalSituationResource } from "./";

type Payload = {
    title?: string;
    targetedDomain?: string;
    problematic?: string;
    description?: string;
    language?: string;
    skills?: string[];
};

interface UpdateProfessionalSituationVariables {
    professionalSituationUuid: string;
    payload: Payload;
}

export const useUpdateProfessionalSituation = (
    config: MutateConfig<AxiosResponse<IProfessionalSituationResource>, any, UpdateProfessionalSituationVariables>,
) => {
    const api = useApi();

    const updateCoeditors = async ({ payload, professionalSituationUuid }: UpdateProfessionalSituationVariables) =>
        api.put<IProfessionalSituationResource>(`professional_situations/${professionalSituationUuid}`, { ...payload });

    return useMutation<AxiosResponse<IProfessionalSituationResource>, any, UpdateProfessionalSituationVariables>(
        updateCoeditors,
        config,
    );
};
