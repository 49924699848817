import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

interface PatchEducationalScenarioItemVariables {
    payload: {
        educationalBriefsToSort: string[];
    };
    educationalScenarioItemId: string;
}

export const patchEducationalScenarioItem =
    (api: AxiosInstance) =>
    ({ payload, educationalScenarioItemId }: PatchEducationalScenarioItemVariables) =>
        api.patch(`/educational_scenario_items/${educationalScenarioItemId}/reorder`, payload, {
            headers: {
                "Content-Type": "application/merge-patch+json",
            },
        });

export const usePatchEducationalScenarioItemMutation = (
    config: MutateConfig<AxiosResponse, any, PatchEducationalScenarioItemVariables>,
) => {
    const api = useApi();
    const patchEducationalScenarioItemMutation = useMemo(() => patchEducationalScenarioItem(api), [api]);
    const [updateEducationalScenarioItem, { isLoading }] = useMutation(patchEducationalScenarioItemMutation, config);

    return { updateEducationalScenarioItem, isLoading };
};
