import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IPaginated } from "../types/IPaginated";
import { serialize } from "../utils/url";
import { ICategoryList } from "./ICategoryList";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        title?: string | null;
    };
    queryKey?: QueryKey;
    orderBy?: "updatedAt" | "createdAt" | "title";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { title } = filters || {};
    return {
        title,
    };
};

export const useCategories = ({
    filters,
    queryKey,
    order = "desc",
    orderBy,
    pagination,
    ...options
}: Params<ICategoryList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/categories${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    ...(orderBy && { [`order[${orderBy}]`]: order }),
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ICategoryList>(url)), [api, url]);

    return useQuery<ICategoryList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
