import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { ISelfEvaluation } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    selfEvaluationId?: string | null;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useSelfEvaluation = ({ queryKey, selfEvaluationId, ...options }: Params<ISelfEvaluation>) => {
    const api = useApi();

    const url = useMemo(() => `/self_evaluations/${selfEvaluationId}`, [selfEvaluationId]);
    const queryFn = useCallback(() => unwrapAPIResult(api.get<ISelfEvaluation>(url)), [api, url]);

    return useQuery<ISelfEvaluation>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
