export enum AnalyticsEvent {
    PagePrint = "sol.page.print",
    UserIdentified = "sol.user.identified",
    OptOut = "sol.optOut",
    OptIn = "sol.optIn",
    LoginSuccess = "sol.login.success",
    LogiFailure = "sol.login.failure",
}

export class AnalyticsPlugin {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    track(event: AnalyticsEvent, data?: any) {
        return;
    }
    reset() {
        return;
    }
}

class Analytics {
    infos: Record<string, any>;
    disabled: boolean;
    plugins: Array<any>;

    constructor({ plugins = [], disabled = false, ...infos }) {
        this.infos = infos;
        this.disabled = disabled;
        this.plugins = plugins;

        if (!disabled) {
            const { app, ...rest } = infos;
            // eslint-disable-next-line no-console
            console.log(
                `%c${app}%c\n${Object.keys(rest)
                    .map(key => `${key}: ${infos[key]}`)
                    .join("\n")}`,
                "text-transform:uppercase;font-size:28px",
                "",
            );
        }
    }

    enable() {
        this.disabled = false;
    }

    disable() {
        this.disabled = true;
    }

    addPlugin(plugin: any) {
        this.plugins.push(plugin);
    }

    page(data?: any) {
        let pageInfo: any = {};
        if (window?.location) {
            const { href, protocol, host, hostname, port, pathname, search, hash, origin } = window.location;

            pageInfo = {
                ...pageInfo,
                href,
                protocol,
                host,
                hostname,
                port,
                pathname,
                search,
                hash,
                origin,
            };
        }

        if (document) {
            const { title, referrer } = document;
            pageInfo = {
                ...pageInfo,
                title,
                referrer,
            };
        }

        this.track(AnalyticsEvent.PagePrint, {
            page: pageInfo,
            ...data,
            timestamp: Date.now(),
        });
    }

    identify(data?: any) {
        this.track(AnalyticsEvent.UserIdentified, { identity: data });
    }

    track(event: AnalyticsEvent, data?: any) {
        if (!this.disabled) {
            this.plugins.forEach(plugin =>
                plugin.track(event, {
                    ...this.infos,
                    ...data,
                }),
            );
        }
    }

    reset() {
        this.plugins.forEach(plugin => plugin.reset());
    }
}

export default Analytics;
