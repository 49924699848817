import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Skill = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <rect
                x="14"
                y="5.5"
                width="4.5"
                height="4.5"
                stroke="black"
                strokeWidth="2"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M16.25 14L18.8481 18.5H13.6519L16.25 14Z"
                stroke="black"
                strokeWidth="2"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M7.75 5L10.3654 6.9002L9.36641 9.9748H6.13359L5.13459 6.9002L7.75 5Z"
                stroke="black"
                strokeWidth="2"
                strokeLinejoin="round"
                className={stroke}
            />
            <circle cx="7.75" cy="16.5" r="2.5" stroke="black" strokeWidth="2" className={stroke} />
        </SvgIcon>
    );
};

export default styled(Skill)``;
