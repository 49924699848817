import clsx from "clsx";
import React from "react";
import styled from "styled-components";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Error = (props: SvgIconProps) => {
    const { stroke, fill } = classes;

    return (
        <SvgIcon {...props}>
            <circle
                cx="12"
                cy="19"
                r="1.25"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                className={clsx(stroke, fill)}
            />
            <path d="M12 14L12 5" stroke="black" strokeWidth="2" strokeLinecap="round" className={stroke} />
        </SvgIcon>
    );
};

export default styled(Error)``;
