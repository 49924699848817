import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation, MutateConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IBrief, IBriefResource } from ".";
import { RelationalValue, mapRelationalValue } from "../utils/filters";

export type DuplicateBriefVariables = {
    brief: RelationalValue<IBriefResource>;
};

export const duplicateBriefMutation =
    (api: AxiosInstance) =>
    ({ brief }: DuplicateBriefVariables) =>
        api.post<IBrief>("/briefs/duplicate", { uuid: mapRelationalValue(brief) });

export const useDuplicateBriefMutation = (
    config: MutateConfig<AxiosResponse<IBrief>, any, DuplicateBriefVariables>,
) => {
    const api = useApi();
    const mutation = useMemo(() => duplicateBriefMutation(api), [api]);
    const [mutate, { isSuccess, error, reset, isLoading }] = useMutation(mutation, config);

    return { mutate, isSuccess, error, reset, isLoading };
};
