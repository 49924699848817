import { IFollowUpResource, ISelfEvaluationResource, IUserResource } from "@sol/sdk";
import { ICommentList } from "@sol/sdk/comments/ICommentList";
import { mapRelationalFilter, RelationalFilter } from "@sol/sdk/utils/filters";

import {
    createApiQueryHook,
    IApiQueryFunction,
    IOrderable,
    IPaginateable,
    mapOrder,
    mapPagination,
} from "../utils/api";
import { serialize } from "../utils/url";

export interface ICommentListQueryParameters extends IPaginateable, IOrderable<"createdAt"> {
    filters?: {
        followUp?: RelationalFilter<IFollowUpResource>;
        selfEvaluation?: RelationalFilter<ISelfEvaluationResource>;
        createdBy?: RelationalFilter<IUserResource>;
    };
}

export type ICommentListQueryResult = ICommentList;

const mapFilters = (filters: ICommentListQueryParameters["filters"]) => {
    const { followUp, selfEvaluation, createdBy } = filters ?? {};
    return {
        "followUp.uuid": mapRelationalFilter(followUp),
        "selfEvaluation.uuid": mapRelationalFilter(selfEvaluation),
        "createdBy.uuid": mapRelationalFilter(createdBy),
    };
};

export const commentListQuery: IApiQueryFunction<ICommentListQueryParameters> = ({ filters, order, pagination }) => {
    return {
        method: "GET",
        url: `/comments${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
};

export const useCommentListQuery = createApiQueryHook<ICommentListQueryParameters, ICommentListQueryResult, any>(
    commentListQuery,
);
