const transitions = {
    spring: {
        default: {
            type: "spring",
            mass: 1,
            stiffness: 170,
            damping: 26,
        },
        stiff: {
            type: "spring",
            mass: 1,
            stiffness: 310,
            damping: 40,
        },
    },
};

export default transitions;
