import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";
import setValue from "lodash/set";
import { SubmissionError } from "redux-form";

const mapToIRIRec = value => {
    if (isArray(value)) {
        return value.map(mapToIRIRec);
    }

    if (isObject(value)) {
        if (value["@id"]) {
            return mapToIRIRec(value["@id"]);
        }

        return mapValues(value, mapToIRIRec);
    }

    return value;
};

const normalize = value => mapValues(value, mapToIRIRec);

const toSubmissionError = (data, errors, message) => {
    return new SubmissionError(
        {
            ...(data &&
                data.violations &&
                data.violations.reduce((acc, violation) => {
                    const { propertyPath, message: msg } = violation;
                    setValue(acc, propertyPath, msg);
                    return acc;
                }, {})),
            ...errors,
        },
        message,
    );
};

const JSONLD = {
    normalize,
    toSubmissionError,
};

export default JSONLD;
