import { cva } from "class-variance-authority";
import React from "react";

import { SvgIconProps, SvgIcon } from "./SvgIcon";

type Direction = "up" | "down" | "left" | "right";

type Props = { direction?: Direction; className?: string };

const arrowVariants = cva(["transition-transform", "ease-in-out", "transition-200"], {
    variants: {
        variant: {
            up: ["rotate-0"],
            down: ["rotate-180"],
            left: ["rotate-[270deg]"],
            right: ["rotate-90"],
        },
    },
});

export const Arrow = ({ direction, className, ...props }: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} className={arrowVariants({ variant: direction, className })}>
            <path d="M11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21H11ZM12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L4.92893 8.65685C4.53841 9.04738 4.53841 9.68054 4.92893 10.0711C5.31946 10.4616 5.95262 10.4616 6.34315 10.0711L12 4.41421L17.6569 10.0711C18.0474 10.4616 18.6805 10.4616 19.0711 10.0711C19.4616 9.68054 19.4616 9.04738 19.0711 8.65685L12.7071 2.29289ZM13 21V3H11V21H13Z" />
        </SvgIcon>
    );
};
